import { Reference } from "fhir"
import { FC } from "react"
import { FormFieldBaseProps } from "./FormField"
import { AutocompleteField } from "./AutocompleteField"

const AutocompleteReferenceField: FC<Props> = (fieldProps) => (
  <AutocompleteField
    selectedItemTemplate={(item) => item.display as string}
    onSelectValue={(e) => ({ id: e.value.id, display: e.value.display ?? e.value, resourceType: e.value.resourceType })}
    setValue={(item) => item?.display}
    emptyValue={{ id: undefined, display: undefined, resourceType: undefined }}
    invalidCondition={(item) => !item?.id}
    {...fieldProps}
  />
)

type Props = FormFieldBaseProps<Reference> & {
  readonly?: boolean
  disabled?: boolean
  multiple?: boolean
  setFilter(query: string): void
  optionList?: Reference[]
  isLoading: boolean
}

export { AutocompleteReferenceField }
