import { useMutation } from "@tanstack/react-query"
import { Account } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"

const useDecryptPatientAccount = () => {
  const { read } = useClient()

  const decryptAccount = async ({ accountId }: { accountId: string }): Promise<Account> => {
    const response = await read({ endpoint: `$decrypt/Account/${accountId}` })

    return response as Account
  }

  const { mutateAsync: decrypt, isPending: isDecrypting } = useMutation({
    mutationFn: decryptAccount,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
  })

  return { decrypt, isDecrypting }
}

export { useDecryptPatientAccount }
