import { faCartPlus, faTrashCan } from "@fortawesome/pro-regular-svg-icons"
import { faCheck, faPencil, faX } from "@fortawesome/pro-light-svg-icons"
import { Duration } from "fhir"
import { useEffect, useState } from "react"
import { classNames } from "primereact/utils"
import { Dropdown } from "primereact/dropdown"

import { Button } from "commons"
import { CartMedData } from "orders"
import { dispenseInterval, DispenseIntervalOption } from "commons/meds"

import { RemoveComfirmation } from "./RemoveConfirmaion"

const OverviewFooter = ({
  blockAdd,
  medData,
  isProcessing,
  overviewStyle,
  price,
  onAdd,
  onEdit,
  onDelete,
  onHide,
}: Props) => {
  const [units, setUnits] = useState(medData?.quantity ?? 1)
  const [editId, setEditId] = useState<string | undefined>("")
  const [buttonClicked, setButtonClicked] = useState({ add: false, del: false, confirm: false, edit: false })
  const [dinterval, setInterval] = useState(medData?.interval ?? dispenseInterval[0].value)
  const totalPrice = (parseFloat((price ?? "$0").slice(1)) * units).toFixed(2)

  useEffect(() => {
    setUnits(medData?.quantity ?? 1)
    setEditId("")
  }, [medData])
  useEffect(() => {
    if (!isProcessing) setButtonClicked({ add: false, del: false, confirm: false, edit: false })
  }, [isProcessing])

  const remove = () => {
    onDelete(medData?.id as string)
    setButtonClicked({ ...buttonClicked, del: true, confirm: false })
    onHide?.()
  }

  const edit = () => {
    onEdit(units, dinterval)
    setEditId(medData?.id as string)
    setButtonClicked({ ...buttonClicked, edit: false })
    onHide?.()
  }
  const dispenseTemplate = (option: DispenseIntervalOption) => `Dispense ${option?.label.toLowerCase()}`

  const dissmis = () => {
    setButtonClicked({ ...buttonClicked, edit: false })
    setUnits(medData?.quantity ?? 1)
    setInterval(medData?.interval ?? dispenseInterval[0].value)
  }

  return (
    <div
      className={classNames("flex flex-col gap-2 items-center justify-end grow", {
        "lg:flex-row": overviewStyle,
      })}
    >
      <div
        className={classNames("flex flex-row w-full primary font-medium justify-start", {
          "font-semibold text-gray-900 text-2xl": overviewStyle,
        })}
      >
        ${totalPrice.toString()}
      </div>
      <div className={classNames("flex justify-between gap-1 w-full", { "lg:w-auto": overviewStyle })}>
        <Dropdown
          options={Array.from({ length: 10 }, (_, i) => i + 1)}
          value={units}
          className="p-inputtext-sm grow"
          onChange={(e) => setUnits(e.value)}
          disabled={(blockAdd || !!medData) && !buttonClicked.edit}
        />
        <Dropdown
          options={dispenseInterval}
          optionLabel="label"
          className="p-inputtext-sm grow truncate"
          itemTemplate={dispenseTemplate}
          valueTemplate={dispenseTemplate}
          value={dinterval}
          onChange={(e) => setInterval(e.value)}
          disabled={(blockAdd || !!medData) && !buttonClicked.edit}
        />
      </div>
      <div className="flex flex-row w-full justify-between gap-1">
        {medData ? (
          <>
            <Button
              icon={buttonClicked.edit ? faX : faTrashCan}
              label={buttonClicked.edit ? "Close" : "Remove"}
              buttonStyle="default"
              size="xl"
              className={classNames("w-full justify-center")}
              loading={buttonClicked.del && isProcessing}
              onClick={() =>
                buttonClicked.edit
                  ? dissmis()
                  : medData.requestedByPract
                    ? setButtonClicked({ ...buttonClicked, confirm: true })
                    : remove()
              }
            />
            <Button
              icon={!buttonClicked.edit ? faPencil : faCheck}
              label={!buttonClicked.edit ? "Edit" : overviewStyle ? "Edit" : "Accept"}
              buttonStyle="primary"
              size="xl"
              loading={editId === medData.id && isProcessing}
              className={classNames("w-full justify-center")}
              onClick={() => {
                buttonClicked.edit ? edit() : setButtonClicked({ ...buttonClicked, edit: true })
              }}
            />
            {medData.requestedByPract && (
              <RemoveComfirmation
                visible={buttonClicked.confirm}
                isLoading={isProcessing}
                onHide={() => setButtonClicked({ ...buttonClicked, confirm: false })}
                onDelete={remove}
              />
            )}
          </>
        ) : (
          <Button
            icon={faCartPlus}
            label="Add to cart"
            buttonStyle={overviewStyle ? "primary" : "default"}
            size="xl"
            className={classNames("w-full justify-center", { "lg:w-auto": overviewStyle })}
            loading={buttonClicked.add && isProcessing}
            disabled={blockAdd}
            onClick={() => {
              setButtonClicked({ ...buttonClicked, add: true })
              onAdd(units, dinterval)
              onHide?.()
            }}
          />
        )}
      </div>
    </div>
  )
}

type Props = {
  blockAdd?: boolean
  medData?: CartMedData
  isProcessing?: boolean
  overviewStyle?: boolean
  price?: string
  onAdd(unitsCount: number, duration: Duration): void
  onEdit(unitsCount: number, duration: Duration): void
  onDelete(mrId: string): void
  onHide?(): void
}

export { OverviewFooter }
