import { useQuery } from "@tanstack/react-query"
import { isFuture } from "date-fns"
import { Appointment, CarePlan, PlanDefinition, getResources, getResourcesByTypeAsIndex } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"

import { plansQueryKeys } from "../query-keys"
import { CarePlanHistory } from "../types"
import { getCPDate } from "../utils"

const useCarePlanHistory = (patientId: string, planId?: string) => {
  const { search } = useClient()
  const queryKey = plansQueryKeys.history(patientId, planId)

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        _id: planId as string,
        _sort: "-createdAt",
        _include: "replaces,instantiates-canonical:PlanDefinition,PlanDefinition:definition:PlanDefinition",
        _revinclude: "CarePlan:replaces:CarePlan",
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/CarePlan`, filters, signal })

      const careplan = getResources<CarePlan>(bundle, "CarePlan")
      const planDefinition = getResources<PlanDefinition>(bundle, "PlanDefinition")

      const hasAppointment = !!careplan?.[0]?.activity?.find(
        (a) => a.outcomeReference?.[0]?.resourceType === "Appointment",
      )?.outcomeReference?.[0]?.id

      if (!hasAppointment)
        return {
          careplan,
          indexedAppointment: {} as Record<string, Appointment>,
          planDefinition,
          hasAppointment,
        }

      const filterAppointment = new URLSearchParams({
        _id: careplan.map((cp) => cp.id).join(","),
        _elements: "id",
        _include: "outcome-reference:Appointment",
      })
      const bundleAppointment = await search({
        endpoint: `Patient/${patientId}/CarePlan`,
        filters: filterAppointment,
        signal,
      })
      const indexedAppointment = getResourcesByTypeAsIndex<Appointment>(bundleAppointment, "Appointment")

      return {
        careplan,
        indexedAppointment,
        planDefinition,
        hasAppointment,
      }
    },

    enabled: !!planId,
    meta: { context: { queryKey, patientId } },
  })

  const { appointmentHistory, nextAppointment, lastActiveAppointment } = useMemo(() => {
    const indexedPD =
      data?.planDefinition.reduce<Record<string, PlanDefinition>>(
        (acc, pd) => ({ ...acc, [`${pd.url}|${pd.version}`]: pd }),
        {},
      ) ?? {}

    const { appointmentHistory, nextAppointment } = data?.careplan?.reduce<CPH>(
      (acc, carePlan) => {
        const appointmentId = carePlan.activity?.find((a) => a.outcomeReference?.[0]?.resourceType === "Appointment")
          ?.outcomeReference?.[0]?.id
        const cphItem = {
          carePlan,
          appointment: data?.indexedAppointment?.[appointmentId as string],
        } as CarePlanHistory

        const isFutureAppointment =
          ((cphItem.appointment?.start && isFuture(new Date(cphItem.appointment.start))) ?? false) &&
          carePlan.status === "active"

        return {
          ...acc,
          ...(isFutureAppointment
            ? { nextAppointment: cphItem }
            : { appointmentHistory: [...acc.appointmentHistory, cphItem] }),
        }
      },
      { appointmentHistory: [] },
    ) ?? { appointmentHistory: [] }

    appointmentHistory.sort((a, b) => (getCPDate(a) > getCPDate(b) ? -1 : 1))

    const lastValidAppointment = ["active", "completed"].includes(appointmentHistory?.[0]?.carePlan?.status)
      ? appointmentHistory?.[0]
      : undefined

    const definition = indexedPD[lastValidAppointment?.carePlan?.instantiatesCanonical?.[0] as string]
    const fuToken = definition?.action?.find((a) => a.code?.[0]?.coding?.[0]?.code === "enroll-follow-up-plan")
      ?.definition?.canonical as string
    const fuPD = indexedPD[fuToken]

    const lastActiveAppointment =
      lastValidAppointment &&
      (lastValidAppointment.carePlan.status === "active" || !!fuPD) &&
      ({
        ...lastValidAppointment,
        nextPlanDefinition: fuPD,
      } as CarePlanHistory & { nextPlanDefinition?: PlanDefinition })

    return {
      appointmentHistory,
      nextAppointment,
      lastActiveAppointment,
    }
  }, [data?.planDefinition, data?.careplan, data?.indexedAppointment])

  return {
    isLoading,
    appointmentHistory,
    nextAppointment,
    lastActiveAppointment,
    hasAppointment: data?.hasAppointment,
  }
}

type CPH = {
  appointmentHistory: CarePlanHistory[]
  nextAppointment?: CarePlanHistory
}

export { useCarePlanHistory }
