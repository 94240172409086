import { faEye } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { MedicationKnowledge, MedicationRequest, Reference } from "fhir"
import { FC, useState } from "react"

import { SkeletonLoader, StackedListContainer } from "commons"
import { useChargeItemDefinitions } from "commons/hooks"
import {
  MedicationDetails,
  prescriptionItemModel,
  useMedicationKnowledge,
  useMedicationRequestDataBind,
} from "commons/meds"
import { MEDICATION_CATALOG } from "data"
import { MedicationDataItem } from "medication-requests"
import { getCommonCode, getMedCodes } from "utils"

const CPMedicationsDetails: FC<Props> = ({ medicationRequests, organizationId, category, className }) => {
  const medCodes = getMedCodes({ meds: medicationRequests })
  const sku = medCodes?.map((coding) => getCommonCode([coding])) ?? []

  const [selectedMed, setSelectedMed] = useState<{ mk?: MedicationKnowledge; mr?: MedicationRequest }>()

  const { isLoading, mksBySku } = useMedicationKnowledge({
    enabled: !!sku.length,
    medCodes: sku.join(","),
    hasCIDInOrg: organizationId,
    category,
  })

  const { chargeItemDefinitions } = useChargeItemDefinitions(organizationId, {
    billToPatientCIDs: medCodes,
  })

  const { medicationRequestData } = useMedicationRequestDataBind(
    medicationRequests,
    mksBySku,
    chargeItemDefinitions?.billToPatientCIDs,
  )

  if (isLoading) return <SkeletonLoader repeats={sku.length} loaderType="two-lines" />

  return (
    <>
      {category === MEDICATION_CATALOG.NUTRA ? (
        <div className="flex flex-col ml-4">
          {medicationRequestData.map((item) => (
            <MedicationDataItem
              key={item.medicationRequestInfo.id}
              medicationRequest={item.medicationRequestInfo}
              medicationDispense={item.medicationDispense}
              medicationKnowledge={item.medicationKnowledge}
              pricePerUnit={item.pricePerUnit}
              onClick={() => setSelectedMed({ mk: item.medicationKnowledge, mr: item.medicationRequestInfo })}
              showDispense
              showInstructions
              showPackagingType
              showStatus
            />
          ))}
        </div>
      ) : (
        <StackedListContainer
          data={medicationRequestData}
          itemModelBuilder={(data) =>
            prescriptionItemModel({
              mrData: data,
              showAsExternalAction: true,
              preview: () => setSelectedMed({ mk: data.medicationKnowledge, mr: data.medicationRequestInfo }),
              externalAction: [
                {
                  label: "Preview",
                  icon: <FontAwesomeIcon icon={faEye} />,
                  command: () => setSelectedMed({ mk: data.medicationKnowledge, mr: data.medicationRequestInfo }),
                },
              ],
            })
          }
          className={className}
        />
      )}
      <MedicationDetails
        medication={
          selectedMed
            ? {
                ...selectedMed?.mr,
                medicationKnowledge: selectedMed?.mk,
                intent: selectedMed.mr?.intent as string,
                status: selectedMed.mr?.status as string,
                subject: selectedMed.mr?.subject as Reference,
              }
            : undefined
        }
        onHide={() => setSelectedMed(undefined)}
      />
    </>
  )
}

type Props = {
  medicationRequests: MedicationRequest[]
  organizationId: string
  category: MEDICATION_CATALOG
  className?: string
}

export { CPMedicationsDetails }
