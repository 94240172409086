import {
  ChargeItemDefinition,
  getFirstEmail,
  humanNameAsString,
  Invoice,
  isPatient,
  RequestGroup,
  ResourceObject,
} from "fhir"
import { getAddressByType, getHomeAddress } from "fhir/utils"
import { classNames } from "primereact/utils"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { createSearchParams, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTriangleExclamation } from "@fortawesome/pro-regular-svg-icons"

import { usePatientAccount } from "account"
import { Button, SkeletonLoader } from "commons"
import { intercomTrackEvent } from "intercom"
import { useAuth, useLoginContext } from "security"
import { getMoneyCurrencyAlt, getSubviewPath } from "utils"
import { SmartyAddressVerificationProvider } from "commons/context"

import {
  useCPOEContext,
  useCpoeOrdersFinish,
  useCPOERequestsContext,
  useExtrasPrices,
  useIvoicePreview,
  useUpdateRG,
} from "../hooks"
import { ACTION_GROUP_CODES, ActionGroupCode, CustomInvoiceData } from "../types"
import { buildAction, getInvoiceMeta } from "../utils"
import { OrderCreditCardContainer } from "./credit-card/OrderCreditCardContainer"
import { OrderSummary } from "./OrderSummary"
import { OrdersShippingAddress } from "./payment-info/OrdersShippingAddress"
import { OrdersShippingMethod } from "./payment-info/OrdersShippingMethod"

const OrdersContainer: FC = () => {
  const { user } = useAuth()
  const { loggedInPatient, loggedInPatientId, isLoadingPatient } = useLoginContext()
  const { refreshCpoeCount, checkoutState, resetCheckoutState, setCheckoutState, gotoShop } = useCPOEContext()
  const [selectedShippingMethod, setSelectedShippingMethod] = useState<ChargeItemDefinition[]>()
  const [isFirstTime, setIsFirstTime] = useState(true)

  const navigate = useNavigate()
  const showOrder = (orderId: string, invoice?: Invoice) => {
    const invoiceMeta = invoice && getInvoiceMeta(invoice)
    const currentPath = window.location.href.split("/patient")
    intercomTrackEvent({
      email: user.email,
      event: "order-placed",
      metadata: {
        order: {
          url:
            currentPath[0] +
            getSubviewPath(loggedInPatientId, "nutraceuticals") +
            "?" +
            createSearchParams({ view: "orders", order: orderId }),
          value: (orderId ?? invoice?.id) as string,
        },
        ...invoiceMeta,
        ...{ order_for: loggedInPatient.name ? humanNameAsString(loggedInPatient.name?.[0]) : invoiceMeta?.order_for },
      },
    })

    resetCheckoutState()
    navigate({
      pathname: getSubviewPath(loggedInPatientId, "nutraceuticals"),
      search: `?${createSearchParams({ view: "orders", order: orderId })}`,
    })
  }

  const { defaultCreditCard, isLoadingAccount } = usePatientAccount(
    loggedInPatientId as string,
    humanNameAsString(loggedInPatient?.name?.[0]),
    getFirstEmail(loggedInPatient?.telecom),
  )

  const { activeRequestReferences, isLoadingRequests, subtotal, requestGroup, hasAutoship } = useCPOERequestsContext()
  const homeAddress = getHomeAddress(loggedInPatient.address)
  const shippingAddress = useMemo(() => {
    const defaultShippingAddress = getAddressByType(loggedInPatient.address, "postal")
    return defaultShippingAddress ?? homeAddress ?? loggedInPatient.address?.[0]
  }, [loggedInPatient, isLoadingPatient])

  const {
    medsShippingMethods,
    nutraShippingMethods,
    isLoadingExtrasPrices: isLoadingShippingMethods,
  } = useExtrasPrices(activeRequestReferences, { nutraAddress: shippingAddress ? [shippingAddress] : [] })

  const shippingMethods = useMemo(
    () => [...(nutraShippingMethods ?? []), ...(medsShippingMethods ?? [])],
    [nutraShippingMethods, medsShippingMethods],
  )

  const { ordersFinish, isFinishing } = useCpoeOrdersFinish(loggedInPatientId, refreshCpoeCount, showOrder)
  const { invoicePreview, isLoadingPreview, invoiceData } = useIvoicePreview(loggedInPatientId)
  const patientInvoice = invoiceData?.find(({ invoice }) => isPatient(invoice?.recipient)) as CustomInvoiceData

  const currency = getMoneyCurrencyAlt(patientInvoice?.invoice?.totalNet?.currency)

  useEffect(() => {
    setCheckoutState({
      ...checkoutState,
      loading:
        isLoadingRequests ||
        isLoadingAccount ||
        isLoadingShippingMethods ||
        isLoadingPreview ||
        isFinishing ||
        !patientInvoice?.invoice ||
        isLoadingPatient,
      disabled: defaultCreditCard === undefined || shippingAddress === undefined,
    })
  }, [isFinishing, isLoadingPreview, isLoadingRequests, isLoadingAccount, defaultCreditCard, isLoadingPatient])

  const scrollToError = useCallback(() => {
    if ((!shippingAddress || !defaultCreditCard || !selectedShippingMethod?.length) && !checkoutState.loading) {
      const selector =
        (!shippingAddress && `[id^='shipping-address-section']`) ||
        (!selectedShippingMethod?.length && `[id^='shipping-method-section']`) ||
        `[id^='payment-method-section']`
      if (selector) {
        const element = document.querySelector(selector) as HTMLElement
        if (element) element.scrollIntoView({ behavior: "smooth", block: "start" })
      }
    }
  }, [shippingAddress, defaultCreditCard, selectedShippingMethod, checkoutState.loading])

  const { updateCpoeRG, isUpdatingRG } = useUpdateRG(invoicePreview)

  const isResourceInShippings = (groupId: ActionGroupCode, resourceId?: string) => {
    switch (groupId) {
      case ACTION_GROUP_CODES.PHARMA:
        return medsShippingMethods?.some((item) => item.id === resourceId)
      case ACTION_GROUP_CODES.NUTRA:
        return nutraShippingMethods?.some((item) => item.id === resourceId)
      default:
        return false
    }
  }

  const handleUpdateShippingMethod = (cid: ChargeItemDefinition, groupId: ActionGroupCode) => {
    if (!selectedShippingMethod?.some((item) => item.id === cid.id)) {
      const shippingAction = buildAction(cid, "add-fee")

      const filteredRGPaymentAction =
        requestGroup?.action?.[1]?.action?.filter(
          ({ resource }) => resource?.localRef !== cid.id && !isResourceInShippings(groupId, resource?.localRef),
        ) ?? []

      const filteredRGContained =
        (requestGroup?.contained as ResourceObject[] | undefined)?.filter(
          (item) => cid.id !== item.id && !isResourceInShippings(groupId, item.id),
        ) ?? []

      const newPaymentAction = {
        ...requestGroup?.action?.[1],
        action: [shippingAction, ...filteredRGPaymentAction],
      }

      const updatedRG = { ...requestGroup } as RequestGroup
      updatedRG.action = [
        ...(requestGroup?.action?.slice(0, 1) ?? []),
        newPaymentAction,
        ...(requestGroup?.action?.slice(2) ?? []),
      ]
      updatedRG.contained = [cid, ...filteredRGContained]

      updateCpoeRG(updatedRG)
      setSelectedShippingMethod((prev) => [
        ...(prev?.filter((cid) => !isResourceInShippings(groupId, cid.id)) ?? []),
        cid,
      ])
    }
  }

  useEffect(() => {
    if (!selectedShippingMethod?.length && requestGroup?.action?.[1]?.action && !isLoadingShippingMethods) {
      const shippingFees = shippingMethods.filter((cid) =>
        requestGroup.action?.[1].action?.some(({ resource }) => resource?.localRef === cid.id),
      )

      if (shippingFees.length) {
        setSelectedShippingMethod(shippingFees)
      }
    }
  }, [isLoadingShippingMethods])

  useEffect(() => {
    if (!isLoadingRequests && isFirstTime) {
      invoicePreview()
      setIsFirstTime(false)
    }
  }, [isLoadingRequests])

  return (
    <div className="h-full overflow-y-auto lg:grid lg:grid-cols-5 px-1 lg:px-8 lg:py-2 lg:gap-x-4 xl:gap-x-8">
      <div className="lg:col-span-3 h-fit pb-5">
        <SmartyAddressVerificationProvider>
          <OrdersShippingAddress />
        </SmartyAddressVerificationProvider>
        <OrdersShippingMethod
          nutraShippingMethods={nutraShippingMethods}
          medsShippingMethods={medsShippingMethods}
          selectedShippingMethods={selectedShippingMethod}
          onChangeShippingMethod={handleUpdateShippingMethod}
          isLoading={isLoadingShippingMethods}
          hasAutoship={hasAutoship ?? false}
          totalItemsCost={subtotal}
          availableShippingMethodsCount={shippingMethods.length}
          isSelectionDisabled={isUpdatingRG || isLoadingRequests}
        />
        <OrderCreditCardContainer defaultCreditCard={defaultCreditCard} isLoading={isLoadingAccount} />
      </div>
      <div className="lg:col-span-2 mt-10 lg:mt-0">
        <h2 className="text-lg font-medium text-gray-900">Order summary</h2>
        <div className="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm">
          {isLoadingRequests || isLoadingPreview ? (
            <SkeletonLoader repeats={5} loaderType="one-line" />
          ) : !patientInvoice || !patientInvoice?.summary ? (
            <div className="flex flex-col p-4 text-center h-96 justify-center">
              <FontAwesomeIcon icon={faTriangleExclamation} size="xl" className="text-yellow-400" />
              No invoice generated for this order
            </div>
          ) : (
            <OrderSummary summary={patientInvoice.summary} currency={currency} invoiceData={patientInvoice} />
          )}
          {(!!patientInvoice || isLoadingPreview) && (
            <div
              className="border-t border-gray-200 py-6 px-4 sm:px-6 relative"
              onMouseOver={scrollToError}
              onTouchStart={scrollToError}
            >
              <Button
                label="Pay"
                size="lg"
                className="w-full px-6 py-3 text-base justify-center font-medium"
                loading={checkoutState.loading || checkoutState.updatingAddress}
                disabled={
                  checkoutState.disabled ||
                  !shippingAddress ||
                  (!!shippingMethods.length && !selectedShippingMethod?.length)
                }
                onClick={() => ordersFinish()}
              />
            </div>
          )}
          <div className="mb-6 text-center text-sm">
            <p>
              {"or "}
              <span
                className={classNames(
                  "font-medium",
                  { "text-primary hover:text-primary-hover cursor-pointer": !isFinishing },
                  { "text-gray-400": isFinishing },
                )}
                onClick={() => !isFinishing && gotoShop()}
              >
                Continue Shopping
                <span aria-hidden="true"> &rarr;</span>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export { OrdersContainer }
