import { classNames } from "primereact/utils"
import { FC } from "react"

import { Button } from "./Buttons"

const NotificationWithActions: FC<Props> = ({ header, message, actions }) => (
  <div className="flex flex-col">
    {header && <p className="font-bold">{header}</p>}
    <p>{message}</p>
    <div className={classNames("flex mt-2", actions.length === 1 ? "justify-end" : "justify-between")}>
      {actions.map((action, index) => (
        <Button key={index} buttonStyle="default" size="xs" label={action.label} onClick={action.onClick} />
      ))}
    </div>
  </div>
)

type Props = {
  header?: string
  message: string
  actions: { label: string; onClick(): void }[]
}

export { NotificationWithActions }
