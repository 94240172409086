import { faInfoCircle } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { AccountBETACreditCardArray, Address, Coding, PatientContactArray, RelatedPerson } from "fhir"
import { useFormikContext } from "formik"
import { IconField } from "primereact/iconfield"
import { InputIcon } from "primereact/inputicon"
import { Tooltip } from "primereact/tooltip"
import { classNames } from "primereact/utils"
import { useMemo } from "react"

import { isPoBoxAddress } from "utils"
import { useValueSet } from "value-set"

import { SuggestionsEntity } from "../hooks/useSmartyAddressAutoCompletion"
import { AutoCompleteAddressField } from "./AutoCompleteAddressField"
import { DropdownField } from "./DropdownField"
import { InputField } from "./InputField"

const AddressField = ({
  parentFieldName,
  showAutoComplete = true,
  disabled = false,
  showTypeUseField = true,
  validAddressTypes,
  checkPOBoxAddress,
  context,
}: Props) => {
  const parentFieldFullName = parentFieldName ? parentFieldName + "." : ""

  const { codes: availableStates } = useValueSet("continental-usa-states")

  const { address, setFieldValue } = useAddressContext(context)

  const isAddressPoBox = useMemo(() => isPoBoxAddress(address), [address])

  const handleAddressAutoComplete = (suggestion: SuggestionsEntity) => {
    Object.entries(suggestion).forEach(([key, value]) => {
      switch (key) {
        case "street_line": {
          setFieldValue(`${parentFieldFullName}line[0]`, value)
          break
        }

        case "secondary": {
          setFieldValue(`${parentFieldFullName}line[1]`, value)
          break
        }

        case "zipcode": {
          setFieldValue(`${parentFieldFullName}postalCode`, value)
          break
        }

        case "city":
          setFieldValue(`${parentFieldFullName}city`, value)
          break

        case "state": {
          setFieldValue(`${parentFieldFullName}state`, value)
          break
        }
      }
    })
  }

  return (
    <>
      <div className="@container col-span-2 w-full">
        <IconField iconPosition="right">
          {showAutoComplete ? (
            <AutoCompleteAddressField
              field={`${parentFieldFullName}line[0]`}
              onAutoCompleteAddress={handleAddressAutoComplete}
              label="Address Line 1"
              placeholder={`Enter street ` + `\u0430` + `ddress`}
              isReadonly={disabled}
              className="relative"
            />
          ) : (
            <InputField
              field={`${parentFieldFullName}line[0]`}
              label="Address Line 1"
              placeholder={`Enter street ` + `\u0430` + `ddress`}
              disabled={disabled}
              autocomplete="off"
              spellcheck={false}
              type="text"
              className="relative"
            />
          )}
          {checkPOBoxAddress && !!isAddressPoBox && (
            <InputIcon className="flex items-center translate-y-2">
              <Tooltip
                target=".tooltiped"
                event="hover"
                position="left"
                content="It appears that you are attempting to set a P.O. Box address. Please be aware that P.O. Boxes might not be suitable for receiving deliveries"
              />
              <FontAwesomeIcon icon={faInfoCircle} className="text-orange-500 cursor-pointer tooltiped" />
            </InputIcon>
          )}
        </IconField>
        <InputField
          field={`${parentFieldFullName}line[1]`}
          label="Address Line 2"
          disabled={disabled}
          type="text"
          autocomplete="off"
          spellcheck={false}
        />
        <div
          className={classNames(
            "p-fluid grid grid-cols-1 gap-4",
            showTypeUseField ? "@sm:grid-cols-4" : "@sm:grid-cols-3",
          )}
        >
          {showTypeUseField && (
            <DropdownField
              field={`${parentFieldFullName}type`}
              label="Use"
              className="grow"
              options={validAddressTypes ?? addressTypes}
              showDefaultValue
              disabled={disabled}
            />
          )}
          <InputField
            field={`${parentFieldFullName}city`}
            label="City"
            className="grow"
            disabled={disabled}
            autocomplete="off"
            spellcheck={false}
            type="text"
          />
          <DropdownField
            field={`${parentFieldFullName}state`}
            label="State"
            className="grow"
            options={availableStates as Coding[]}
            optionLabel="display"
            disabled={disabled}
          />
          <InputField
            field={`${parentFieldFullName}postalCode`}
            label="ZIP Code"
            className="grow"
            disabled={disabled}
            autocomplete="off"
            spellcheck={false}
            type="text"
          />
        </div>
      </div>
    </>
  )
}

const useAddressContext = (context: AddressContextType) => {
  const { setFieldValue, values } = useFormikContext()

  const address = useMemo(() => {
    switch (true) {
      case context === "CreditCard":
        return (values as AccountBETACreditCardArray).billingAddress
      case context === "Address":
        return values as Address
      case context === "PatientContact":
        return (values as PatientContactArray).address
      case context === "RelatedPerson":
        return (values as RelatedPerson).address?.[0]
      default:
        return undefined
    }
  }, [context, values])

  return { address, setFieldValue }
}

type AddressContextType = "CreditCard" | "Address" | "PatientContact" | "RelatedPerson"

const addressTypes = [
  { code: "home", label: "home" },
  { code: "physical", label: "physical" },
  { code: "postal", label: "postal" },
]

type Props = {
  context: AddressContextType
  parentFieldName?: string
  showAutoComplete?: boolean
  disabled?: boolean
  showTypeUseField?: boolean
  checkPOBoxAddress?: boolean
  validAddressTypes?: {
    code: string
    label: string
  }[]
}

export { AddressField }
