import { faCalendarDays, faPills, faTrashCan } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ServiceRequest, isPractitioner } from "fhir"
import { Chip } from "primereact/chip"
import { MenuItem } from "primereact/menuitem"
import { classNames } from "primereact/utils"

import { CardListItem, getOrderDate } from "commons"

const MedicationOrderListItem = ({ order, isCancelDisabled, isLoading, onItemClicked, onCancel }: Props) => {
  const getOrderAllowedActions = (order: ServiceRequest) => {
    const actionsItems = [
      {
        label: "Cancel",
        icon: <FontAwesomeIcon icon={faTrashCan} size="sm" className="mr-2" />,
        command: () => onCancel(order.id as string),
      },
    ] as MenuItem[]

    return order.status === "active" && !isCancelDisabled ? actionsItems : []
  }

  const orderMRs = order.basedOn?.filter((ref) => ref.resourceType === "MedicationRequest")

  const head = (
    <>
      <div className="font-bold" title="Order number">
        {`#${order.identifier?.[0]?.value ?? "Unspecified number"}`}
      </div>
      <span title="Status">
        <Chip
          label={order.status === "revoked" ? "cancelled" : order.status}
          className={classNames("ml-2 custom-chip", {
            "is-danger": order.status === "revoked",
            "is-success": order.status === "completed",
            "is-info": order.status === "active",
          })}
        />
      </span>
      {!isPractitioner(order.requester) && (
        <span title="Self prescription">
          <Chip label="self prescription" className="ml-2 custom-chip" />
        </span>
      )}
    </>
  )

  const details = (
    <>
      <div className="inline-flex items-center justify-between">
        {(order.occurrence?.Period || order.authoredOn) && (
          <span title="Ocurrence">
            <FontAwesomeIcon icon={faCalendarDays} size="xs" className="mr-1" />
            {getOrderDate(order)}
            <span className="mx-1">|</span>
          </span>
        )}

        <span title="Medication Requests">
          <FontAwesomeIcon icon={faPills} size="xs" className="mr-1" />
          {orderMRs?.length ?? 0}
        </span>
      </div>
    </>
  )

  return (
    <CardListItem
      contentHeader={head}
      contentSubheader={details}
      dropdownMenuItems={getOrderAllowedActions(order)}
      isLoading={isLoading}
      onContentCliked={() => onItemClicked(order.id as string)}
      className="hover:cursor-pointer hover:bg-slate-100"
    />
  )
}

type Props = {
  order: ServiceRequest
  isLoading?: boolean
  isCancelDisabled: boolean
  onItemClicked(orderId: string): void
  onCancel(orderId: string): void
}

export { MedicationOrderListItem }
