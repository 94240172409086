import { useBasisTheory } from "@basis-theory/basis-theory-react"

import { CustomError } from "commons"
import { registerErrorTrace } from "logger"

const btApiKey = window.VITE_APP_BASIS_THEORY_API_KEY

const useBasisTheoryInstance = () => {
  const { bt, error } = useBasisTheory(btApiKey, { elements: true })

  if (error) {
    throw registerErrorTrace(new Error(error.message) as CustomError)
  }
  return { bt }
}

export { useBasisTheoryInstance }
