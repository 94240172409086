import { DocumentReference } from "fhir"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"
import { CustomError } from "commons"

import { patientKeys } from "../query-keys"

const useCreatePatientDocument = (onSuccess?: () => void, onSettled?: () => void) => {
  const { create } = useClient()
  const queryClient = useQueryClient()

  const newDocument = async (document: DocumentReference) => create("DocumentReference", document)

  const { mutate: createDocument, isPending } = useMutation({
    mutationFn: newDocument,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: patientKeys.documents() })
      displayNotificationSuccess("Document added successfully!")
      onSuccess?.()
    },
    onSettled,
  })

  return { createDocument, isCreating: isPending }
}

export { useCreatePatientDocument }
