export enum SYSTEM_VALUES {
  MEDICATION_NDC_SYSTEM = "https://dps.fda.gov/ndc",
  LIFEFILE_MEDICATION_SYSTEM = "http://lifefile.com/fhir/identifiers/medication",
  DRUG_CHARACTERISTIC_SYSTEM = "http://chartedhealth.com/fhir/medicationknowledge-characteristic",
  MEDICATION_REQUEST_CATEGORY_SYSTEM = "http://chartedhealth.com/fhir/medication-request-category",
  // SERVICE REQUEST RELATED
  ORDER_DETAIL_TYPE = "http://chartedhealth.com/fhir/order-detail-type",
  MEDICATION_ORDER = "http://chartedhealth.com/fhir/identifiers/medication-order",
  //PAYMENT
  INVOICE_INDENTIFIER = "http://chartedhealth.com/fhir/payment/invoice-identifier",
}
