import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { ClientProvider, QuestionnaireResponseContainer } from "@chartedhealth/survey-components"
import { asReference } from "fhir"

import { NavigationBanner } from "navigation"
import { useClient } from "api"
import { datadogLogs } from "logger"
import { useLoginContext } from "security"
import { getSubviewPath } from "utils"
import "@chartedhealth/survey-components/dist/index.css"

const SurveyContainer = ({ showBanner = true, onFinish }: { onFinish?(): void; showBanner?: boolean }) => {
  const { id: qrId = "" } = useParams()
  const [searchParams] = useSearchParams()
  const { search, update, operationRequest, read, patch } = useClient()
  const { loggedInPatient, loggedInPatientId } = useLoginContext()

  const navigate = useNavigate()

  const goBack = () => navigate(getSubviewPath(loggedInPatientId, "questionnaires"))

  const goBackWithDelay = () => {
    setTimeout(goBack, 1000)
  }

  const getQrId = () => searchParams.get("qrId")

  return (
    <div className="flex flex-col h-full w-full overflow-auto bg-white">
      {showBanner && <NavigationBanner previousPage="Questionnaires" onIconClick={goBack} />}
      <div className="h-full overflow-hidden">
        <ClientProvider
          apm={datadogLogs.logger}
          loggedInUserResource={asReference(loggedInPatient)}
          search={search}
          operationRequest={operationRequest}
          read={read}
          patch={patch}
          update={update}
        >
          <QuestionnaireResponseContainer questionnaireId={getQrId() ?? qrId} onFinish={onFinish ?? goBackWithDelay} />
        </ClientProvider>
      </div>
    </div>
  )
}

export { SurveyContainer }
