import { ContactPoint, DocumentReference, Patient, PatientContactArray, Reference } from "fhir"
import * as Yup from "yup"
import { sub } from "date-fns"

import { ContactPointSystem, emptyAddress } from "data"
import { strCapitalize } from "utils"

import { DocumentFormData } from "../types"

const INITIAL_VALUES: Partial<Patient> = {
  name: [{ use: "official", given: ["", ""], family: "", suffix: [""], prefix: [""] }],
  active: true,
  gender: "unknown",
  birthDate: "",
  telecom: [
    { system: ContactPointSystem.email, use: "home", value: undefined },
    { system: ContactPointSystem.phone, use: "home", value: undefined },
  ],
  address: [emptyAddress],
  contact: [],
}

const joinTelecomValues = (telecom?: ContactPoint[]) => {
  const result = [...(telecom ?? [])]
  result[0] = telecom?.[0] ?? { ...INITIAL_VALUES.telecom?.[0] }
  result[1] = telecom?.[1] ?? { ...INITIAL_VALUES.telecom?.[1] }
  return result
}

const initialValues = (patient: Partial<Patient> = INITIAL_VALUES) => {
  const [name = { use: "official", given: ["", ""], family: "", suffix: [""], prefix: [""] }] = patient.name ?? []
  const [
    address = {
      country: "US",
      line: ["", ""],
      city: "",
      state: "",
      postalCode: "",
      use: "home",
    },
    ...restAddress
  ] = patient.address ?? []

  return {
    ...patient,
    name: [name],
    address: [address, ...restAddress],
    managingOrganization: patient.managingOrganization,
    generalPractitioner: patient.generalPractitioner ?? [{ id: undefined }],
    telecom: joinTelecomValues(patient.telecom),
  } as Patient
}

const minDateTime = sub(new Date(), {
  minutes: 1,
})

const sanitize = ({ ...patient }: Patient) => {
  if (patient.name?.[0].given) {
    patient.name[0].given = patient.name[0].given.filter((value) => value && value !== "")
  }

  if (patient.name?.[0].suffix) {
    patient.name[0].suffix = patient.name[0].suffix.filter((value) => value && value !== "")
  }

  if (patient.name?.[0].prefix) {
    patient.name[0].prefix = patient.name[0].prefix.filter((value) => value && value !== "")
  }

  return patient
}

const addressSchema = Yup.object().shape({
  country: Yup.string().required("Country is required"),
  line: Yup.array()
    .of(
      Yup.string().test("test-address-lines", "First address line is required", (value, context) => {
        return context?.path === "address[0].line[0]" ? value !== undefined && value !== "" : true
      }),
    )
    .min(1, ({ min }) => `At least ${min} address line is required`),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  postalCode: Yup.string().required("ZIP is required"),
})

const humanNameSchema = Yup.object().shape({
  given: Yup.array()
    .of(
      Yup.string().test("test-first-name", "First name is required", (value, context) => {
        return context?.path === "name[0].given[0]" ? value !== undefined && value !== "" : true
      }),
    )
    .min(1, "Name is required"),
  family: Yup.string().required("Last name is required"),
})

const telecomSchema = Yup.object().shape({
  system: Yup.string()
    .oneOf(["phone", "fax", "email", "pager", "url", "sms", "other"], "Invalid value")
    .required("Specify telecom system"),
  use: Yup.string()
    .oneOf(["home", "work", "temp", "old", "mobile"], "Invalid value")
    .required("Specify this telecom usage"),
  value: Yup.string().when("system", (system, yup) => system && yup.required(`${strCapitalize(system)} is required`)),
})

const contactHumanNameSchema = Yup.object().shape({
  given: Yup.array()
    .of(
      Yup.string().test("test-first-name", "First name is required", (value, context) => {
        return context?.path === "name.given[0]" ? value !== undefined && value !== "" : true
      }),
    )
    .min(1, "Name is required"),
  family: Yup.string().required("Family name is required"),
})

const contactValidationSchema = Yup.object()
  .shape({
    name: contactHumanNameSchema,
    telecom: Yup.array(telecomSchema),
    relationship: Yup.array(
      Yup.object().test("empty-coding", "Relationship is required", (value) => value.coding !== undefined),
    ),
    gender: Yup.string().oneOf(["male", "female", "other", "unknown"], "Invalid value"),
  })
  .nullable()
  .optional()

const patientValidationSchema = Yup.object().shape({
  birthDate: Yup.date().max(minDateTime, "Patient birthdate should be before now").required("Birthdate is required"),
  gender: Yup.string()
    .oneOf(["male", "female", "other", "unknown"], "Invalid value")
    .required("Biological Sex is required"),
  name: Yup.array(humanNameSchema).min(1, "At least one name is required"),
  telecom: Yup.array(telecomSchema),
  address: Yup.array(addressSchema),
  generalPractitioner: Yup.array().of(
    Yup.object().test("test-reference", "Practitioner is required", (value: Reference) => value?.id !== undefined),
  ),
})

const telecomValidationSchema = Yup.object().shape({
  telecom: Yup.array(telecomSchema),
})

const CONTACT_INITIAL_VALUES = {
  relationship: [{ coding: undefined }],
  gender: "unknown",
  address: emptyAddress,
  name: { use: "official", given: ["", ""], family: "", suffix: [""], prefix: [""] },
  telecom: [
    { system: ContactPointSystem.email, use: "home", value: "" },
    { system: ContactPointSystem.phone, use: "home", value: "" },
  ],
} as PatientContactArray

const sanitizeContact = ({ ...contact }: PatientContactArray) => {
  if (contact.name?.given) {
    contact.name.given = contact.name.given.filter((value) => value && value !== "")
  }

  if (contact.name?.suffix) {
    contact.name.suffix = contact.name.suffix.filter((value) => value && value !== "")
  }

  if (contact.name?.prefix) {
    contact.name.prefix = contact.name.prefix.filter((value) => value && value !== "")
  }

  if (!contact.address?.line?.[0]) delete contact.address
  if (contact.address?.line?.[0] && !contact.address?.use) contact.address.use = "home"

  return contact
}

const documentInitialValues = (patientRef: Reference): DocumentFormData => {
  return {
    status: "current",
    type: {
      text: "Entered",
      coding: [
        {
          system: "http://chartedhealth.com/fhir/document-reference-type",
          code: "entered",
          display: "Entered",
        },
      ],
    },
    content: undefined,
    subject: patientRef,
    author: [patientRef],
    attachment: undefined,
  }
}

const documentValidationSchema = Yup.object().shape({
  attachment: Yup.object().required("Attachment is required"),
})

const sanitizeDocument = (documentData: DocumentFormData): DocumentReference => {
  const currentDate = new Date()

  documentData.date = currentDate

  const attachment = documentData.attachment

  if (attachment) {
    documentData.content = [{ attachment: { ...attachment, creation: currentDate.toISOString() } }]
  }

  delete documentData.attachment

  return documentData as DocumentReference
}

export {
  minDateTime,
  patientValidationSchema,
  initialValues,
  sanitize,
  CONTACT_INITIAL_VALUES,
  sanitizeContact,
  contactValidationSchema,
  telecomValidationSchema,
  documentInitialValues,
  documentValidationSchema,
  sanitizeDocument,
}
