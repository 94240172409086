import { useState, useEffect, useCallback } from "react"

const url = window.VITE_APP_FHIR_SERVER

const useNetworkStatus = () => {
  const [isOnline, setIsOnline] = useState(true)

  const check = useCallback(() => {
    fetch(`${url}/health`, {
      mode: "no-cors",
    })
      .then(() => setIsOnline((prevPingResult) => (!prevPingResult ? true : prevPingResult)))
      .catch(() => setIsOnline((prevPingResult) => (prevPingResult ? false : prevPingResult)))
  }, [])

  useEffect(() => {
    if (isOnline) {
      const interval = setInterval(() => {
        check()
      }, 20000)
      return () => clearInterval(interval)
    }
  }, [isOnline])

  return { isOnline, setIsOnline, retry: check }
}

export { useNetworkStatus }
