import { faAngleRight, IconDefinition } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { classNames } from "primereact/utils"
import { Skeleton } from "primereact/skeleton"
import { PropsWithChildren } from "react"
import { Link, useParams } from "react-router-dom"
import { getSubviewPath } from "utils"

const DashboardCard = ({
  title,
  link,
  headerIcon,
  iconClass,
  isLoading,
  className,
  children,
}: PropsWithChildren<Props>) => {
  const { patientId } = useParams()
  const resolvedPath = getSubviewPath(patientId ?? "", link)

  return (
    <div
      className={classNames(
        "grid relative rounded-md shadow-md border text-slate-400 bg-gradient-to-b from-white via-white to-slate-50 text-center grow",
        className,
      )}
    >
      <div className="flex flex-row justify-between mb-3">
        <div className="flex flex-col flex-1 h-full justify-left text-sm px-2">
          <h3 className="font-semibold whitespace-nowrap text-slate-700 w-full h-10 flex items-center text-lg">
            {title}
          </h3>
          <div className="grid text-start w-full">
            {isLoading ? (
              <div className="relative">
                <Skeleton height="1rem" className="my-1"></Skeleton>
                <Skeleton height="1rem" className="my-1"></Skeleton>
              </div>
            ) : (
              children
            )}
          </div>
        </div>
        <FontAwesomeIcon
          icon={headerIcon}
          className={classNames("self-center text-3xl xl:text-4xl 2xl:text-5xl text-primary p-3", iconClass)}
        />
      </div>

      {link && !isLoading && (
        <Link
          className="text-sm hover:bg-slate-100 text-primary cursor-pointer flex items-center self-end p-2 py-2.5 border-t"
          to={resolvedPath}
        >
          <span className="text-sm flex items-center">
            See more <FontAwesomeIcon icon={faAngleRight} className="pl-0.5" />
          </span>
        </Link>
      )}
    </div>
  )
}

type Props = {
  title: string
  isLoading?: boolean
  className?: string
  link?: string
  headerIcon: IconDefinition
  iconClass?: string
}

export { DashboardCard }
