import { useNavigate, useParams } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarDays } from "@fortawesome/pro-regular-svg-icons"
import { parseISO } from "date-fns"

import { CardListItem } from "commons"
import { formatsByTypes } from "data"
import { formatDate, getSubviewPath } from "utils"

import { Notification } from "../types"
import { notificationImages } from "../data"

const NotificationListItem = ({ notification }: Props) => {
  const { patientId } = useParams()
  const navigate = useNavigate()

  const goToNotificationDetails = () => navigate(getSubviewPath(patientId ?? "", notification.url))

  const header = <div className="font-bold">{notification.text}</div>

  const subHeader = (
    <div className="inline-flex items-center">
      {notification.date && (
        <>
          <span title="Issued date">
            <FontAwesomeIcon icon={faCalendarDays} size="xs" className="mr-2" />
            {formatDate(parseISO(notification.date), formatsByTypes.LONG_DATE)}
          </span>
          <span className="mx-1 last:hidden">|</span>
        </>
      )}
      {notification.extraData && <span>{notification.extraData}</span>}
    </div>
  )

  const icon = notification.type && notificationImages.get(notification.type)

  return (
    <CardListItem
      rowImg={icon && <FontAwesomeIcon icon={icon} size="lg" className="mr-1 text-slate-500" />}
      contentHeader={header}
      contentSubheader={subHeader}
      className="cursor-pointer"
      onContentCliked={goToNotificationDetails}
    />
  )
}

type Props = {
  notification: Notification
}

export { NotificationListItem }
