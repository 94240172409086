import { useSearchParams } from "react-router-dom"

import { LaboratoryOrderList } from "./LaboratoryOrderList"
import { LaboratoryOrderDetails } from "./LaboratoryOrderDetails"

const LaboratoryOrdersContainer = () => {
  const [searchParams] = useSearchParams()

  return <>{searchParams.get("order") !== null ? <LaboratoryOrderDetails /> : <LaboratoryOrderList />}</>
}

export { LaboratoryOrdersContainer }
