import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import {
  faContactBook,
  faCreditCard,
  faFiles,
  faHouseCrack,
  faInfoCircle,
  faLocationDot,
  faSms,
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { classNames } from "primereact/utils"
import { FC } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

import { ProfileSection } from "data"

const ProfileNav: FC = () => (
  <div className="w-1/4 flex flex-col items-center px-6 mx-4 mt-16 max-lg:hidden profile-nav">
    {profileNavSections.map(({ section, ...rest }) => (
      <PatientNavButton {...rest} section={section} key={section} />
    ))}
  </div>
)

const PatientNavButton: FC<NavButtonProps> = ({ section, icon, label }) => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const activeLink = `nav_link_${searchParams.get("section")}`

  const isActive = activeLink === `nav_link_${section}`

  const onClick = () => {
    navigate(`?section=${section}`)
  }

  return (
    <div
      id={`nav_link_${section}`}
      className={classNames(
        "profile-link cursor-pointer m-1 w-full flex items-center p-2 pl-4 justify-start text-gray-500 hover:bg-gray-100 hover:text-primary",
        isActive && "bg-gray-100 text-primary border-l-2 border-primary",
      )}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={icon} />
      <span className="ml-3">{label}</span>
    </div>
  )
}

const profileNavSections: NavButtonProps[] = [
  {
    label: "Basic information",
    icon: faInfoCircle,
    section: ProfileSection.BASIC,
  },
  {
    label: "Contact information",
    icon: faContactBook,
    section: ProfileSection.CONTACT,
  },
  {
    label: "Address information",
    icon: faLocationDot,
    section: ProfileSection.ADDRESS,
  },
  {
    label: "Credit Cards",
    icon: faCreditCard,
    section: ProfileSection.CC,
  },
  {
    label: "Insurance Information",
    icon: faHouseCrack,
    section: ProfileSection.INSURANCE,
  },
  {
    label: "Emergency Contacts",
    icon: faSms,
    section: ProfileSection.EMERGENCY_CONTACT,
  },
  {
    label: "Documents",
    icon: faFiles,
    section: ProfileSection.PATIENT_DOCUMENTS,
  },
]

type NavButtonProps = {
  section: ProfileSection
  label: string
  icon: IconDefinition
}

export { ProfileNav }
