import { faHexagonExclamation, faInfoCircle, faLocationDot } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Address, addressStringify } from "fhir"
import { getAddressByType, getHomeAddress } from "fhir/utils"
import { Checkbox } from "primereact/checkbox"
import { Tooltip } from "primereact/tooltip"
import { useEffect, useMemo, useState } from "react"

import {
  AddressField,
  AddressVerificationFeedback,
  Button,
  DialogFormContainer,
  useCrudReducer,
  useSmartyAddressVerification,
  useSmartyAddressVerificationContext,
} from "commons"
import { emptyAddress } from "data"
import { useCPOEContext } from "orders"
import { useUpdatePatientShippingAddress } from "patient"
import { useLoginContext } from "security"
import { getAddressSchema, isPoBoxAddress } from "utils"
import { FormikHelpers, useFormikContext } from "formik"

const OrdersShippingAddress = () => {
  const { loggedInPatient, isLoadingPatient } = useLoginContext()
  const { setCheckoutState, checkoutState } = useCPOEContext()
  const { updateShippingAddress, isUpdating } = useUpdatePatientShippingAddress(() => {
    reset()
    setUseHome(false)
  })
  const { checkAddress, clearVerificationInfo } = useSmartyAddressVerification()

  const homeAddress = getHomeAddress(loggedInPatient.address)
  const shippingAddress = useMemo(() => {
    const defaultShippingAddress = getAddressByType(loggedInPatient.address, "postal")
    return defaultShippingAddress ?? homeAddress ?? loggedInPatient.address?.[0]
  }, [loggedInPatient, isLoadingPatient])

  const {
    showSlide: showForm,
    initialValue,
    edit,
    reset,
  } = useCrudReducer({
    defaultEntity: shippingAddress ?? { ...emptyAddress, type: "postal" },
  })
  const [useHome, setUseHome] = useState(false)

  useEffect(() => {
    setCheckoutState({ ...checkoutState, updatingAddress: isUpdating })
  }, [isUpdating])

  const onSubmit = async (address: Address, formikHelpers?: FormikHelpers<Address>) => {
    await checkAddress(address, formikHelpers, () => {
      clearVerificationInfo()
      updateShippingAddress({ address, patient: loggedInPatient })
    })
  }

  return (
    <>
      <div id="shipping-address-section" className="flex flex-col">
        <div className="flex justify-between items-baseline">
          <h2 className="text-lg font-medium text-gray-900 mb-4 inline-flex">Shipping address</h2>
          <Button buttonStyle="default" label="Edit" onClick={() => edit(initialValue)} />
        </div>
        <span className="text-gray-500 text-sm">
          {isPoBoxAddress(shippingAddress) && (
            <>
              <Tooltip
                target=".tooltiped"
                event="hover"
                position="left"
                content="It appears that you are attempting to set a P.O. Box address. Please be aware that P.O. Boxes might not be suitable for receiving deliveries"
              />
              <span className="cursor-pointer tooltiped mr-4">
                <FontAwesomeIcon icon={faInfoCircle} className="text-orange-500" />
              </span>
            </>
          )}
          {addressStringify(shippingAddress)}
        </span>
        {!shippingAddress && (
          <small className="p-error">
            <FontAwesomeIcon icon={faHexagonExclamation} className="mr-1" />
            Please specify a shipping address to proceed
          </small>
        )}
      </div>
      <DialogFormContainer
        title="Edit shipping address"
        showForm={showForm}
        initialValue={initialValue}
        validationSchema={getAddressSchema()}
        onSubmit={onSubmit}
        onCancel={() => {
          reset()
          setUseHome(false)
        }}
      >
        {homeAddress && (
          <div className="flex items-center text-sm gap-1 mt-1">
            <Checkbox
              inputId="usehome"
              onChange={(e) => {
                if (e.checked) {
                  const { use, ...address } = homeAddress as Address
                  edit({ ...address, type: "postal" })
                  setUseHome(true)
                }
              }}
              checked={useHome}
              value="Use home address"
            />
            <label htmlFor="usehome" className="text-gray-500 text-sm cursor-pointer" title="Use home address">
              <FontAwesomeIcon icon={faLocationDot} className="mr-1" />
              {addressStringify(homeAddress)}
            </label>
          </div>
        )}
        <AddressFieldWithVerification />
      </DialogFormContainer>
    </>
  )
}

const AddressFieldWithVerification = () => {
  const { setFieldValue } = useFormikContext()
  const { addressVerificationInfo, autoCompleteRecommendedAddress } = useSmartyAddressVerificationContext()

  return (
    <div className="relative p-fluid grid gap-4">
      <fieldset className="relative p-fluid grid grid-cols-2 gap-4">
        <AddressField context="Address" />
      </fieldset>
      <AddressVerificationFeedback
        addressVerificationInfo={addressVerificationInfo}
        handleAutoCompleteRecommendedAddress={() => autoCompleteRecommendedAddress?.(setFieldValue)}
      />
    </div>
  )
}

export { OrdersShippingAddress }
