import { useContext } from "react"

import { registerErrorTrace } from "logger"
import { CustomError } from "commons"

import { NetworkContext } from "../context/NetworkContext"

const useCheckNetworkContext = () => {
  const context = useContext(NetworkContext)

  if (context === undefined) {
    throw registerErrorTrace(
      new Error("useCheckNetworkContext must be used within a NetworkContextProvider") as CustomError,
    )
  }

  return context
}

export { useCheckNetworkContext }
