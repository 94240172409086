const plansQueryKeys = {
  all: ["patient/plans"] as const,
  list: (
    patientId?: string,
    planId?: string,
    searchText?: string,
    statusFilter?: string[],
    encounter?: string,
    billingType?: string,
  ) => [
    ...plansQueryKeys.all,
    patientId,
    ...(planId ? [planId] : []),
    ...(searchText ? [searchText] : []),
    ...(statusFilter ? [statusFilter] : []),
    ...(billingType ? [billingType] : []),
    ...(encounter ? [encounter] : []),
  ],
  details: (patientId: string, planId?: string) => ["careplan", patientId, planId],
  history: (patientId: string, planId?: string) => ["careplan/history", patientId, planId],
  definitions: (patientGender?: string, organizationId?: string, planId?: string, billingType?: string) => [
    "plans",
    ...(patientGender ? [patientGender] : []),
    ...(organizationId ? [organizationId] : []),
    ...(planId ? [planId] : []),
    ...(billingType ? [billingType] : []),
  ],
  testsDefinitions: (pdCanonicals?: string[]) => ["laboratory/pd-tests", pdCanonicals],
  testsQuestionnaires: (questionnairesCanonicals?: string[]) => [
    "laboratory/tests-questionnaires",
    questionnairesCanonicals,
  ],
  assessments: (patientId: string, orderId?: string) => [
    "careplan/assessments",
    patientId,
    ...(orderId ? [orderId] : []),
  ],
  assessmentDefinition: (algorithmId: string) => ["careplan/assessment-definition", algorithmId],
}

export { plansQueryKeys }
