import { onlineManager } from "@tanstack/react-query"
import { createContext, FC, PropsWithChildren } from "react"

import { useNetworkStatus } from "../hooks"

const NetworkContext = createContext<Props | undefined>(undefined)
NetworkContext.displayName = "NetworkContext"

const NetworkContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { isOnline, setIsOnline, retry } = useNetworkStatus()

  onlineManager.setEventListener((setOnline) => () => {
    return setOnline(isOnline)
  })

  return (
    <NetworkContext.Provider
      value={{
        isOnline,
        setIsOnline,
        retry,
      }}
    >
      {children}
    </NetworkContext.Provider>
  )
}

type Props = {
  isOnline: boolean
  setIsOnline(status: boolean): void
  retry(): void
}

export { NetworkContextProvider, NetworkContext }
