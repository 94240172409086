import { CodeableConcept, Coverage, Reference } from "fhir"
import { useFormikContext } from "formik"
import { useState } from "react"

import { AutocompleteReferenceField, CodeableConceptField, DateField, FormField, InputField } from "commons"
import { insuranceRelationship } from "data"

import { useInsuranceProviders } from "../hooks"
import { RelatedPersonContainer } from "./RelatedPersonContainer"

const CoverageForm = () => {
  const [payorFilter, setPayorFilter] = useState<string>()
  const { insuranceProviders, isLoading } = useInsuranceProviders(payorFilter)

  const {
    values: { relationship, subscriber },
    initialValues: { relationship: initialRelationship },
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<Coverage>()

  const setSelectedPerson = (personRef: Reference | undefined) => {
    setFieldValue("subscriber", personRef)
    setTimeout(() => setFieldTouched("subscriber", true), 1000)
  }

  const setSelectedRelationship = (code: CodeableConcept | undefined) => {
    if (code?.coding?.some(({ code }) => Object.keys(insuranceRelationship).includes(code?.toLowerCase() as string))) {
      setFieldValue("relationship", code)
    } else {
      setFieldValue("relationship", { coding: [insuranceRelationship.other], text: "Other" })
    }
  }
  return (
    <div className="relative p-fluid grid gap-4">
      <fieldset className="relative p-fluid space-y-4 p-3 mb-5">
        <AutocompleteReferenceField
          field="payor[0]"
          label="Carrier"
          className="col-span-2"
          setFilter={setPayorFilter}
          optionList={insuranceProviders}
          isLoading={isLoading}
        />
        <InputField field="class[0].value" label="Group Number" className="col-span-2" />
        <InputField field="subscriberId" label="Subscriber ID" className="col-span-2" />
        <CodeableConceptField
          field="relationship"
          codes={Object.values(insuranceRelationship)}
          label="Subscriber"
          className="col-span-2"
        />
        {relationship?.coding?.[0]?.code !== insuranceRelationship.self.code && (
          <FormField field="subscriber" label="Subscriber data" className="col-span-2 @container" showInvalidState>
            <RelatedPersonContainer
              autoSelect={initialRelationship?.coding?.[0]?.code !== relationship?.coding?.[0]?.code}
              selectedCode={relationship?.coding?.[0]?.code as string}
              selectedPerson={subscriber?.id}
              setSelectedPerson={setSelectedPerson}
              setSelectedRelationship={setSelectedRelationship}
            />
          </FormField>
        )}
        <DateField field="period.start" label="Effective Date" />
      </fieldset>
    </div>
  )
}

export { CoverageForm }
