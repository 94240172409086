import { faEdit } from "@fortawesome/pro-regular-svg-icons"
import { classNames } from "primereact/utils"
import { PropsWithChildren, ReactNode } from "react"

import { Button } from "./Buttons"

const InformationCardContainer = ({
  id,
  title,
  showEdit,
  onEdit,
  customButton,
  className,
  children,
}: PropsWithChildren<Props>) => (
  <div className={classNames("border rounded-lg shadow-sm p-2 scroll-mt-4", className)} id={id}>
    <div className="flex justify-between items-start mb-1">
      <h3 className="flex font-semibold text-lg text-gray-900">{title}</h3>
      {showEdit && (customButton ?? <Button buttonStyle="default" label="Edit" icon={faEdit} onClick={onEdit} />)}
    </div>
    {children}
  </div>
)

type Props = {
  id: string
  title: string
  showEdit?: boolean
  onEdit?(): void
  customButton?: ReactNode
  className?: string
}

export { InformationCardContainer }
