import { useSidebarContext } from "commons"
import { VideoCallProvider, VideoCall } from "communication"

export const VideoCallRender = () => {
  const { isModuleActive } = useSidebarContext()
  return (
    isModuleActive("telemedicine") && (
      <VideoCallProvider>
        <VideoCall />
      </VideoCallProvider>
    )
  )
}
