import { faPills } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { SizeProp } from "@fortawesome/fontawesome-svg-core"
import { MedicationKnowledgeDrugCharacteristicArray } from "fhir"
import { Carousel } from "primereact/carousel"
import { ReactNode, useMemo } from "react"

import { AzureImage } from "commons"

import "./MedicationKnowledgeImage.css"

const MedicationKnowledgeImage = ({
  drugCharacteristic,
  className = "w-12 mr-1",
  showAll = false,
  fallbackIconSize = "4x",
  showFallback = true,
}: MKImageProps) => {
  const fallbackIcon = <FontAwesomeIcon icon={faPills} size={fallbackIconSize} className="p-4 w-auto h-auto max-h-24" />

  const { images } = useMemo(() => {
    const images = drugCharacteristic?.reduce((images, { value }) => {
      const newImage = value?.attachment?.url && (
        <AzureImage
          key={value.attachment.id ?? value.attachment.url}
          url={value.attachment.url}
          loadingClassName="p-1 fa-2x"
          fallbackIconNode={fallbackIcon}
          imageClassName={`object-contain ${!showAll ? className : "h-48 xs:h-72"}`}
          showPreview={showAll}
        />
      )

      return newImage
        ? (value?.attachment?.url as string).includes("front")
          ? [newImage, ...images]
          : [...images, newImage]
        : images
    }, [] as JSX.Element[])

    return { images }
  }, [drugCharacteristic, showAll, className])

  const itemTemplate = (item: ReactNode) => item

  if (!images?.length) return showFallback ? fallbackIcon : null

  return (
    (showAll && (
      <Carousel
        value={images}
        itemTemplate={itemTemplate}
        numVisible={1}
        numScroll={1}
        className={className}
        circular
      />
    )) ||
    images[0]
  )
}

type MKImageProps = {
  drugCharacteristic?: MedicationKnowledgeDrugCharacteristicArray[]
  className?: string
  showAll?: boolean
  fallbackIconSize?: SizeProp
  showFallback?: boolean
}

export { MedicationKnowledgeImage }
