import { FieldProps } from "formik"
import { classNames } from "primereact/utils"
import { FC } from "react"
import { Calendar } from "primereact/calendar"
import { parseISO } from "date-fns"

import { FormField, FormFieldBaseProps } from "./FormField"

const DateField: FC<Props> = ({ showTime, ...fieldProps }) => (
  <FormField {...fieldProps}>
    {({ field: { name, value }, meta: { touched, error }, form: { setFieldValue } }: FieldProps) => (
      <Calendar
        id={name}
        name={name}
        value={parseISO(value)}
        onChange={(e) => setFieldValue(name, (e.value as Date).toISOString())}
        showIcon
        showTime={showTime}
        className={classNames({ "p-invalid": touched && error }, "h-10 p-inputtext-sm")}
      />
    )}
  </FormField>
)

type Props = FormFieldBaseProps<Date> & {
  showTime?: boolean
}

export { DateField }
