import { useInfiniteQuery } from "@tanstack/react-query"
import { getResources, Questionnaire, QuestionnaireResponse } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"

import { surveysKeys } from "../query-keys"
import { QuestionnaireData } from "../types"

const useQuestionnaireResponse = (patientId: string) => {
  const { search } = useClient()
  const queryKey = surveysKeys.questionnaireResponse(patientId)

  const { data, isLoading, isError, error, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery<
    QuestionnareResponseQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = 1 }) => {
      const filters = new URLSearchParams({
        _count: "50",
        _page: `${pageParam}`,
        _sort: "-authored",
        _include: "questionnaire:Questionnaire",
        "enabled:not": "false",
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/QuestionnaireResponse`, filters })

      const qrs = getResources<QuestionnaireResponse>(bundle, "QuestionnaireResponse")
      const questionnaires = getResources<Questionnaire>(bundle, "Questionnaire")

      const qData = questionnaires.reduce<QuestionnaireData[]>((prev, questionnaire) => {
        if (questionnaire.useContext?.find(({ value }) => value?.CodeableConcept?.coding?.[0].code === "labs")) {
          return prev
        }

        const qResponses = qrs.filter((qr) => qr.questionnaire === `${questionnaire.url}|${questionnaire.version}`)

        return [...prev, { questionnaire, qResponses }]
      }, [])

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      const { inProgress, completed } = qrs.reduce(
        (acc, current) => {
          const inProgress = current.status === "in-progress" ? 1 : 0
          const completed = current.status === "completed" ? 1 : 0

          return { inProgress: acc.inProgress + inProgress, completed: acc.completed + completed }
        },
        { inProgress: 0, completed: 0 },
      )

      return { qData, inProgress, completed, next, total: bundle?.total ?? 0 }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, patientId } },
  })

  const { qData, inProgress, completed } = useMemo(() => {
    let countInProgress = 0
    let countCompleted = 0
    const newData = data?.pages.flatMap((page) => {
      countInProgress += page.inProgress
      countCompleted += page.completed

      return page.qData
    })
    return {
      qData: newData,
      inProgress: countInProgress,
      completed: countCompleted,
    }
  }, [data?.pages])

  if (isError) {
    throw error
  }

  return {
    questionnaireData: qData,
    inProgress,
    completed,
    isLoading,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  }
}

type QuestionnareResponseQueryData = {
  qData: QuestionnaireData[]
  inProgress: number
  completed: number
  next: number | undefined
  total: number
}

export { useQuestionnaireResponse }
