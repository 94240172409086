import { useMutation } from "@tanstack/react-query"
import { Bundle } from "fhir"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { CustomError } from "commons"

import { IMessage } from "../types"

const useReadCommunications = (onSuccess: () => void) => {
  const { transaction } = useClient()

  const completeCommunications = async (messages: IMessage[]) => {
    const bundle: Bundle = {
      resourceType: "Bundle",
      type: "batch",
      entry: messages.map((msg) => ({
        request: {
          method: "PATCH",
          url: `Communication/${msg.communication.id}`,
        },
        resource: { status: "completed", resourceType: "Communication" },
      })),
    }
    return transaction(bundle)
  }

  const { mutate: readCommunications, isPending } = useMutation({
    mutationFn: completeCommunications,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess,
  })

  return { readCommunications, isLoading: isPending }
}
export { useReadCommunications }
