import { useMutation } from "@tanstack/react-query"
import { Parameters } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"

const useMCReportUrl = (onSuccess: (url: string) => void) => {
  const { operationRequest } = useClient()

  const getPlanReportUrl = async ({ carePlanId }: { carePlanId: string }) =>
    operationRequest<Parameters>({ endpoint: "CarePlan", method: "GET", operation: "report", id: carePlanId })

  const { mutate: getReportUrl, isPending } = useMutation({
    mutationFn: getPlanReportUrl,
    onSuccess: (bundle) => {
      const url = bundle.parameter?.[0]?.value?.url
      url && onSuccess(url)
    },
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
  })

  return {
    getReportUrl,
    isLoadingUrl: isPending,
  }
}

export { useMCReportUrl }
