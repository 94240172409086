import { ReactNode, useId, useState } from "react"
import { useWindowSize } from "react-use"
import { Paginator } from "primereact/paginator"
import { MenuItem } from "primereact/menuitem"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/pro-regular-svg-icons"
import { faClockRotateLeft, faPlus, faStop } from "@fortawesome/pro-solid-svg-icons"
import { MedicationKnowledge, MedicationRequest, Money } from "fhir"
import InfiniteScroll from "react-infinite-scroller"
import { classNames } from "primereact/utils"

import { Button, SkeletonLoader, useSidebarContext } from "commons"
import { MOBILE_SCREEN_MAX } from "utils"
import { useCPOEContext } from "orders"
import { MedicationKnowledgeDetails } from "commons/meds"

import { MedicationRequestData, MRActions } from "../../types"
import { MedicationDataItem } from "../common"

const MedicationRequestList = ({
  data,
  page,
  perPage,
  totalMR,
  className,
  showPagination = true,
  itemWaitingForAction,
  customEmptyMessage,
  onPerPageChange,
  onPageChange,
  hasNextPage,
  fetchNextPage,
  medicationRequestActions,
}: Props) => {
  const { width } = useWindowSize()
  const [selectedMK, setSelectedMK] = useState<MedicationKnowledge>()
  const [selectedMKPrice, setSelectedMKPrice] = useState<Money>()

  const { purchaseEnabled } = useSidebarContext()
  const { gotoShop } = useCPOEContext()

  const fetchMoreLoader = (
    <SkeletonLoader
      loaderType="list"
      skeletonShape="rectangle"
      skeletonSize="6rem"
      extraLine
      repeats={1}
      key={useId()}
    />
  )

  const dropdownMenuItem = (mr: MedicationRequest): MenuItem[] => [
    ...(medicationRequestActions.renew
      ? [
          {
            label: "Reorder",
            icon: <FontAwesomeIcon icon={faClockRotateLeft} size="sm" className="mr-2" />,
            command: () => {
              medicationRequestActions.renew?.(mr)
            },
            disabled: !["completed", "stopped"].includes(mr.status),
          },
        ]
      : []),
    {
      label: "Stop",
      icon: <FontAwesomeIcon icon={faStop} size="sm" className="mr-2" />,
      command: () => {
        medicationRequestActions.stop?.(mr.id as string)
      },
      disabled: !["active", "on-hold"].includes(mr.status),
    },
  ]

  const showData = () =>
    data?.map((medication) => (
      <MedicationDataItem
        key={medication.medicationRequestInfo.id}
        medicationKnowledge={medication.medicationKnowledge}
        medicationRequest={medication.medicationRequestInfo}
        medicationDispense={medication.medicationDispense}
        pricePerUnit={medication.pricePerUnit}
        showInstructions
        showPackagingType
        showStatus
        showDispense
        onClick={() => {
          setSelectedMK(medication.medicationKnowledge)
          setSelectedMKPrice(medication.pricePerUnit)
        }}
        dropdownMenuItems={dropdownMenuItem(medication.medicationRequestInfo)}
        isDropdownLoading={itemWaitingForAction === medication.medicationRequestInfo.id}
      />
    ))

  return (
    <div className={classNames("flex flex-col flex-1 overflow-hidden", className)}>
      {!totalMR || !data?.length ? (
        customEmptyMessage ?? (
          <div className="flex flex-col items-center justify-center h-full">
            <FontAwesomeIcon icon={faSearch} size="3x" className="text-slate-400" />
            <p className="text-md text-slate-400 pt-4 pb-2 place-self-center">No medications found</p>
            {purchaseEnabled && (
              <Button buttonStyle="primary" label="Add medications" icon={faPlus} onClick={gotoShop} />
            )}
          </div>
        )
      ) : width < MOBILE_SCREEN_MAX ? (
        <div className="h-full overflow-y-auto overflow-x-hidden">
          <InfiniteScroll
            loadMore={() => {
              fetchNextPage?.()
            }}
            hasMore={hasNextPage}
            useWindow={false}
            initialLoad={false}
            loader={fetchMoreLoader}
          >
            {showData()}
          </InfiniteScroll>
        </div>
      ) : (
        <div className="grid h-full overflow-auto grow p-2 grid-flow-row-dense content-start">{showData()}</div>
      )}

      {showPagination && width > MOBILE_SCREEN_MAX && (
        <Paginator
          first={(page - 1) * perPage}
          rows={perPage}
          totalRecords={totalMR}
          rowsPerPageOptions={[10, 20, 50, 100]}
          onPageChange={(e) => {
            e.page !== page - 1 && onPageChange?.(e.page + 1)
            e.rows !== perPage && onPerPageChange?.(e.rows)
          }}
          className="h-20"
        />
      )}

      <MedicationKnowledgeDetails
        selectedMK={selectedMK}
        onHide={() => setSelectedMK(undefined)}
        pricePerUnit={selectedMKPrice}
      />
    </div>
  )
}

type Props = {
  data?: MedicationRequestData[]
  page: number
  perPage: number
  totalMR: number
  medicationRequestActions: MRActions
  showPagination?: boolean
  className?: string
  customEmptyMessage?: JSX.Element | ReactNode
  actionsClassName?: string
  dropdownMenuItems?: MenuItem[]
  itemWaitingForAction?: string
  hasNextPage?: boolean
  fetchNextPage?(): void
  onPerPageChange?(perPage: number): void
  onPageChange?(page: number): void
}

export { MedicationRequestList }
