import { faEye, faStop, faTrashCan } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { MedicationRequestMedication, ResourceObject, codeableConceptAsString, isCarePlan } from "fhir"
import pluralize from "pluralize"

import { formatsByTypes } from "data"
import { MenuStyles, StackedListItemProps } from "commons"
import { getBadgeColor, getDateLabel, getMoneyCurrencyAlt, strCapitalize } from "utils"
import { SYSTEM_VALUES } from "system-values"

import { MedicationRequestData, MedicationRequestInfo } from "./types"
import { getDispenseFrequency } from "./utils"

export const prescriptionItemModel = ({
  mrData,
  externalAction,
  discard,
  stop,
  showDetails,
  preview,
  showStatusBadge = true,
  showAsExternalAction = false,
  loading,
}: {
  mrData: MedicationRequestData
  discard?: (mrId: string) => void
  stop?: (mrId: string) => void
  preview?: (mrData: MedicationRequestData) => void
  showDetails?: (mr: MedicationRequestInfo) => void
  externalAction?: StackedListItemProps["menu"]
  showStatusBadge?: boolean
  showAsExternalAction?: boolean
  loading?: boolean
}): StackedListItemProps => {
  const { medicationRequestInfo, medicationKnowledge, patientPrice } = mrData

  const prescriptionsStatus = medicationRequestInfo.doNotPerform ? "suspended" : medicationRequestInfo.status

  const planBased = medicationRequestInfo?.basedOn?.some(isCarePlan) ?? false
  const disabledAction = planBased || prescriptionsStatus !== "draft"
  const isStopped = prescriptionsStatus === "stopped"

  const isWriteIn = medicationRequestInfo.medication?.Reference?.localRef !== undefined
  const medication = medicationRequestInfo?.medication?.Reference?.localRef
    ? (medicationRequestInfo?.contained?.find(
        (resource) => (resource as ResourceObject)?.id === medicationRequestInfo?.medication?.Reference?.localRef,
      ) as MedicationRequestMedication)
    : (medicationRequestInfo?.medication as MedicationRequestMedication)
  const lfID = medicationKnowledge?.code?.coding?.find(
    (c) => c.system === SYSTEM_VALUES.LIFEFILE_MEDICATION_SYSTEM,
  )?.code
  const ndc = medicationKnowledge?.code?.coding?.find((c) => c.system === SYSTEM_VALUES.MEDICATION_NDC_SYSTEM)?.code
  const refillsCount = medicationRequestInfo?.dispenseRequest?.numberOfRepeatsAllowed ?? 0
  const dispenseRequest = medicationRequestInfo?.dispenseRequest
  const dispense = getDispenseFrequency(dispenseRequest?.dispenseInterval)?.label
  const nextRefill = dispenseRequest?.nextRefillDate

  return {
    leftData: [
      {
        lineItems: [
          {
            component: (
              <div className="flex items-baseline gap-2">
                <span className={preview && "cursor-pointer"} onClick={() => preview?.(mrData)}>
                  {medicationKnowledge
                    ? strCapitalize(codeableConceptAsString(medicationKnowledge?.code) as string)
                    : strCapitalize(codeableConceptAsString(medication?.CodeableConcept)) ?? "Unspecified"}
                </span>
                {medicationKnowledge?.ingredient?.length === 1 &&
                  medicationKnowledge?.ingredient[0]?.strength?.numerator?.unit && (
                    <span className="text-gray-500 whitespace-nowrap overflow-hidden text-ellipsis">
                      {medicationKnowledge?.ingredient[0].strength.numerator.unit}
                    </span>
                  )}
              </div>
            ),
          },
        ],
      },
      {
        withVerticalDivider: true,
        lineItems: [
          ...(medicationKnowledge?.manufacturer?.display
            ? [
                {
                  name: "Manufacturer",
                  value: medicationKnowledge.manufacturer.display.replace("Farmakeio", "FarmaKeio"),
                },
              ]
            : []),
          ...(medicationKnowledge?.doseForm?.coding?.[0]?.display
            ? [
                {
                  name: "Form",
                  value: medicationKnowledge?.doseForm?.coding?.[0]?.display,
                },
              ]
            : []),
          ...(medicationKnowledge?.packaging?.type?.coding?.[0]?.display &&
          medicationKnowledge?.packaging?.quantity?.value
            ? [
                {
                  name: "Packaging",
                  value: `Pkg: ${medicationKnowledge?.packaging?.type?.coding?.[0]?.display}/${medicationKnowledge?.packaging?.quantity?.value}`,
                },
              ]
            : []),
          ...(lfID
            ? [
                {
                  name: "ID",
                  value: `ID: ${lfID}`,
                },
              ]
            : []),
          ...(ndc
            ? [
                {
                  name: "NDC",
                  value: `NDC: ${ndc}`,
                },
              ]
            : []),
          ...(isWriteIn
            ? [
                {
                  name: "Type",
                  value: "Write-in",
                },
              ]
            : []),
          ...(patientPrice
            ? [
                {
                  name: "Patient Price",
                  value: `Patient Price: ${getMoneyCurrencyAlt(patientPrice?.currency)}${patientPrice?.value?.toFixed(2)}`,
                },
              ]
            : []),
        ],
      },
      {
        withVerticalDivider: true,
        lineItems: [
          {
            name: "Quantity",
            value: `Quantity: ${dispenseRequest?.quantity?.value} ${pluralize(
              dispenseRequest?.quantity?.unit ?? "unit",
              dispenseRequest?.quantity?.value,
            )}`,
          },
          ...(dispense
            ? [
                {
                  name: "Dispense",
                  value: `Dispense: ${dispense}`,
                },
              ]
            : []),
          {
            name: "Refills",
            value: `Refills: ${refillsCount} ${pluralize("refill", refillsCount)}`,
          },
          ...(nextRefill
            ? [
                {
                  name: "Next Refill",
                  value: `Next Refill: after ${getDateLabel(nextRefill, formatsByTypes.LONG_DATE)}`,
                },
              ]
            : []),
        ],
      },
    ],
    badge: showStatusBadge ? getBadgeColor(prescriptionsStatus) : undefined,
    menuStyle: showAsExternalAction ? MenuStyles.ExternalAction : undefined,
    isLoading: loading,
    menu:
      showDetails || discard || stop || externalAction?.length
        ? [
            ...(externalAction ?? []),
            ...(showDetails
              ? [
                  {
                    icon: <FontAwesomeIcon icon={faEye} />,
                    label: "Show details",
                    command: () => showDetails(medicationRequestInfo),
                  },
                ]
              : []),
            ...(stop
              ? [
                  {
                    icon: <FontAwesomeIcon icon={faStop} />,
                    label: "Stop",
                    command: () => stop(medicationRequestInfo.id ?? ""),
                    disabled: isStopped,
                  },
                ]
              : []),
            ...(discard
              ? [
                  {
                    icon: <FontAwesomeIcon icon={faTrashCan} />,
                    label: "Discard",
                    command: () => discard(medicationRequestInfo.id ?? ""),
                    disabled: disabledAction,
                  },
                ]
              : []),
          ]
        : undefined,
  }
}
