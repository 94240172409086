import { MenuItem } from "primereact/menuitem"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPrint } from "@fortawesome/pro-solid-svg-icons"
import { parseISO } from "date-fns/parseISO"
import { faCalendarDays } from "@fortawesome/pro-regular-svg-icons"

import { formatDate, getBadgeColor, openLinkInNewTab } from "utils"
import { formatsByTypes } from "data"
import { StackedListItem, StackedListItemProps } from "commons"

import { MetabolicCodeReportData } from "../types"
import { useMetabolicCodeReportUrl } from "../hooks"

const MetabolicCodeReportItem = ({ report }: Props) => {
  const { getReportUrl, isLoadingUrl } = useMetabolicCodeReportUrl(openLinkInNewTab)

  const showResult = (carePlanId: string) => {
    getReportUrl({ carePlanId })
  }

  return <StackedListItem modelData={reportItemModel(report, showResult, isLoadingUrl)} />
}

const reportItemModel = (
  data: MetabolicCodeReportData,
  showResult: (planId: string) => void,
  loading: boolean,
): StackedListItemProps => {
  const getMenuItems = () => {
    const menuItems: MenuItem[] = [
      {
        icon: <FontAwesomeIcon icon={faPrint} size="sm" className="mr-1" />,
        label: "View results",
        disabled: !data.carePlan.id,
        command: () => {
          showResult(data.carePlan.id as string)
        },
      },
    ]

    return menuItems
  }

  const issuedDate = data.issued
    ? formatDate(parseISO(new Date(data.issued as Date).toISOString()), formatsByTypes.LONG_DATE)
    : ""

  return {
    leftData: [
      {
        lineItems: [{ name: "Name", value: `Metabolic Code Report - ${issuedDate}` }],
      },
      ...(data.carePlan.meta?.createdAt
        ? [
            {
              lineItems: [
                {
                  name: "Created at",
                  value: formatDate(
                    parseISO(new Date(data.carePlan.meta.createdAt as Date).toISOString()),
                    formatsByTypes.LONG_DATETIME,
                  ),
                  icon: faCalendarDays,
                },
              ],
            },
          ]
        : []),
    ],
    badge: getBadgeColor(data.status),
    menu: getMenuItems(),
    onClick: () => showResult(data.carePlan.id as string),
    isLoading: loading,
    itemClassName: "items-center",
  }
}

type Props = { report: MetabolicCodeReportData }

export { MetabolicCodeReportItem }
