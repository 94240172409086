import { classNames } from "primereact/utils"
import { FieldProps, FormikValues } from "formik"
import { AutoComplete, AutoCompleteChangeEvent, AutoCompleteSelectEvent } from "primereact/autocomplete"
import { startTransition, useEffect, useState } from "react"

import { FormField, FormFieldBaseProps } from "./FormField"

const AutocompleteField = <T extends FormikValues>({
  readonly,
  disabled,
  multiple,
  setFilter,
  optionList,
  isLoading,
  selectedItemTemplate,
  onSelectValue,
  setValue,
  invalidCondition,
  emptyValue,
  ...fieldProps
}: Props<T>) => {
  const [suggestionList, setSuggestionList] = useState<T[]>([])

  const itemTemplate = (item: T) => {
    return (
      <span className="whitespace-nowrap overflow-hidden text-ellipsis max-w-xs">{selectedItemTemplate(item)}</span>
    )
  }

  useEffect(() => {
    if (!isLoading) setSuggestionList(optionList ?? [])
  }, [optionList, isLoading])

  return (
    <FormField {...fieldProps}>
      {({ field: { name, value, onChange }, form: { setFieldValue }, meta: { touched, error } }: FieldProps) => (
        <AutoComplete
          id={name}
          name={name}
          field="display"
          disabled={disabled}
          readOnly={readonly}
          suggestions={suggestionList}
          delay={400}
          minLength={3}
          multiple={multiple}
          selectedItemTemplate={multiple && itemTemplate}
          completeMethod={(e) => {
            startTransition(() => setFilter(e.query))
          }}
          onBlur={() => {
            if (!multiple && invalidCondition(value)) setFieldValue(name, emptyValue)
          }}
          onChange={(e) => {
            multiple ? onChange(e) : setFieldValue(name, onSelectValue ? onSelectValue(e) : e.value)
          }}
          onSelect={(e) => {
            const newValue = onSelectValue ? onSelectValue(e) : e.value
            multiple ? setFieldValue(name, [...(value ?? []), newValue]) : setFieldValue(name, newValue)
          }}
          value={multiple ? value : setValue(value)}
          className={classNames("p-inputtext-sm h-10", { "p-invalid": touched && error })}
        />
      )}
    </FormField>
  )
}

type Props<T> = FormFieldBaseProps<T> & {
  readonly?: boolean
  disabled?: boolean
  multiple?: boolean
  setFilter(query: string): void
  optionList?: T[]
  isLoading: boolean
  selectedItemTemplate(item: T): string
  onSelectValue?(event: AutoCompleteSelectEvent | AutoCompleteChangeEvent): T
  setValue(item?: T): FormikValues | string | undefined
  emptyValue: T
  invalidCondition(item?: T): boolean
}

export { AutocompleteField }
