import { useQuery } from "@tanstack/react-query"
import {
  Appointment,
  CarePlan,
  Consent,
  getResource,
  getResources,
  MedicationRequest,
  PlanDefinition,
  Procedure,
  Questionnaire,
  QuestionnaireResponse,
  ServiceRequest,
} from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { mrCategoryCodes } from "data"
import { QuestionnaireData } from "surveys/types"

import { plansQueryKeys } from "../query-keys"

const useCarePlanDetails = (patientId: string, planId?: string) => {
  const { search } = useClient()
  const queryKey = plansQueryKeys.details(patientId, planId)

  const { data, isLoading, refetch, isRefetching } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        _id: planId as string,
        _sort: "-createdAt",
        _include:
          "instantiates-canonical:PlanDefinition,outcome-reference:Procedure,outcome-reference:QuestionnaireResponse,outcome-reference:Appointment,outcome-reference:Consent,QuestionnaireResponse:questionnaire:Questionnaire",
        _revinclude: "ServiceRequest:based-on:CarePlan,MedicationRequest:based-on:CarePlan",
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/CarePlan`, filters, signal })

      const careplan = getResource<CarePlan>(bundle, "CarePlan")
      const planDefinition = getResource<PlanDefinition>(bundle, "PlanDefinition")
      const serviceRequest = getResources<ServiceRequest>(bundle, "ServiceRequest")?.find(
        (sr) => sr.category?.[0]?.coding?.[0]?.code === "lab-order",
      )
      const questionnaires = getResources<Questionnaire>(bundle, "Questionnaire")
      const questionnaireResponses = getResources<QuestionnaireResponse>(bundle, "QuestionnaireResponse")
      const appointment = getResource<Appointment>(bundle, "Appointment")
      const procedure = getResource<Procedure>(bundle, "Procedure")
      const medicationRequests = getResources<MedicationRequest>(bundle, "MedicationRequest")
      const consents = getResources<Consent>(bundle, "Consent")

      return {
        careplan,
        planDefinition,
        serviceRequest,
        questionnaires,
        questionnaireResponses,
        appointment,
        procedure,
        medicationRequests,
        consents,
      }
    },

    enabled: !!planId,
    meta: { context: { queryKey, patientId } },
  })

  const { questionnaires, isProcedurePlan, isMCPlan, isOnlySurvey, mcAlgorithmId, mcPlanIds, nutraceuticals, rx } =
    useMemo(() => {
      const questionnaires = data?.questionnaireResponses?.reduce<Array<QuestionnaireData>>((acc, qr) => {
        const questionnaire = data?.questionnaires.find((q) => `${q.url}|${q.version}` === qr.questionnaire)
        return [...acc, { qResponse: qr, questionnaire } as QuestionnaireData]
      }, [])

      const isProcedurePlan =
        data?.planDefinition?.action?.some((action) => action?.code?.[0]?.coding?.[0]?.code === "request-procedure") ??
        false
      const isMCPlan =
        data?.planDefinition?.action?.some((action) => action?.code?.[0]?.coding?.[0]?.code === "request-algorithm") ??
        false

      const mcActivity = isMCPlan
        ? data?.careplan?.activity?.find((a) => a.outcomeCodeableConcept?.[0]?.coding?.[0]?.code === "request-mc")
        : undefined
      const isOnlySurvey = mcActivity?.detail?.kind === "mc-survey"
      const mcAlgorithmId = mcActivity?.outcomeReference?.find((r) => r.resourceType === "PlanDefinition")?.id
      const mcPlanIds = mcActivity?.outcomeReference
        ?.filter((r) => r.resourceType === "CarePlan")
        ?.map(({ id }) => id ?? "")

      const defaultMeds = { nutraceuticals: Array<MedicationRequest>(), rx: Array<MedicationRequest>() }

      const { nutraceuticals, rx } =
        data?.medicationRequests.reduce((acc, mr) => {
          const isNutra = mr.category?.some(({ coding }) =>
            coding?.some(({ code }) => mrCategoryCodes.nutraceutical?.code === code),
          )
          const isRx = mr.category?.some(({ coding }) =>
            coding?.some(({ code }) => mrCategoryCodes.medication?.code === code),
          )
          if (isNutra) return { ...acc, nutraceuticals: [...acc.nutraceuticals, mr] }
          if (isRx) return { ...acc, rx: [...acc.rx, mr] }
          return { ...acc }
        }, defaultMeds) ?? defaultMeds

      return {
        questionnaires,
        isProcedurePlan,
        isMCPlan,
        isOnlySurvey,
        mcAlgorithmId,
        mcPlanIds,
        nutraceuticals,
        rx,
      }
    }, [data?.careplan?.activity, data?.planDefinition?.action, data?.questionnaireResponses, data?.questionnaires])

  return {
    carePlan: data?.careplan,
    planDefinition: data?.planDefinition,
    serviceRequest: data?.serviceRequest,
    questionnaires,
    appointment: data?.appointment,
    procedure: data?.procedure,
    consents: data?.consents,
    isLoading,
    isProcedurePlan,
    isMCPlan,
    isOnlySurvey,
    mcAlgorithmId,
    mcPlanIds,
    refetch,
    isRefetching,
    nutraceuticals,
    rx,
  }
}

export { useCarePlanDetails }
