import { useContext } from "react"

import { registerErrorTrace } from "logger"
import { CustomError } from "commons"

import { LoginContext } from "../context/LoginProvider"

const useLoginContext = () => {
  const context = useContext(LoginContext)

  if (context === undefined) {
    throw registerErrorTrace(new Error("useLoginContext must be used within a LoginProvider") as CustomError)
  }

  return context
}

export { useLoginContext }
