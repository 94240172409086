import { useQuery } from "@tanstack/react-query"
import { asReference, getResources, Organization, Reference } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"

import { insuranceProvidersKeys } from "../query-keys"

const useInsuranceProviders = (filterText?: string) => {
  const { search } = useClient()
  const queryKey = insuranceProvidersKeys.withFilter(filterText)

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async () => {
      const filters = new URLSearchParams({
        type: "insurance-provider",
        active: "true",
        ...(filterText ? { _name: filterText } : {}),
        _count: "20",
      })

      const bundle = await search({ endpoint: "Organization", filters })

      const insuranceProviders = getResources<Organization>(bundle, "Organization")

      return { insuranceProviders }
    },

    enabled: !!filterText,
    meta: { context: { queryKey } },
  })

  const insuranceProviderRefs = useMemo(() => {
    const insuranceProviderRefs = data?.insuranceProviders.reduce<Reference[]>(
      (acc, item) => [...acc, asReference(item)],
      [],
    )
    return insuranceProviderRefs
  }, [data?.insuranceProviders])

  return {
    insuranceProviders: insuranceProviderRefs,
    isLoading,
  }
}

export { useInsuranceProviders }
