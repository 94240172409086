import { useInfiniteQuery } from "@tanstack/react-query"
import { getResources, Invoice, Task } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"

import { invoicesKeys } from "../query-keys"

const useInvoices = ({ patientId, statusFilter = [], searchText, includeTasks }: InvoicesArgs) => {
  const { search } = useClient()

  const queryKey = invoicesKeys.withStatusAndSearchFilter(patientId, statusFilter, searchText)

  const { data, isLoading, isError, error, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery<
    InvoicesQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = 1 }) => {
      const filters = new URLSearchParams({
        ...(searchText ? { _ilike: searchText } : {}),
        ...(statusFilter.length > 0 ? { status: statusFilter.join(",") } : {}),
        ...(includeTasks ? { _revinclude: "Task:focus" } : {}),
        recipient: patientId,
        _count: "20",
        _page: `${pageParam}`,
        _sort: "-date",
      })
      const bundle = await search({ endpoint: `Patient/${patientId}/Invoice`, filters })
      const invoices = getResources<Invoice>(bundle, "Invoice")
      const tasks = getResources<Task>(bundle, "Task")

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return { invoices, tasks, next, total: bundle?.total ?? 0 }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, patientId } },
  })
  const { invoices, dependsTasks } = useMemo(() => {
    const invoices = data?.pages.flatMap((page) => page.invoices)
    const tasks = data?.pages.flatMap((page) => page.tasks)

    const dependsTasks =
      tasks?.reduce<Record<string, Task>>((acc, task) => {
        if (
          ["complete-shipping-address", "complete-shipping-method", "complete-cc"].includes(
            task.code?.coding?.[0].code ?? "",
          )
        )
          return { ...acc, [task.focus?.id as string]: task }

        return acc
      }, {}) ?? {}

    return {
      invoices,
      dependsTasks,
    }
  }, [data?.pages])

  if (isError) {
    throw error
  }

  return {
    invoices,
    dependsTasks,
    isLoading,
    count: invoices?.length ?? 0,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  }
}

type InvoicesQueryData = { invoices: Invoice[]; tasks: Task[]; next: number | undefined; total: number }

interface InvoicesArgs {
  patientId: string
  statusFilter?: string[]
  searchText?: string
  includeTasks?: boolean
}

export { useInvoices }
