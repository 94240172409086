import { faFilter, faFilterSlash } from "@fortawesome/pro-regular-svg-icons"
import { faFilter as faFilterSolid , faSpinner } from "@fortawesome/pro-solid-svg-icons"
import { OverlayPanel } from "primereact/overlaypanel"
import { useRef } from "react"
import { Form, Formik, FormikProps } from "formik"
import { Composition } from "fhir"
import { Chip } from "primereact/chip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { Button, DropdownField, SearchInput } from "commons"

const MedicationCatalogListFilters = ({ isLoading, catalogs, catalog, setSelectedCatalog, setSearchFilter }: Props) => {
  const overlayFilter = useRef<OverlayPanel>(null)

  return (
    <div className="flex flex-col w-full drop-shadow mb-3">
      <div className="flex flex-wrap justify-between w-full gap-3 mb-3 mr-1">
        <SearchInput search={setSearchFilter} isLoading={isLoading} className="flex-1" />
        {catalogs.length > 1 && (
          <>
            <Button
              buttonStyle="default"
              icon={catalog ? faFilterSolid : faFilter}
              className={catalog ? "text-primary" : undefined}
              onClick={(e) => overlayFilter?.current?.toggle(e)}
            />
            <OverlayPanel
              ref={overlayFilter}
              showCloseIcon={false}
              dismissable
              id="overlay_panel"
              className="form-panel overflow-hidden min-w-96 2xl:min-w-80"
            >
              <Formik
                initialValues={{ catalog }}
                onSubmit={(data) => {
                  setSelectedCatalog(data.catalog)
                  overlayFilter?.current?.hide()
                }}
              >
                {({ isSubmitting }: FormikProps<{ catalog: string | undefined }>) => (
                  <Form className="flex flex-col h-full bg-white w-full">
                    <DropdownField
                      label="Catalog"
                      field="catalog"
                      options={catalogs}
                      optionLabel="title"
                      optionValue="id"
                      className="flex-1 flex"
                      inputClassName="slashed"
                    />
                    <div className="flex w-full justify-end pt-3 lg:pt-5 h-12 lg:h-16 bg-white">
                      <Button
                        buttonStyle="default"
                        label="Clear"
                        disabled={isSubmitting || !catalog}
                        icon={faFilterSlash}
                        onClick={() => {
                          setSelectedCatalog()
                          overlayFilter?.current?.hide()
                        }}
                        className="mr-3"
                      />
                      <Button type="submit" label="Search" loading={isSubmitting} />
                    </div>
                  </Form>
                )}
              </Formik>
            </OverlayPanel>
          </>
        )}
      </div>
      <div className="flex items-center gap-2">
        {catalog ? (
          <>
            <span>Filtering by:</span>
            {isLoading ? (
              <FontAwesomeIcon icon={faSpinner} spin className="text-primary" />
            ) : (
              <Chip
                key={getSelectedCatalog(catalog, catalogs)?.id}
                label={getSelectedCatalog(catalog, catalogs)?.title}
                className="text-sm text-center text-white bg-primary whitespace-nowrap max-h-[2rem]"
              />
            )}
          </>
        ) : (
          <span>No catalog selected</span>
        )}
      </div>
    </div>
  )
}

type Props = {
  isLoading: boolean
  catalogs: Composition[]
  catalog?: string
  setSelectedCatalog(catalogId?: string): void
  setSearchFilter(filter?: string): void
}

const getSelectedCatalog = (id: string, catalogs: Composition[]) =>
  catalogs.filter((catalog: Composition) => catalog.id === id)?.[0]

export { MedicationCatalogListFilters }
