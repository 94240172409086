import { useMutation } from "@tanstack/react-query"
import { Parameters } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { useLoginContext } from "security"

const useAuthorizeBasisTheorySession = () => {
  const { operationRequest } = useClient()
  const { loggedInPatientId } = useLoginContext()

  const authorizeBT = async ({ nonce }: { nonce: string }): Promise<AuthorizeResponse> => {
    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "nonce",
          value: { string: nonce },
        },
      ],
    }

    const responseParameters = await operationRequest<Parameters>({
      endpoint: `Patient/${loggedInPatientId}`,
      method: "POST",
      operation: "bt-authorize",
      parameters,
    })

    const container = responseParameters.parameter?.find(({ name }) => name === "container")?.value?.string ?? ""

    return { container }
  }

  const { mutateAsync: authorize, isPending: isAuthorizing } = useMutation({
    mutationFn: authorizeBT,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
  })

  return { authorize, isAuthorizing }
}

type AuthorizeResponse = {
  container: string
}

export { useAuthorizeBasisTheorySession }
