import { FC } from "react"

const NotificationSuccess: FC<Props> = ({ message }) => (
  <div className="flex flex-col">
    <p>{message}</p>
  </div>
)

type Props = { message: string }

export { NotificationSuccess }
