import { FormikValues } from "formik"
import { useCallback, useReducer } from "react"

const useCrudReducer = <T extends FormikValues, K extends FormikValues>({
  defaultEntity,
  extraState,
  extraStateReducer,
}: Props<T, K>) => {
  const initState = {
    ...extraState,
    initialValue: defaultEntity,
    showSlide: false,
    isNew: false,
  } as BasicCrudState<T, K>

  const reducer = (
    state: BasicCrudState<T, K>,
    { type, payload }: { type: string; payload?: number | string | T | K | EditableEntity<T> },
  ): BasicCrudState<T, K> => {
    switch (type) {
      case "hardReset":
        return initState
      case "reset":
        return { ...state, isNew: false, showSlide: false, initialValue: defaultEntity as T }
      case "add":
        return { ...state, isNew: true, showSlide: true, initialValue: payload as T }
      case "edit":
        return { ...state, isNew: false, showSlide: true, initialValue: payload as T }
      case "editWithIndex":
        return { ...state, isNew: false, showSlide: true, ...(payload as EditableEntity<T>) }
      case "setDeleteIndex":
        return { ...state, deleteIndex: payload as number | string }
      case "remove":
        return { ...state, removeItem: payload as T }
      case "selectItem":
        return { ...state, selectedItem: payload as T }
      case "selectItemIndex":
        return { ...state, selectedItemIndex: payload as number | string }
      default:
        if (extraStateReducer) return extraStateReducer(state, { type, payload })
        return state
    }
  }

  const [state, dispatch] = useReducer(reducer, initState)

  const reset = () => dispatch({ type: "reset" })

  const hardReset = () => dispatch({ type: "hardReset" })

  const add = useCallback(() => {
    dispatch({ type: "add", payload: defaultEntity as T })
  }, [defaultEntity])

  const edit = (value: T) => {
    dispatch({ type: "edit", payload: value })
  }

  const editWithIndex = (value: T, index: number) => {
    dispatch({ type: "editWithIndex", payload: { initialValue: value, editIndex: index } })
  }

  const remove = (item: T) => {
    dispatch({ type: "remove", payload: item })
  }

  const setDeleteIndex = (index?: number | string) => {
    dispatch({ type: "setDeleteIndex", payload: index })
  }

  const selectItem = (item: T) => {
    dispatch({ type: "selectItem", payload: item })
  }

  const selectItemIndex = (index?: number | string) => {
    dispatch({ type: "selectItemIndex", payload: index })
  }

  return {
    initialValue: state.initialValue,
    showSlide: state.showSlide,
    isNew: state.isNew,
    editIndex: state.editIndex,
    deleteIndex: state.deleteIndex,
    removeItem: state.removeItem,
    selectedItem: state.selectedItem,
    selectedItemIndex: state.selectedItemIndex,
    state,
    reset,
    hardReset,
    add,
    edit,
    editWithIndex,
    setDeleteIndex,
    remove,
    selectItem,
    selectItemIndex,
    dispatch,
  }
}

type Props<T, K> = {
  defaultEntity?: T
  extraState?: K
  extraStateReducer?: (state: BasicCrudState<T, K>, { type, payload }: CrudReducerState<T, K>) => BasicCrudState<T, K>
}

export type CrudReducerState<T, K> = {
  type: string
  payload?: number | string | boolean | T | K | EditableEntity<T>
}

type EditableEntity<T> = {
  initialValue: T
  editIndex: number
}

export type BasicCrudState<T, K> = K & {
  initialValue: T
  showSlide: boolean
  isNew: boolean
  editIndex?: number
  deleteIndex?: number | string
  removeItem?: T
  selectedItem?: T
  selectedItemIndex?: number | string
}

export { useCrudReducer }
