import { useSearchParams } from "react-router-dom"

import { PlanListContainer } from "./PlanListContainer"
import { PlanDetails } from "./details/PlanDetails"

const PlansContainer = () => {
  const [searchParams] = useSearchParams()

  return <>{searchParams.get("planId") !== null ? <PlanDetails /> : <PlanListContainer />}</>
}

export { PlansContainer }
