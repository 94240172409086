import { datadogLogs } from "@datadog/browser-logs"
import { v4 } from "uuid"

import { CustomError } from "commons"

import packageJson from "../../package.json"

const isDevelopment = process.env.NODE_ENV === "development"

const init = () => {
  datadogLogs.init({
    clientToken: "pub2e064c7e8fa9f4a7a1a3c867951e0cb3",
    site: "us5.datadoghq.com",
    service: packageJson.name,
    version: packageJson.version,
    env: window.VITE_APP_ENVIROMENT_NAME,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    beforeSend: () => {
      return !isDevelopment
    },
  })
}

const registerErrorTrace = (error: CustomError, context?: unknown) => {
  const apmTransactionTraceId = v4()
  const customErr: CustomError = {
    ...error,
    cause: {
      ...error.cause,
      traceId: apmTransactionTraceId,
    },
  }

  datadogLogs.logger.error(
    error.message,
    { custom_trace: apmTransactionTraceId, ...(context ? { data: context } : {}) },
    error,
  )

  return customErr
}

export { init, datadogLogs, registerErrorTrace }
