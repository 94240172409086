import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome, faPills, faStore, faUser, faVials, IconDefinition } from "@fortawesome/pro-regular-svg-icons"
import { PropsWithChildren } from "react"
import { Link, LinkProps, useMatch, useResolvedPath } from "react-router-dom"
import { classNames } from "primereact/utils"
import { Badge } from "primereact/badge"

import { useCPOEContext } from "orders"
import { useSidebarContext } from "commons"

const BottomNav = ({ className }: Props) => {
  const { purchaseEnabled } = useSidebarContext()
  const { ordersCount } = useCPOEContext()
  const { isModuleActive } = useSidebarContext()

  return (
    <div className={classNames("bottom-nav w-full flex flex-row space-around items-center z-10", className)}>
      <NavLink to="/" icon={faHome}>
        Home
      </NavLink>
      {isModuleActive("nutraceuticals") && (
        <NavLink to="nutraceuticals" icon={faPills}>
          Nutra
        </NavLink>
      )}
      {isModuleActive("labs") && (
        <NavLink to="labs" icon={faVials}>
          Labs
        </NavLink>
      )}
      {purchaseEnabled && (
        <NavLink icon={faStore} to="shop" showBadge={ordersCount > 0}>
          Shop
        </NavLink>
      )}
      <NavLink to="profile" icon={faUser}>
        Profile
      </NavLink>
    </div>
  )
}

const NavLink = ({
  to,
  icon,
  children,
  showBadge,
}: PropsWithChildren<LinkProps & { icon: IconDefinition; showBadge?: boolean }>) => {
  const resolved = useResolvedPath(to)
  const match = useMatch({ path: resolved.pathname })

  return (
    <Link
      to={to}
      className={classNames("grow flex flex-col items-center hover:text-blue-400 relative", {
        "text-blue-500": match,
      })}
    >
      {showBadge && !match && <Badge className="nav-badge text-blue-400/80" />}
      <FontAwesomeIcon icon={icon} size="lg" />
      <small className="font-semibold">{children}</small>
    </Link>
  )
}

type Props = {
  className?: string
}

export { BottomNav }
