import { Address } from "fhir"
import { FormikErrors } from "formik"
import React, { createContext, ReactNode, useState } from "react"

import { AddressVerificationInfo } from "../hooks"

interface SmartyAddressVerificationContextProps {
  isOverlayVisible: boolean
  showAutoCompleteOverlay: () => void
  hideAutoCompleteOverlay: () => void
  addressVerificationInfo?: AddressVerificationInfo
  updateAddressVerificationInfo: React.Dispatch<React.SetStateAction<AddressVerificationInfo | undefined>>
  autoCompleteRecommendedAddress?: (
    setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) => Promise<void | FormikErrors<Address>>,
    parentFieldName?: string,
  ) => void
}

const SmartyAddressVerificationContext = createContext<SmartyAddressVerificationContextProps | undefined>(undefined)

const SmartyAddressVerificationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false)
  const [addressVerificationInfo, setAddressVerificationInfo] = useState<AddressVerificationInfo>()

  const showAutoCompleteOverlay = () => setIsOverlayVisible(true)
  const hideAutoCompleteOverlay = () => setIsOverlayVisible(false)

  const autoCompleteRecommendedAddress = (
    setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) => Promise<void | FormikErrors<Address>>,
    parentFieldName = "",
  ) => {
    if (addressVerificationInfo?.recommendedAddress?.address) {
      const {
        recommendedAddress: { address: recommendedAddressData },
      } = addressVerificationInfo

      Object.entries(recommendedAddressData).forEach(([key, value]) => {
        if (key === "line") {
          value.forEach((line: string, index: number) => {
            setFieldValue(`${parentFieldName}line[${index}]`, line)
          })
        } else {
          setFieldValue(`${parentFieldName}${key}`, value)
        }
      })

      if (addressVerificationInfo.shouldPromptAutoCompleteOnUse) {
        showAutoCompleteOverlay()
      }
    }
  }

  return (
    <SmartyAddressVerificationContext.Provider
      value={{
        isOverlayVisible,
        showAutoCompleteOverlay,
        hideAutoCompleteOverlay,
        addressVerificationInfo,
        updateAddressVerificationInfo: setAddressVerificationInfo,
        autoCompleteRecommendedAddress,
      }}
    >
      {children}
    </SmartyAddressVerificationContext.Provider>
  )
}

export { SmartyAddressVerificationContext, SmartyAddressVerificationProvider }
