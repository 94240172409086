import { AccountBETACreditCardArray, humanNameAsString, Patient } from "fhir"
import { getAddressByType } from "fhir/utils"
import * as Yup from "yup"

import { emptyAddress } from "data"
import { getAddressSchema } from "utils"

import { CreditCardFormData } from "../types"

const getInitialValues = (patient: Patient): CreditCardFormData => ({
  billingAddress: getAddressByType(patient.address, "postal") ?? emptyAddress,
  cardHolderName: humanNameAsString(patient?.name?.[0]),
  expirationYear: undefined,
  expirationMonth: undefined,
  expirationDate: undefined,
  last4Digits: undefined,
  number: undefined,
  type: undefined,
  cvv: undefined,
})

const sanitize = ({ ...creditCard }: CreditCardFormData): AccountBETACreditCardArray => {
  delete creditCard.expirationDate
  delete creditCard.number
  delete creditCard.cvv

  return creditCard
}

const creditCardValidationSchema = Yup.object({
  billingAddress: getAddressSchema("billingAddress"),
  cardHolderName: Yup.string().required("Holder name is required"),
})

export { creditCardValidationSchema, getInitialValues, sanitize }
