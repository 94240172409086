import { useContext } from "react"

import { CustomError } from "commons"
import { registerErrorTrace } from "logger"

import { AuthContext } from "../context/AuthProvider"

const useAuth = () => {
  const context = useContext(AuthContext)

  if (context === undefined) {
    throw registerErrorTrace(
      new Error("useAuth must be used within a AuthProvider", {
        cause: { name: "404", message: "useAuth must be used within a AuthProvider", logoutFlag: true },
      }) as CustomError,
    )
  }

  return context
}

export { useAuth }
