import { ReactNode } from "react"
import { Field, FieldProps, ErrorMessage, FormikValues } from "formik"
import { classNames } from "primereact/utils"

const FormField = <T extends FormikValues | string | number>({
  field,
  label,
  children,
  className,
  horizontal,
  containerClassName,
  showInvalidState,
  validation,
}: FormFieldProps<T>) => (
  <Field name={field} validate={validation}>
    {(props: FieldProps) => (
      <div
        className={classNames(
          "field relative",
          horizontal ? "inline-flex justify-between horizontal" : "flex flex-col",
          className,
        )}
      >
        {label && (
          <label
            htmlFor={props.field.name}
            className={classNames("text-sm font-medium text-gray-700 mb-2", { "mr-3 mb-0 mt-2": horizontal })}
          >
            {label}
          </label>
        )}
        <div
          className={classNames("flex flex-col flex-1", containerClassName, {
            "ring-1 ring-red-500 rounded-md overflow-hidden":
              showInvalidState && props.meta.error && props.meta.touched,
          })}
        >
          {typeof children === "function" ? children(props) : children}
        </div>
        <div className="flex items-start p-error h-2">
          <ErrorMessage name={field}>{(msg) => <small>{msg}</small>}</ErrorMessage>
        </div>
      </div>
    )}
  </Field>
)

export type FormFieldProps<T = FormikValues> = {
  field: string
  label?: string
  className?: string
  horizontal?: boolean
  containerClassName?: string
  showInvalidState?: boolean
  children: ReactNode | ((props: FieldProps) => ReactNode)
  validation?(value: T): void
}

export type FormFieldBaseProps<T = FormikValues> = Omit<FormFieldProps<T>, "children" | "showInvalidState">

export { FormField }
