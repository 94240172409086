import { faSignOut } from "@fortawesome/pro-light-svg-icons"
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "primereact/button"

import { useAuth } from "security"
import { CustomError } from "commons"

import { ERROR_CAUSE } from "../types"

const UnauthorizedErrorView = ({ error }: { error?: CustomError }) => {
  const { user, logout } = useAuth()
  const name = user?.name ?? "unspecified name"
  const email = user?.email ?? "unspecified email"

  return (
    <div className="flex items-center justify-center w-full h-full">
      <div className="text-center">
        <p className="p-error text-5xl">401</p>
        <h1 className="text-4xl py-2 font-bold">{ERROR_CAUSE.UNAUTHORIZED}</h1>
        <p className="text-gray-400 py-2 pb-4">
          Sorry, logged in user <span className="font-bold">{name}</span> with email{" "}
          <span className="font-bold">{email}</span> has not access
        </p>
        {error && (
          <div className="text-sm bg-indigo-100 text-gray-600 p-2 rounded-lg">
            <span className="flex items-center">
              <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
              {error?.cause?.message}
            </span>
          </div>
        )}
        <Button onClick={logout} className="p-button button-primary mt-4">
          <span className="flex items-center">
            <FontAwesomeIcon icon={faSignOut} className="mr-2" /> Logout
          </span>
        </Button>
      </div>
    </div>
  )
}

export { UnauthorizedErrorView }
