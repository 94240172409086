import { useQuery } from "@tanstack/react-query"
import { useMemo } from "react"

import { CATALOG_VISIBILITY_FLAG } from "../types"
import { settingsKeys } from "../query-keys"
import { useSettingQueryFunction } from "./useSetting"

const usePurchaseSetting = (organizationId: string) => {
  const getSetting = useSettingQueryFunction()
  const queryKey = settingsKeys.purchaseSetting(organizationId)

  const { data, isError, error } = useQuery({
    queryKey,
    queryFn: async () => await getSetting(organizationId, "medications-catalogs"),
    meta: { context: { queryKey, organizationId } },
  })

  const { activeCatalogs } = useMemo(() => {
    const activeCatalogs = data?.items?.reduce<string[]>((acc, setting) => {
      const catalogId =
        setting.value?.boolean &&
        setting.flag?.some(({ coding }) => coding?.some(({ code }) => code === CATALOG_VISIBILITY_FLAG)) &&
        setting.value.Reference
          ? [setting.value.Reference.id as string]
          : []
      return [...acc, ...catalogId]
    }, [])

    return { activeCatalogs }
  }, [data])

  if (isError) {
    throw error
  }

  return {
    activeCatalogs: activeCatalogs ?? [],
    purchaseEnabled: !!activeCatalogs?.length,
  }
}

export { usePurchaseSetting }
