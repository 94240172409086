import { faCartShopping } from "@fortawesome/pro-regular-svg-icons"
import { asReference, Duration, isPractitionerRole, MedicationKnowledge, MedicationRequest, Reference } from "fhir"
import { Message } from "primereact/message"
import { Sidebar } from "primereact/sidebar"
import { FC, useEffect, useState } from "react"

import { Button, ConfirmDialog, SkeletonLoader, useSidebarContext } from "commons"
import {
  getInitialValues,
  sanitize,
  useCreateMedicationRequest,
  useDeleteMedicationRequest,
  useDraftMedicationRequests,
  useUpdateMedicationRequest,
} from "medication-requests"
import { useCPOEContext } from "orders"
import { usePatientGeneralPractitionerRole } from "patient"
import { useLoginContext } from "security"

import { MedicationKnowledgeList } from "./MedicationKnowledgeList"
import { ShoppingCartSummary } from "./ShoppingCartSummary"

const CartContainer: FC = () => {
  const { loggedInPatient, loggedInPatientId } = useLoginContext()
  const { isSmallScreen, organizationId } = useSidebarContext()
  const { practitionerRole } = usePatientGeneralPractitionerRole(loggedInPatient, organizationId)
  const { ordersCount, isLoadingOrdersCount, refreshCpoeCount, gotoCheckout } = useCPOEContext()
  const [showCart, setShowCart] = useState(false)
  const [mrToDelete, setMrToDelete] = useState<string>()

  const {
    isLoading: isLoadingMR,
    isFetching: isFetchingMR,
    addedMeds,
    medicationRequests,
  } = useDraftMedicationRequests(loggedInPatientId)

  const checkIfPrescribedByPract = (mrId: string) =>
    isPractitionerRole(medicationRequests?.find((mr) => mr.id === mrId)?.recorder)

  const { updateMedicationReq, isUpdating } = useUpdateMedicationRequest(refreshCpoeCount)
  const { createMedicationReq, isAdding } = useCreateMedicationRequest(() => {
    refreshCpoeCount()
  })
  const { deleteMedicationRequest, isDeleting } = useDeleteMedicationRequest(
    () => {
      refreshCpoeCount()
    },
    () => setMrToDelete(undefined),
  )

  const prepareMR = (mk: MedicationKnowledge, units: number, catalogAuthor: Reference, duration: Duration) => {
    if (practitionerRole) {
      const newMR = getInitialValues(mk, units, asReference(loggedInPatient), catalogAuthor, duration, practitionerRole)

      return {
        ...newMR,
        dispenseRequest: { ...newMR.dispenseRequest, numberOfRepeatsAllowed: duration.code === "mo" ? undefined : 0 },
      }
    }
  }
  const addMK = (mk: MedicationKnowledge, units: number, catalogAuthor: Reference, duration: Duration) => {
    const newMR = prepareMR(mk, units, catalogAuthor, duration) as MedicationRequest

    createMedicationReq(sanitize(newMR))
  }

  const editMK = (mk: MedicationKnowledge, units: number, catalogAuthor: Reference, duration: Duration) => {
    const newMR = prepareMR(mk, units, catalogAuthor, duration) as MedicationRequest

    updateMedicationReq(sanitize(newMR))
  }

  useEffect(() => {
    if (!isLoadingOrdersCount && showCart) setShowCart(ordersCount !== 0)
  }, [ordersCount, isLoadingOrdersCount])

  const isLoading = isLoadingMR || isFetchingMR || isDeleting || isAdding || isUpdating

  if (isLoadingMR) return <SkeletonLoader repeats={5} loaderType="two-lines" />

  return (
    <>
      {!practitionerRole && (
        <Message text="To buy a product you need to have a general practitioner set" severity="warn" />
      )}
      <MedicationKnowledgeList
        onAddMK={addMK}
        onEditMK={editMK}
        addedMeds={addedMeds}
        onDelete={deleteMedicationRequest}
        isAdding={isAdding || isUpdating}
        isDeleting={isDeleting}
        isDisabled={!practitionerRole}
      />
      <Button
        buttonStyle="default"
        icon={faCartShopping}
        label={ordersCount > 0 ? `${ordersCount}` : undefined}
        size="xl"
        className="fixed top-3 lg:top-5 right-4"
        loading={isLoadingOrdersCount}
        disabled={!ordersCount}
        onClick={() => setShowCart(true)}
      />
      <Sidebar
        visible={showCart}
        onHide={() => setShowCart(false)}
        header={<div className="font-medium text-lg text-gray-900 flex flex-1">Shopping Cart</div>}
        position={isSmallScreen ? "bottom" : "right"}
        className={isSmallScreen ? "h-[95%] rounded-t-xl" : "md:w-1/2 lg:w-1/3"}
      >
        <ShoppingCartSummary
          mrToDelete={mrToDelete}
          onDelete={setMrToDelete}
          isLoading={isLoading}
          onCheckout={gotoCheckout}
        />
      </Sidebar>
      <ConfirmDialog
        actionName="Delete"
        confirmText={
          mrToDelete && checkIfPrescribedByPract(mrToDelete)
            ? "This medication was prescribed by your practitioner, are you sure you want to remove it?"
            : "Are you sure that you want to delete this item?"
        }
        hideDialog={() => setMrToDelete(undefined)}
        isLoading={isDeleting}
        visible={!!mrToDelete}
        onConfirm={() => deleteMedicationRequest(mrToDelete as string)}
      />
    </>
  )
}

export { CartContainer }
