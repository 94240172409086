import { useQuery } from "@tanstack/react-query"
import { ValueSet } from "fhir"

import { useClient } from "api"

import { valueSetKeys } from "../query-keys"

const useValueSet = (valueSetId: string, filter?: string | undefined, dontFilter?: boolean) => {
  const { read } = useClient()
  const queryKey = valueSetKeys.withFilter(valueSetId, filter)

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async () => {
      const filters = filter ? new URLSearchParams({ filter, count: "30" }) : undefined

      const { expansion } = await read<ValueSet>({ endpoint: "ValueSet", id: valueSetId, filters, operation: "expand" })

      return expansion?.contains?.map(({ code, system, display }) => ({ code, system, display })) ?? []
    },
    enabled: !!filter || dontFilter,
    meta: { context: { queryKey, valueSetId, filter } },
  })

  return { codes: data, isLoading }
}

export { useValueSet }
