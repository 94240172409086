import { useContext } from "react"

import { CustomError } from "commons"
import { registerErrorTrace } from "logger"

import { VideoCallContext } from "../context"

const useVideoCallContext = () => {
  const context = useContext(VideoCallContext)

  if (context === undefined) {
    throw registerErrorTrace(new Error("useVideoCallContext must be used within a VideoCallProvider") as CustomError)
  }

  return context
}

export { useVideoCallContext }
