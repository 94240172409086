const accountKeys = {
  all: ["patient/account"],
  withPatientId: (patientId: string) => [...accountKeys.all, patientId] as const,
}

const creditCardsKeys = {
  all: ["patient/credit-cards"] as const,
  withPatientId: (patientId: string) => [...creditCardsKeys.all, patientId] as const,
}

export { accountKeys, creditCardsKeys }
