import { FormikValues } from "formik"
import { PropsWithChildren } from "react"

import { Slideover, SlideoverProps } from "../components/Slideover"
import { useSidebarContext } from "../hooks"
import { FormContainer } from "./FormContainer"
import { FormContainerProps } from "./types"

const SlideFormContainer = <T extends FormikValues>({
  showSlide,
  onCancel,
  showCloseIcon = true,
  children,
  ...formProps
}: PropsWithChildren<Props<T>>) => {
  const { isSmallScreen } = useSidebarContext()
  return (
    <Slideover
      showSlide={showSlide}
      showCloseIcon={false}
      dismissable={false}
      onHide={onCancel ?? (() => {})}
      position={isSmallScreen ? "bottom" : "right"}
      className={isSmallScreen ? "h-[95%] rounded-t-xl" : "md:w-1/2 lg:w-1/3"}
    >
      <FormContainer onCancel={onCancel} {...formProps} showCloseIcon={showCloseIcon} enableReinitialize>
        {children}
      </FormContainer>
    </Slideover>
  )
}

type Props<T extends FormikValues> = Omit<
  FormContainerProps<T>,
  "enableReinitialize" | "className" | "title" | "subTitle"
> &
  Omit<SlideoverProps, "onHide">

export { SlideFormContainer }
