import { useSearchParams } from "react-router-dom"

import { SimpleBreadCrumb } from "commons"
import { InvoiceDetailsContainer } from "./InvoiceDetailsContainer"
import { PaymentDetailsContainer } from "./PaymentDetailsContainer"

const PaymentContainer = ({ invoiceId, paymentId }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const breadCrumbTitle = `${invoiceId ? "Invoice" : "Payment"} details`

  const goBack = () => {
    searchParams.delete("invoiceId")
    searchParams.delete("paymentId")
    setSearchParams(searchParams)
  }

  return (
    <div className="h-full w-full flex flex-col relative p-3 lg:px-5 pb-0">
      <SimpleBreadCrumb goHome={goBack} title={breadCrumbTitle} />
      <div className="flex flex-col h-full w-full overflow-auto bg-white">
        {invoiceId && <InvoiceDetailsContainer />}
        {paymentId && <PaymentDetailsContainer />}
      </div>
    </div>
  )
}

type Props = {
  invoiceId: string | null
  paymentId: string | null
}

export { PaymentContainer }
