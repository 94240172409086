import { useInfiniteQuery } from "@tanstack/react-query"
import { parseISO } from "date-fns"
import {
  Coding,
  DiagnosticReport,
  DocumentReference,
  Money,
  PlanDefinition,
  Practitioner,
  PractitionerRole,
  ServiceRequest,
  Specimen,
  Task,
  getResources,
  getResourcesByTypeAsIndex,
  humanNameAsString,
} from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { useCIDQueryFunction } from "commons"
import { BillingTypeCodes, ServiceRequestCategory, formatsByTypes } from "data"
import {
  convertIdentifiersToCodings,
  formatDate,
  getBasePrice,
  getCidIdentifier,
  getCommonCode,
  getServiceRequestBillingType,
} from "utils"

import { laboratoryOrdersKeys } from "../query-keys"
import { LaboratoryOrder, LaboratoryOrderCombo, LaboratoryOrderPanel } from "../types"

const useLaboratoryOrders = (
  organizationId: string,
  patientId: string,
  statusFilter: string[],
  searchText?: string,
) => {
  const { search } = useClient()
  const queryKey = laboratoryOrdersKeys.withStatusAndSearchFilter(patientId, statusFilter, searchText)

  const getChargeItemDefinitions = useCIDQueryFunction()

  const { data, isLoading, isError, error, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery<
    LaboratoryOrderQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        ...(searchText ? { _filter: searchText } : {}),
        ...(statusFilter.length > 0 ? { status: statusFilter.join(",") } : {}),
        _query: "laboratories-data",
        patient: patientId,
        _count: "20",
        _page: `${pageParam}`,
        _sort: "-authored",
        _include: "based-on",
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/ServiceRequest`, filters, signal })

      const laboratoryRequests = getResources<ServiceRequest>(bundle).filter(
        (record) => record.category?.[0]?.coding?.[0].code === ServiceRequestCategory.LAB_ORDER,
      )

      const serviceRequests = getResources<ServiceRequest>(bundle, "ServiceRequest")
      const planDefinitions = getResources<PlanDefinition>(bundle, "PlanDefinition")
      const diagnosticReports = getResources<DiagnosticReport>(bundle, "DiagnosticReport")
      const tasks = getResources<Task>(bundle, "Task")
      const specimens = getResources<Specimen>(bundle, "Specimen")
      const practRoles = getResources<PractitionerRole>(bundle, "PractitionerRole")
      const documentsByOrder = getResources<DocumentReference>(bundle, "DocumentReference").reduce<
        Record<string, string>
      >((prev, doc) => {
        const newDocOrderId = doc.context?.related?.[0]?.id
        const newDocAttachment = doc.content[0].attachment.url
        return !!newDocAttachment && !!newDocOrderId ? { ...prev, [newDocOrderId]: newDocAttachment } : { ...prev }
      }, {})

      const pds = planDefinitions.reduce<Record<string, Coding[]>>((result, pd) => {
        return { ...result, [`${pd?.url}|${pd?.version}`]: convertIdentifiersToCodings([pd]) }
      }, {})

      const srs = serviceRequests.reduce<Record<string, ServiceRequest>>((acc, cur) => {
        return { ...acc, [cur.id as string]: cur }
      }, {})

      const panels = serviceRequests.reduce<Record<string, ServiceRequest>>(
        (acc, sr) => ({ ...acc, [sr.id as string]: sr }),
        {},
      )

      const codes: { billToPracticeOrInsuranceCIDs: Coding[]; billToPatientCIDs: Coding[] } = serviceRequests.reduce(
        (acc: { billToPracticeOrInsuranceCIDs: Coding[]; billToPatientCIDs: Coding[] }, cur) => {
          const serviceRequest = srs?.[cur.id as string]

          const srPanels =
            serviceRequest.basedOn
              ?.filter((basedOn) => basedOn.resourceType === "ServiceRequest")
              .reduce((acc, sr) => {
                const newSr = srs[sr.id as string]

                return [...acc, ...(newSr ? [newSr] : [])]
              }, Array<ServiceRequest>()) ?? []

          const codes = srPanels.reduce(
            (acc, curr) => [...acc, ...(pds[curr.instantiatesCanonical?.[0] as string] ?? [])],
            [] as Coding[],
          )

          const billingType = getServiceRequestBillingType(serviceRequest)
          return billingType === BillingTypeCodes.BILL_PATIENT
            ? {
                billToPracticeOrInsuranceCIDs: acc.billToPracticeOrInsuranceCIDs,
                billToPatientCIDs: [...acc.billToPatientCIDs, ...codes],
              }
            : {
                billToPracticeOrInsuranceCIDs: [...acc.billToPracticeOrInsuranceCIDs, ...codes],
                billToPatientCIDs: acc.billToPatientCIDs,
              }
        },
        { billToPracticeOrInsuranceCIDs: [] as Coding[], billToPatientCIDs: [] as Coding[] },
      )

      const { billToPracticeOrInsuranceCIDs, billToPatientCIDs } = await getChargeItemDefinitions(organizationId, codes)

      const defaultLaboratoryPanels = {
        laboratoryPanels: new Array<LaboratoryOrderPanel>(),
        totalPrice: { currency: "USD", value: 0 },
      }

      const indexedTasks = tasks.reduce<Record<string, Task>>(
        (acc, cur) => ({ ...acc, [cur.focus?.id as string]: cur }),
        {},
      )

      const indexedPractitioners = getResourcesByTypeAsIndex<Practitioner>(bundle, "Practitioner")

      const requesters = practRoles.reduce<Record<string, string>>((requesters, pr) => {
        if (pr.id && pr.practitioner?.id) {
          const pract = indexedPractitioners[pr.practitioner.id]

          if (pract) {
            return { ...requesters, [pr.id]: humanNameAsString(pract?.name?.[0]) }
          }
        }
        return requesters
      }, {})

      const getPanelData = (id: string, billingType: BillingTypeCodes) => {
        const panelInstantiateCanonical = panels[id]?.instantiatesCanonical?.[0] ?? ""
        const [pdUrl, pdVersion] = panelInstantiateCanonical.split("|")
        const planDefinition = planDefinitions.find((pd) => pd.url === pdUrl && pd.version === pdVersion)

        const codes = convertIdentifiersToCodings(planDefinition ? [planDefinition] : [])
        const cid =
          billingType === BillingTypeCodes.BILL_PATIENT
            ? billToPatientCIDs[getCidIdentifier(getCommonCode(codes))]
            : billToPracticeOrInsuranceCIDs[getCidIdentifier(getCommonCode(codes))]

        const cidPrice = getBasePrice(cid?.propertyGroup?.[0].priceComponent)?.amount

        return {
          panel: {
            profile: panels[id],
            price: cidPrice,
          },
        }
      }

      const labOrders = laboratoryRequests.reduce((prev, labOrder) => {
        const orderComboId = labOrder.basedOn?.flatMap(({ id }) =>
          id && panels[id]?.category?.[0].coding?.[0].code === ServiceRequestCategory.LAB_ORDER_COMBO ? id : [],
        )?.[0]
        let totalPrice: Money = { currency: undefined, value: 0 }
        let orderCombo: LaboratoryOrderCombo | undefined = undefined

        const billingType = getServiceRequestBillingType(labOrder)

        if (orderComboId) {
          const comboData = getPanelData(orderComboId, billingType)
          const comboPanels =
            comboData.panel.profile.basedOn?.reduce((acc, bo) => {
              const panel =
                bo.resourceType === "ServiceRequest" ? getPanelData(bo.id as string, billingType).panel : undefined
              return panel ? [...acc, panel] : [...acc]
            }, Array<LaboratoryOrderPanel>()) ?? Array<LaboratoryOrderPanel>()

          orderCombo = {
            laboratoryCombo: comboData.panel.profile,
            panels: comboPanels,
          }

          totalPrice = { ...comboData.panel.price }
        }

        // Get extra panels from the order basedOn
        const orderPanelsId = labOrder.basedOn
          ?.filter(({ id }) => !orderCombo?.panels.some(({ profile }) => profile?.id === id))
          .flatMap(({ id }) =>
            id && panels[id]?.category?.[0].coding?.[0].code === ServiceRequestCategory.LAB_ORDER_PANEL ? id : [],
          )

        const { laboratoryPanels, totalPrice: panelsPrice } =
          orderPanelsId?.reduce((prevPanels, id) => {
            const panelData = getPanelData(id, billingType)

            return {
              laboratoryPanels: [...prevPanels.laboratoryPanels, panelData.panel],
              totalPrice: {
                currency: panelData.panel.price?.currency ?? "USD",
                value: prevPanels.totalPrice.value + (panelData.panel.price?.value ?? 0),
              },
            }
          }, defaultLaboratoryPanels) ?? defaultLaboratoryPanels

        totalPrice.value = (totalPrice.value ?? 0) + panelsPrice.value
        totalPrice.currency = totalPrice.currency ?? panelsPrice.currency

        const orderDr = diagnosticReports.filter((dr) => dr.basedOn?.find((basedOn) => basedOn.id === labOrder.id))[0]
        const presentedForm = orderDr?.presentedForm?.[0].url ?? ""
        const orderRequisition = documentsByOrder[labOrder.id as string]

        const planBased = labOrder?.basedOn?.some((r) => r.resourceType === "CarePlan") ?? false
        const revocableTaskStatus = ["draft", "on-hold", "failed"]
        const taskOrderLabs = indexedTasks[labOrder.id as string]
        const revocable = !planBased && (!taskOrderLabs || revocableTaskStatus.includes(taskOrderLabs.status))

        return [
          ...prev,
          {
            order: labOrder,
            panels: laboratoryPanels,
            panelsCount: laboratoryPanels?.length ?? 0,
            billingType: billingType,
            deletedPanels: [],
            price: totalPrice,
            requester: requesters[labOrder.requester?.id ?? ""] ?? "unspecified",
            bloodDrawnInOffice: !!labOrder.specimen,
            specimenDate: specimens?.[0]?.receivedTime,
            presentedForm,
            revocable,
            combo: orderCombo,
            requisition: orderRequisition,
            latestResult: orderDr,
          },
        ]
      }, [] as LaboratoryOrder[])

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return { labOrders: labOrders ?? [], next, total: bundle?.total ?? 0 }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, patientId } },
  })

  const { laboratoryOrders, count } = useMemo(() => {
    const newData = data?.pages.flatMap((page) => page.labOrders)
    const count = newData?.length

    const result = newData?.reduce<Map<string, LaboratoryOrder[]>>((acc, cur) => {
      const updatedLabData = { ...cur }

      const date = updatedLabData.order?.authoredOn
        ? formatDate(parseISO(updatedLabData.order?.authoredOn), formatsByTypes.LONG_DATE)
        : "unspecified"

      const group = acc.get(date)

      if (group) {
        group.push(updatedLabData)
      } else {
        acc.set(date, [updatedLabData])
      }

      return acc
    }, new Map())

    return {
      laboratoryOrders: result,
      count,
    }
  }, [data?.pages])

  if (isError) {
    throw error
  }

  return {
    laboratoryOrders: laboratoryOrders
      ? Array.from(laboratoryOrders.entries()).map(([date, items]) => ({
          key: date,
          name: date,
          items,
        }))
      : [],
    isLoading,
    count: count ?? 0,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  }
}

type LaboratoryOrderQueryData = { labOrders: LaboratoryOrder[]; next: number | undefined; total: number }

export { useLaboratoryOrders }
