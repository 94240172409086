import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faExternalLink } from "@fortawesome/pro-light-svg-icons"
import { FC } from "react"

import { CustomError } from "commons"

import { ERROR_CAUSE } from "../types"
import { getContactToSupportURL, getCustomErrorMessage } from "../utils"

const InternalServerErrorView: FC<Props> = ({ error }) => {
  const message = error.cause?.message ?? ERROR_CAUSE.INTERNAL_SERVER_ERROR
  const code = error.cause?.name ?? "500"

  return (
    <div className="flex items-center justify-center w-full h-full">
      <div className="text-center">
        <p className="p-error text-5xl">{code}</p>
        <h1 className="text-4xl py-2 font-bold">{getCustomErrorMessage(error)}</h1>
        <p className="text-gray-400 pt-2 pb-4">{message}</p>
        <div className="mt-3 mb-5">
          <a className="ml-2 underline" href={getContactToSupportURL(error?.cause?.traceId ?? "")}>
            <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
            Feel free to contact support
          </a>
        </div>
        <a href="/" className="p-button button-primary">
          <span>
            Go to Home <FontAwesomeIcon icon={faExternalLink} className="pl-1" />
          </span>
        </a>
      </div>
    </div>
  )
}

type Props = { error: CustomError }

export { InternalServerErrorView }
