import { useSuspenseQuery } from "@tanstack/react-query"
import { Organization } from "fhir"

import { useClient } from "api"
import { registerErrorTrace } from "logger"
import { CustomError } from "commons"

import { organizationKeys } from "../query-keys"

const useOrganization = (organizationId: string) => {
  const { read } = useClient()
  const queryKey = organizationKeys.detail(organizationId)

  const { data, isLoading, isError, error } = useSuspenseQuery({
    queryKey,
    queryFn: async () => {
      const organization = await read<Organization>({ endpoint: "Organization", id: organizationId })

      const isActive = organization.active !== undefined ? organization.active : true

      if (!organization || !isActive) {
        throw registerErrorTrace(
          new Error("Not found", {
            cause: {
              name: "404",
              message: "Organization not found",
              logoutFlag: true,
            },
          }) as CustomError,
        )
      }

      return { organization }
    },
    meta: { context: { queryKey } },
  })

  if (isError) {
    throw error
  }

  return { organization: data?.organization as Organization, isLoading }
}

export { useOrganization }
