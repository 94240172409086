import { RequestGroup, RequestGroupActionArrayActionArray, ResourceObject } from "fhir"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { registerErrorTrace } from "logger"
import { displayNotificationError } from "errors"
import { CustomError } from "commons"

import { cpoeOrdersKeys } from "../query-keys"

const useUpdateRG = (onSuccess: () => void, onSettled?: () => void) => {
  const queryClient = useQueryClient()
  const { patch } = useClient()

  const { mutate: updateCpoeRG, isPending: isUpdatingRG } = useMutation({
    mutationFn: async (updatedRG: RequestGroup) =>
      await patch(
        "RequestGroup",
        updatedRG.id as string,
        {
          action: [...(updatedRG?.action as RequestGroupActionArrayActionArray[])],
          contained: [...(updatedRG.contained ?? [])],
          meta: updatedRG.meta,
        } as ResourceObject,
      ),
    onSettled: onSettled,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: cpoeOrdersKeys.all, exact: false }).finally(() => onSuccess())
    },
    onError: async (error: CustomError, rg) => {
      if (!!error.cause?.name && ["409", "412"].includes(error.cause.name)) {
        // Conflict error or precondition failed error are thrown when the resource has been modified by someone
        await queryClient.invalidateQueries({ queryKey: cpoeOrdersKeys.all, exact: false })
      }
      displayNotificationError(registerErrorTrace(error, { finish: "CPOE Update RG", rg }))
    },
  })
  return { updateCpoeRG, isUpdatingRG }
}

export { useUpdateRG }
