import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useDeferredValue, useId, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { faSearch } from "@fortawesome/pro-regular-svg-icons"

import { SkeletonLoader, SearchWithStatus } from "commons"
import { useLoginContext } from "security"

import { useMetabolicCodeReports } from "../hooks"
import { MetabolicCodeReportItem } from "./MetabolicCodeReportItem"

const MetabolicCodeReportsContainer = () => {
  const loaderKey = useId()
  const { loggedInPatientId } = useLoginContext()
  const [searchFilter, setSearchFilter] = useState("")
  const deferredFilter = useDeferredValue(searchFilter)

  const { reports, hasNextPage, isLoading, fetchNextPage } = useMetabolicCodeReports(loggedInPatientId, deferredFilter)

  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  return (
    <div className="module-container flex flex-col h-full relative overflow-auto p-3 lg:px-5 pb-0">
      <div className="flex flex-wrap justify-end md:inline-flex md:h-12 w-full mb-3">
        <SearchWithStatus
          placeholder="Search reports"
          onSearch={(filter) => {
            setSearchFilter(filter ?? "")
          }}
          className="p-fluid w-full md:w-2/3 lg:1/3 xl:max-w-max justify-end"
        />
      </div>
      {isLoading ? (
        loader()
      ) : (
        <>
          {!reports?.length ? (
            <div className="flex flex-col items-center justify-center h-full">
              <FontAwesomeIcon icon={faSearch} size="3x" className="text-slate-400" />
              <p className="text-md text-slate-400 pt-4 pb-2 place-self-center">No reports found</p>
            </div>
          ) : (
            <div className="bg-white h-full overflow-auto">
              <InfiniteScroll
                hasMore={hasNextPage}
                loadMore={() => fetchNextPage()}
                useWindow={false}
                loader={loader()}
              >
                {reports.map((report) => (
                  <MetabolicCodeReportItem key={report.id} report={report} />
                ))}
              </InfiniteScroll>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export { MetabolicCodeReportsContainer }
