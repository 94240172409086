import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLocationDot } from "@fortawesome/pro-regular-svg-icons"
import { AccountBETACreditCardArray, addressStringify } from "fhir"
import { useMemo, useState } from "react"
import { useFormikContext } from "formik"
import { getAddressByType } from "fhir/utils"
import { Checkbox } from "primereact/checkbox"

import { InputField, AddressField } from "commons"
import { formatCreditCardNumber } from "utils"
import { useLoginContext } from "security"
import { emptyAddress } from "data"

import { CreditcardNumberField } from "./CreditcardNumberField"
import { ExpirationDateField } from "./ExpirationDateField"

const CreditCardForm = ({ isEditing }: Props) => {
  const {
    loggedInPatient: { address },
  } = useLoginContext()
  const shippingAddress = getAddressByType(address, "postal")
  const { values, setValues, isSubmitting } = useFormikContext<AccountBETACreditCardArray>()

  const isSameAddress = useMemo(
    () => (shippingAddress ? JSON.stringify(values["billingAddress"]) === JSON.stringify(shippingAddress) : false),
    [shippingAddress, values],
  )

  const [useShipping, setUseShipping] = useState(isSameAddress)

  const onChange = (value: boolean) => {
    if (shippingAddress) {
      setValues((prevValues) => ({
        ...prevValues,
        billingAddress: value ? shippingAddress : isEditing ? prevValues["billingAddress"] : emptyAddress,
      }))
    }

    setUseShipping(value)
  }

  return (
    <div className="flex flex-col gap-4">
      <fieldset className="flex flex-col p-fluid gap-4">
        <legend className="mb-2">Basic information</legend>
        {isEditing ? (
          <div className="flex gap-4">
            <div className="flex-1">
              <div className="text-sm mb-1">Number</div>
              <div className="border border-slate-200 rounded-md mb-2 text-gray-400 text-sm p-3 h-10 truncate block cursor-default select-none">
                {formatCreditCardNumber(values.last4Digits as string, values.type)}
              </div>
            </div>
            <div className="w-1/3">
              <div className="text-sm mb-1">CVV</div>
              <div className="border border-slate-200 rounded-md mb-2 text-gray-400 text-sm p-3 h-10 truncate block cursor-default select-none">
                ****
              </div>
            </div>
          </div>
        ) : (
          <CreditcardNumberField field="number" label="Number" disabled={isSubmitting} />
        )}
        {isEditing && <ExpirationDateField field="expirationMonth" label="Expiration date" disabled />}
        <InputField field="cardHolderName" label="Cardholder name" disabled={isEditing} />
      </fieldset>
      <fieldset className="flex flex-col p-fluid gap-4">
        <legend className="mb-2">Billing Address</legend>
        {shippingAddress && (
          <div className="w-full">
            <div className="flex items-center text-sm gap-1 relative">
              <Checkbox
                inputId="useshipping"
                onChange={(e) => onChange(e.checked ?? false)}
                checked={useShipping}
                value="Use shipping address"
              />
              <label
                htmlFor="useshipping"
                className="text-gray-500 text-sm cursor-pointer"
                title="Use shipping address"
              >
                <FontAwesomeIcon icon={faLocationDot} className="mr-1" />
                {addressStringify(shippingAddress)}
              </label>
            </div>
          </div>
        )}
        {!useShipping && <AddressField parentFieldName="billingAddress" disabled={isSubmitting} context="CreditCard" />}
      </fieldset>
    </div>
  )
}

type Props = {
  isEditing: boolean
}

export { CreditCardForm }
