import { useQuery } from "@tanstack/react-query"

import { useClient } from "api"

import { invoicesKeys } from "../query-keys"

const useCountInvoices = (patientId: string, statusFilter: string[] = []) => {
  const { search } = useClient()

  const queryKey = invoicesKeys.withStatusFilter(patientId, statusFilter)

  const { data, isLoading, isError, error, refetch } = useQuery({
    queryKey,
    queryFn: async () => {
      const filters = new URLSearchParams({
        ...(statusFilter.length > 0 ? { status: statusFilter.join(",") } : {}),
        recipient: patientId,
        _count: "0",
        _total: "count",
      })
      const bundle = await search({ endpoint: `Patient/${patientId}/Invoice`, filters })

      return { issuedInvoicesCount: bundle.total }
    },
    meta: { context: { queryKey, patientId } },
  })

  const reloadInvoicesCount = () => {
    refetch()
  }

  if (isError) {
    throw error
  }

  return {
    issuedInvoicesCount: data?.issuedInvoicesCount ?? 0,
    isLoading,
    reloadInvoicesCount,
  }
}

export { useCountInvoices }
