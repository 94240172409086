import {
  Appointment,
  CarePlan,
  Money,
  PlanDefinition,
  Reference,
  ServiceRequest,
  TaskRestriction,
  CodeableConcept,
  Composition,
  CarePlanActivityArray,
  Coding,
  PlanDefinitionActionArrayActionArray,
  MedicationKnowledge,
} from "fhir"

import { QuestionnaireData } from "surveys/types"

export type PlanData = {
  carePlan: CarePlan
  definitionToken: string
  planDefinition: PlanDefinition
  billingType?: string
  order?: ServiceRequest
  panels?: string[]
  combo?: string
  mailTasks?: Record<string, MailTaskData>
  appointment?: Appointment
  icd10?: CodeableConcept
  extraPlanDefinition?: PlanDefinition[]
  performer?: Reference
  mcActivity?: CarePlanActivityArray
  bloodDrawnInOffice?: boolean
  specimenDate?: string
  questionnairesCanonicals?: string[]
  questionnairesAnswered?: QuestionnaireData[]
  combos: ComboDefinition[]
  requiredPanels?: PanelDefinition[]
  performers?: Reference[]
  daysBeforeToMail?: number
  algorithmActivity?: CarePlanActivityArray[]
  configureActions: Record<string, PlanDefinitionActionArrayActionArray>
}

export type MailTaskData = {
  taskId: string
  restriction?: TaskRestriction
  label: string
  activityIndex: number
  enabled: boolean
  isRequired: boolean
}

export type Plan = {
  definitionToken: string
  definition: PlanDefinition
  combos: ComboDefinition[]
  panels: PanelDefinition[]
  performer?: Reference[]
  daysBeforeToMail?: number
  catalogs?: Record<string, Composition>
  algorithmActivity?: CarePlanActivityArray[]
  configureActions: Record<string, PlanDefinitionActionArrayActionArray>
  nutraceutical?: MedicationKnowledge[]
  rx?: MedicationKnowledge[]
  nutraCodes?: string[]
  rxCodes?: string[]
}

export type ComboDefinition = {
  canonical: string
  definition: PlanDefinition
  canonicalPanels: string[]
  panels: PlanDefinition[]
  price?: Money
  performer: Reference
  promoCoding?: Coding
}

export type PanelDefinition = {
  definition: PlanDefinition
  performer: Reference
}

export type CarePlanHistory = {
  carePlan: CarePlan
  appointment?: Appointment
}

export enum AppointmentAction {
  None,
  Complete = 1,
  Enroll = 2,
  CompleteAndEnroll = 3,
}

export type PanelItemDisplay = {
  isCombo?: boolean
  price?: Money
  display: string
  info?: string
  planDefinition: PlanDefinition
  panels?: PlanDefinition[]
}

export enum PLAN_SUBVIEWS {
  DETAILS = "plan-details",
  CONFIG = "plan-configuration",
  ENROLL = "plan-enrollment",
}

export enum PlanContext {
  MC = "mc",
  PLANS = "plans",
  MC_ORDERS = "mc-orders",
  MC_ASSESSMENTS = "mc-assessments",
}

export enum PLAN_ACTION_CODES {
  CONFIGURE_LABS = "request-lab-order",
  CONFIGURE_ALGORITHM = "request-algorithm",
  CONFIGURE_QUESTIONNAIRES = "send-questionnaire",
}
