import InfiniteScroll from "react-infinite-scroller"
import { useId, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/pro-light-svg-icons"
import { SelectButton } from "primereact/selectbutton"
import { useNavigate } from "react-router-dom"

import { useLoginContext } from "security"
import { SkeletonLoader } from "commons"

import { useQuestionnaireResponse } from "../hooks"
import { PatientQRListItem } from "./PatientQRListItem"

const statusOptions = [
  { name: "In progress", value: "in-progress" },
  { name: "Completed", value: "completed" },
]

const PatientSurveys = () => {
  const loaderKey = useId()
  const { loggedInPatientId } = useLoginContext()
  const navigate = useNavigate()

  const [selectedStatus, setSelectedStatus] = useState("in-progress")

  const { questionnaireData, inProgress, completed, isLoading, hasNextPage, fetchNextPage } =
    useQuestionnaireResponse(loggedInPatientId)

  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  return (
    <div className="flex flex-col overflow-auto h-full relative p-5 pb-0 bg-white">
      <SelectButton
        className="flex p-2 h-12 mb-1 wide-buttons"
        value={selectedStatus}
        options={statusOptions}
        optionLabel="name"
        optionValue="value"
        unselectable={false}
        onChange={(e) => setSelectedStatus(e.value)}
      />
      {isLoading ? (
        loader()
      ) : (
        <>
          {!questionnaireData?.length ||
          (inProgress === 0 && selectedStatus === "in-progress") ||
          (completed === 0 && selectedStatus === "completed") ? (
            <div className="flex flex-col h-full items-center justify-center">
              <FontAwesomeIcon icon={faSearch} size="3x" className="text-slate-400" />
              <p className="text-md text-slate-400 pt-4 place-self-center">No questionnaires found</p>
            </div>
          ) : (
            <InfiniteScroll
              hasMore={hasNextPage}
              loadMore={() => fetchNextPage()}
              useWindow={false}
              loader={loader()}
              className="bg-white h-full overflow-y-auto overflow-x-hidden p-0"
            >
              {questionnaireData.map(({ questionnaire, qResponses }) =>
                qResponses?.map(
                  (qr) =>
                    qr.status === selectedStatus && (
                      <PatientQRListItem
                        key={qr.id}
                        qResponse={qr}
                        questionnaireTitle={questionnaire.title as string}
                        onClick={() => navigate(`${qr.id}`)}
                      />
                    ),
                ),
              )}
            </InfiniteScroll>
          )}
        </>
      )}
    </div>
  )
}

export { PatientSurveys }
