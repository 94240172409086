import { Dialog } from "primereact/dialog"

import { Button } from "./Buttons"

const ConfirmDialog = ({
  onConfirm,
  hideDialog,
  visible,
  actionName = "Accept",
  confirmText = "Are you sure you want to do this?",
  isLoading,
}: Props) => {
  const renderModalFooter = () => (
    <div className="flex justify-end mt-2">
      <Button
        label="Cancel"
        buttonStyle="default"
        size="xl"
        className="mr-3"
        onClick={hideDialog}
        disabled={isLoading}
      />
      <Button label={actionName} loading={isLoading} size="xl" className="m-0" onClick={onConfirm} />
    </div>
  )

  return (
    <Dialog
      header="Confirmation"
      closable={true}
      draggable={false}
      visible={visible}
      className="sm:w-4/5 md:w-2/3 lg:w-[36rem]"
      onHide={hideDialog}
      footer={renderModalFooter}
    >
      <span>{confirmText}</span>
    </Dialog>
  )
}

type Props = {
  visible?: boolean
  confirmText?: string
  actionName?: string
  isLoading?: boolean
  onConfirm(): void
  hideDialog(): void
}

export { ConfirmDialog }
