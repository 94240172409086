import { useInfiniteQuery } from "@tanstack/react-query"
import { CarePlan, DiagnosticReport, getResources, isCarePlan } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"

import { mcKeys } from "../query-keys"

const useMetabolicCodeReports = (patientId: string, searchText?: string) => {
  const { search } = useClient()
  const queryKey = mcKeys.withSearchFilter(patientId, searchText)

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery<
    MCReportsQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        ...(searchText ? { _ilike: searchText } : {}),
        _category: "mc",
        "issued:missing": "false",
        _count: "20",
        _page: `${pageParam}`,
        _sort: "-issued",
        _include: "based-on:CarePlan",
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/DiagnosticReport`, filters, signal })

      const diagnosticReports = getResources<DiagnosticReport>(bundle, "DiagnosticReport")
      const carePlans = getResources<CarePlan>(bundle, "CarePlan")
      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return { diagnosticReports, carePlans, next, total: bundle?.total ?? 0 }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, patientId } },
  })

  const { reports, count } = useMemo(() => {
    const diagnosticReports = data?.pages.flatMap((page) => page.diagnosticReports)
    const carePlans = data?.pages.flatMap((page) => page.carePlans)

    const reportsWithPlan =
      carePlans?.map((carePlan) => {
        const relatedDR = diagnosticReports?.find(({ basedOn }) =>
          basedOn?.some((item) => isCarePlan(item) && item.id === carePlan.id),
        ) as DiagnosticReport

        return { ...relatedDR, carePlan }
      }) ?? []

    return {
      reports: reportsWithPlan,
      count: reportsWithPlan?.length ?? 0,
    }
  }, [data?.pages])

  return {
    reports,
    isLoading,
    count,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  }
}

type MCReportsQueryData = {
  carePlans: CarePlan[]
  diagnosticReports: DiagnosticReport[]
  next: number | undefined
  total: number
}

export { useMetabolicCodeReports }
