import { useRef, useState, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCropAlt } from "@fortawesome/pro-regular-svg-icons"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { FileUpload } from "primereact/fileupload"
import { Crop, Component as ReactCrop } from "react-image-crop"
import "react-image-crop/dist/ReactCrop.css"

import defaultAvatar from "images/default-user.png"
import "./AvatarCrop.css"

const AvatarCrop = ({ visible, onHide, onSave }: Props) => {
  const fileInput = useRef<FileUpload>(null)

  // We keep the internal file state so only this components knows what is been cropped
  const [file, setFile] = useState<string>()
  const [crop, setCrop] = useState<Crop>()
  const [image, setImage] = useState<HTMLImageElement>()

  // When the user saves a image we call the onSave function and hide the modal
  const onSaveFile = (file: { file: Blob; fileUrl: string }) => {
    onSave(file)
    onHide()
  }
  useEffect(() => {
    setFile(undefined)
    setCrop(undefined)
    setImage(undefined)
  }, [visible])

  useEffect(() => {
    const size = Math.min(image?.width ?? 100, image?.height ?? 100)
    setCrop({ unit: "px", x: 0, y: 0, width: size, height: size })
  }, [image, file])

  const cropImage = (save = false) => {
    if (image && crop) {
      const canvas = document.createElement("canvas")
      const scaleX = image.naturalWidth / image.width
      const scaleY = image.naturalHeight / image.height
      canvas.width = canvas.height = save ? Math.min(crop.width, 192) : crop.width
      const canvasContext = canvas.getContext("2d")
      canvasContext?.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        canvas.width,
        canvas.height,
      )
      if (save) {
        // We convert the canvas to a blob so we can create the File to been uploaded in BasicInformation.tsx
        // And we create a tempUrl to access that file by Url.createObjectURL
        canvas.toBlob((blob) => {
          blob && onSaveFile({ file: blob, fileUrl: URL.createObjectURL(blob) })
        })
      } else {
        setFile(canvas.toDataURL())
      }
    }
  }

  const modalFooter = (
    <div className="flex flex-col lg:flex-row lg:justify-end gap-3 mt-2">
      <Button label="Cancel" className="button-default order-3 lg:order-none" onClick={onHide} />
      <Button
        label="Crop"
        className="button-primary"
        icon={<FontAwesomeIcon className="mr-1" icon={faCropAlt} />}
        disabled={!crop || crop.width === 0}
        onClick={() => cropImage()}
      />
      <Button
        label="Crop & Save"
        className="button-primary"
        disabled={!crop || crop.width === 0}
        onClick={() => cropImage(true)}
      />
    </div>
  )

  return (
    <Dialog
      id="avatarCrop"
      visible={visible}
      onHide={onHide}
      draggable={false}
      className="w-full h-full md:w-3/4 xl:w-1/2 2xl:w-1/3 md:h-3/4"
      header="Update avatar photo"
      footer={modalFooter}
    >
      <div className="flex flex-col justify-center items-center h-full m-auto avatar-crop-container">
        {file ? (
          <>
            <ReactCrop crop={crop} onChange={(c) => setCrop(c)} aspect={1} circularCrop>
              <img src={file} onLoad={(e) => setImage(e.currentTarget)} alt="" />
            </ReactCrop>
          </>
        ) : (
          <img src={defaultAvatar} className="rounded-full opacity-50 h-1/2" alt="" />
        )}
        <FileUpload
          ref={fileInput}
          className="mt-8 text-xs"
          mode="basic"
          chooseOptions={{ label: "Select a picture", icon: "pi pi-camera" }}
          accept="image/*"
          customUpload
          onSelect={(e) => {
            setCrop(undefined)
            setImage(undefined)
            setFile(URL.createObjectURL(e.files[0]))
            fileInput.current?.clear()
          }}
        />
      </div>
    </Dialog>
  )
}

type Props = {
  visible: boolean
  onHide(): void
  onSave({ file, fileUrl }: { file: Blob; fileUrl: string }): void
}

export { AvatarCrop }
