import { Account, ResourceObject } from "fhir"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { useLoginContext } from "security"
import { CustomError } from "commons"

import { creditCardsKeys, accountKeys } from "../query-keys"

const useUpdateDefaultCreditCard = (hideForm?: () => void) => {
  const { loggedInPatientId } = useLoginContext()
  const { patch } = useClient()
  const queryClient = useQueryClient()

  const updatedAccount = async ({ creditCardId, account }: { creditCardId: string; account: Account }) => {
    return patch(
      "Account",
      account.id as string,
      {
        defaultCreditCard: creditCardId,
        meta: account.meta,
      } as ResourceObject,
    )
  }
  const { mutate: updateDefaultCreditCard, isPending: isUpdating } = useMutation({
    mutationFn: updatedAccount,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: accountKeys.withPatientId(loggedInPatientId) })
      await queryClient.invalidateQueries({ queryKey: creditCardsKeys.withPatientId(loggedInPatientId) })
    },
    onSettled: hideForm,
    onError: async (error: CustomError, context) => {
      if (!!error.cause?.name && ["409", "412"].includes(error.cause.name)) {
        // Conflict error or precondition failed error are thrown when the resource has been modified by someone
        await queryClient.invalidateQueries({ queryKey: accountKeys.withPatientId(loggedInPatientId) })
        await queryClient.invalidateQueries({ queryKey: creditCardsKeys.withPatientId(loggedInPatientId) })
      }
      displayNotificationError(registerErrorTrace(error, context))
    },
  })

  return { updateDefaultCreditCard, isUpdating }
}

export { useUpdateDefaultCreditCard }
