import { FC, ReactNode, useState, useRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { faImageSlash } from "@fortawesome/pro-regular-svg-icons"
import { Image } from "primereact/image"
import { classNames } from "primereact/utils"
import { ProgressSpinner } from "primereact/progressspinner"

import "./AzureImage.css"

const AzureImage: FC<Props> = ({
  url,
  alt,
  className,
  imageClassName,
  loadingClassName,
  fallbackIconNode,
  fallbackIcon = faImageSlash,
  fallbackIconClass,
  showPreview,
}) => {
  const [showFallback, setShowFallback] = useState(false)
  const [isDownloaded, setIsDownloaded] = useState(false)
  const imgRef = useRef<HTMLImageElement>(null)

  const imageLoaded = () => {
    setIsDownloaded(true)
    imgRef.current?.classList.add("loaded")
  }

  return showPreview ? (
    <Image src={url} alt={alt} className={className} preview={showPreview} imageClassName={imageClassName} />
  ) : (
    <div className={classNames("img-container flex flex-col h-full", { relative: !isDownloaded })}>
      {!isDownloaded && (
        <ProgressSpinner
          animationDuration="1s"
          className={classNames("absolute w-full", loadingClassName ?? imageClassName ?? className)}
        />
      )}
      <div
        className={classNames({
          hidden: !showFallback,
        })}
      >
        {fallbackIconNode ?? <FontAwesomeIcon icon={fallbackIcon} className={fallbackIconClass} />}
      </div>
      <img
        ref={imgRef}
        src={url}
        loading="lazy"
        alt={alt}
        onLoad={imageLoaded}
        onError={() => {
          setShowFallback(true)
          imgRef.current?.classList.remove("loaded")
        }}
        className={classNames({ hidden: isDownloaded && showFallback }, imageClassName)}
      />
    </div>
  )
}

export type AzureImageReloader = {
  reloadImage(): void
}

type Props = {
  url: string
  alt?: string
  className?: string
  imageClassName?: string
  loadingClassName?: string
  fallbackIconNode?: ReactNode
  fallbackIcon?: IconDefinition
  fallbackIconClass?: string
  showPreview?: boolean
}

export { AzureImage }
