import { useContext } from "react"

import { SmartyAddressVerificationContext } from "../context"

export const useSmartyAddressVerificationContext = () => {
  const context = useContext(SmartyAddressVerificationContext)
  if (!context) {
    throw new Error("useSmartyAddressVerificationContext must be used within a SmartyAddressVerificationProvider")
  }
  return context
}
