import { Account, AccountBETACreditCardArray, ResourceObject } from "fhir"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { displayNotificationSuccess } from "utils"
import { useLoginContext } from "security"
import { registerErrorTrace } from "logger"
import { CustomError } from "commons"

import { creditCardsKeys, accountKeys } from "../query-keys"

const useUpdateCreditCard = (onSettled: () => void) => {
  const { loggedInPatientId } = useLoginContext()
  const { patch } = useClient()
  const queryClient = useQueryClient()

  const updatedCreditCard = async ({
    creditCard,
    creditCardList,
    index,
    account,
  }: {
    creditCard: AccountBETACreditCardArray
    account?: Account
    creditCardList: AccountBETACreditCardArray[]
    index: number
  }) => {
    return patch(
      "Account",
      account?.id as string,
      {
        creditCard: [...creditCardList.slice(0, index), creditCard, ...creditCardList.slice(index + 1)],
        meta: account?.meta,
      } as ResourceObject,
    )
  }

  const { mutate: updateCreditCard, isPending: isUpdating } = useMutation({
    mutationFn: updatedCreditCard,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: accountKeys.withPatientId(loggedInPatientId) })
      await queryClient.invalidateQueries({ queryKey: creditCardsKeys.withPatientId(loggedInPatientId) })

      displayNotificationSuccess("Credit card updated successfully!")
    },
    onSettled,
    onError: async (error: CustomError, context) => {
      if (!!error.cause?.name && ["409", "412"].includes(error.cause.name)) {
        // Conflict error or precondition failed error are thrown when the resource has been modified by someone
        await queryClient.invalidateQueries({ queryKey: accountKeys.withPatientId(loggedInPatientId) })
        await queryClient.invalidateQueries({ queryKey: creditCardsKeys.withPatientId(loggedInPatientId) })
      }
      displayNotificationError(registerErrorTrace(error, context))
    },
  })

  return { updateCreditCard, isUpdating }
}

export { useUpdateCreditCard }
