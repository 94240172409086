import { Parameters } from "fhir"
import { Fragment } from "react"
import pluralize from "pluralize"

import { strCapitalize } from "utils"

import { CustomInvoiceData } from "../types"
import { getSummaryParameter } from "../utils"

const OrderSummary = ({ invoiceData, summary, currency }: Props) => (
  <div className="py-3 text-sm">
    <div className="grid grid-cols-3 w-full gap-3 font-bold p-3 text-base">
      <span className="col-span-2">Products</span>
      <div className="col-span-1 flex justify-between">
        <span>Qty</span>
        <span>Price</span>
      </div>
    </div>
    {Object.entries(invoiceData.items).map(([pType, items]) => (
      <div key={pType} className="flex flex-col space-y-2 border-b py-2 px-3">
        <span className="inline-block font-semibold">
          {pType === "medication" ? "Pharmaceuticals" : pluralize(strCapitalize(pType), 2)}
        </span>
        {items.map(({ description, price, qty, productId }, index) => (
          <Fragment key={`product_${index}`}>
            <div className="grid grid-cols-3 w-full gap-3 pl-2">
              <span title="Product" className="col-span-2">
                {description}
              </span>
              <div className="col-span-1 flex justify-between">
                <span title="Quantity" className="flex w-min">
                  x{qty ?? 1}
                </span>
                <span title="Price" className="flex justify-end">
                  {currency + price.toFixed(2)}
                </span>
              </div>
            </div>
            {pType === "procedure" &&
              invoiceData.productFees?.[productId as string]?.map(({ description, price }, index) => (
                <div key={`procedure_fee_${index}`} className="flex justify-between pl-4" title="Fee">
                  <span>{description}</span>
                  <span className="inline-block text-right">{currency + price.toFixed(2)}</span>
                </div>
              ))}
          </Fragment>
        ))}

        {invoiceData.shippingMethods[pType]?.map(({ description, price }, index) => (
          <div key={`shipping_${index}`} className="flex justify-between pl-2">
            <span>{description}</span>
            <span className="inline-block text-right">{currency + price.toFixed(2)}</span>
          </div>
        ))}
      </div>
    ))}
    <div className="border-b py-2 px-3 flex justify-between font-semibold mb-5">
      <span className="inline-block">Subtotal</span>
      <span className="inline-block text-right">
        {currency +
          (
            (getSummaryParameter(summary, "subtotal") as number) ??
            invoiceData.itemsSubtotal + invoiceData.productFeesSubtotal + invoiceData.shippingMethodSubtotal
          ).toFixed(2)}
      </span>
    </div>
    {!!invoiceData.discounts && !!invoiceData.discount && (
      <div className="px-3">
        <h4 className="font-bold my-3 text-base">Discounts</h4>
        {Object.entries(invoiceData.discounts).map(([pType, items], index) => {
          const summaryPrice = (getSummaryParameter(summary, "discounts") as Record<string, number>)[pType]
          return (
            !!summaryPrice && (
              <div key={`discount_${index}`} className="flex justify-between pl-2">
                <span>{pType === "medication" ? "Pharmaceuticals" : pluralize(strCapitalize(pType), 2)}</span>
                {items.map(({ price }, index) => (
                  <span key={`${pType}_${index}`} className="inline-block text-right">
                    {"-" + currency + ((summary ? summaryPrice : price) * -1).toFixed(2)}
                  </span>
                ))}
              </div>
            )
          )
        })}
      </div>
    )}
    <div className="flex flex-col pl-3">
      <div className="font-bold text-base mt-5 mb-2">Summary</div>
      <div className="mb-10 pl-2 pr-3 text-sm">
        <div className="border-b py-2 flex justify-between font-semibold">
          <span className="inline-block">Subtotal</span>
          <span className="inline-block text-right">
            {currency + (getSummaryParameter(summary, "subtotal") as number).toFixed(2)}
          </span>
        </div>

        <div className="border-b py-2 flex justify-between font-semibold">
          <span className="inline-block">Taxes</span>
          <span className="inline-block text-right">
            {currency + (getSummaryParameter(summary, "taxes") as number).toFixed(2)}
          </span>
        </div>
        {!!getSummaryParameter(summary, "discounts-total") && (
          <div className="border-b py-2 flex flex-col space-y-1">
            <div className="flex justify-between font-semibold">
              <span className="inline-block">Discounts total</span>
              <span className="inline-block text-right">{`-${
                currency + ((getSummaryParameter(summary, "discounts-total") as number) * -1).toFixed(2)
              }`}</span>
            </div>
          </div>
        )}
        <div className="py-2 font-bold flex justify-between">
          <span className="inline-block">Total</span>
          <span className="inline-block text-right">
            {currency + (getSummaryParameter(summary, "total") as number).toFixed(2)}
          </span>
        </div>
      </div>
    </div>
  </div>
)

type Props = {
  invoiceData: CustomInvoiceData
  summary: Parameters
  currency: string
}

export { OrderSummary }
