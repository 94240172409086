import { FC, PropsWithChildren, Suspense } from "react"
import { QueryCache, QueryClient, QueryClientProvider, QueryErrorResetBoundary } from "@tanstack/react-query"
import { ToastContainer } from "react-toastify"
import { ErrorBoundary } from "react-error-boundary"

import { OffLineModal, useCheckNetworkContext } from "check-network"
import { ErrorContainer, displayNotificationError } from "errors"
import { datadogLogs, registerErrorTrace } from "logger"
import { LoadingView, CustomError } from "commons"

const AppContainer: FC<PropsWithChildren> = ({ children }) => {
  const { isOnline, setIsOnline } = useCheckNetworkContext()

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        enabled: isOnline,
        retry: 1,
      },
    },
    queryCache: new QueryCache({
      onError: (error, query) => {
        if ((error as CustomError).cause?.name !== "499") {
          const context = query?.meta?.context

          if ((error as CustomError).cause?.name !== "403") {
            displayNotificationError(
              registerErrorTrace(error as CustomError, { ...(context ? { data: context } : {}) }),
            )
          }
        } else {
          setIsOnline(false)
        }
      },
    }),
  })
  return (
    <QueryClientProvider client={queryClient}>
      <QueryErrorResetBoundary>
        {({ reset }) => (
          <ErrorBoundary
            FallbackComponent={ErrorContainer}
            onReset={reset}
            onError={(error) => {
              datadogLogs.logger.error(error.message, error)
            }}
          >
            <Suspense fallback={<LoadingView />}>
              {children}
              {!isOnline && <OffLineModal reset={reset} />}
              <ToastContainer autoClose={10000} theme={"light"} position={"bottom-left"} />
            </Suspense>
          </ErrorBoundary>
        )}
      </QueryErrorResetBoundary>
    </QueryClientProvider>
  )
}

export default AppContainer
