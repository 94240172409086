import { humanNameAsString } from "fhir"
import { useEffect, useId, useRef, useState } from "react"
import { Link, useMatch, useNavigate, useResolvedPath } from "react-router-dom"
import { IconDefinition, IconProp } from "@fortawesome/fontawesome-svg-core"
import { Menu } from "primereact/menu"
import { Badge } from "primereact/badge"
import { Tooltip } from "primereact/tooltip"
import {
  faContactBook,
  faCreditCard,
  faHouseCrack,
  faInfoCircle,
  faLocationDot,
  faMemoCircleInfo,
  faSignOut,
  faSms,
  faFiles,
} from "@fortawesome/pro-light-svg-icons"
import { faAngleLeft, faAngleRight } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBell,
  faChartUser,
  faFileInvoiceDollar,
  faMessages,
  faPills,
  faSquarePollHorizontal,
  faStore,
  faVials,
  faHome,
  faBooksMedical,
} from "@fortawesome/pro-regular-svg-icons"
import { classNames } from "primereact/utils"
import { format } from "date-fns/format"
import { datadogRum } from "@datadog/browser-rum"

import { useUnreadedCommunications } from "communication"
import { useCPOEContext } from "orders"
import { useAuth, useLoggedUserPatients, useLoginContext, useUserHash } from "security"
import { useQuestionnaireResponse } from "surveys"
import { ProfileSection, formatsByTypes } from "data"
import { intercomInit, intercomTrackEvent, intercomUpdate } from "intercom"

import { useSidebarContext } from "../hooks"
import { AppIconLogo } from "./AppIconLogo"
import { AvatarImage } from "./AvatarImage"
import "./Sidebar.css"

const Sidebar = ({ className, collapsible, onLinkClicked }: Props) => {
  const { logout, user } = useAuth()
  const [userHmac, setUserHmac] = useState<string>()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const menu = useRef<any>(null)
  const navigate = useNavigate()

  const {
    isModuleActive,
    purchaseEnabled,
    medicationRequestsCount,
    issuedInvoicesCount,
    notificationCount,
    modulesSettings,
    branding: { ehrUrl },
  } = useSidebarContext()
  const { loggedInPatient, loggedInPatientId } = useLoginContext()
  const { ordersCount } = useCPOEContext()
  const { patientRefs } = useLoggedUserPatients()

  const { count: unreadComms } = useUnreadedCommunications(loggedInPatientId)
  const { inProgress } = useQuestionnaireResponse(loggedInPatientId)

  const [showCollapsed, setShowCollapsed] = useState(true && collapsible)
  const toogleCollapse = () => setShowCollapsed((collapsed) => !collapsed)

  const items = [
    {
      label: "Basic Information",
      icon: <FontAwesomeIcon icon={faInfoCircle} className="mr-3" fixedWidth />,
      command: () => navigate(`profile?section=${ProfileSection.BASIC}`),
    },
    {
      label: "Contact Information",
      icon: <FontAwesomeIcon icon={faContactBook} className="mr-3" fixedWidth />,
      command: () => navigate(`profile?section=${ProfileSection.CONTACT}`),
    },
    {
      label: "Address Information",
      icon: <FontAwesomeIcon icon={faLocationDot} className="mr-3" fixedWidth />,
      command: () => navigate(`profile?section=${ProfileSection.ADDRESS}`),
    },
    {
      label: "Credit Cards",
      icon: <FontAwesomeIcon icon={faCreditCard} className="mr-3" fixedWidth />,
      command: () => navigate(`profile?section=${ProfileSection.CC}`),
    },
    {
      label: "Insurance Information",
      icon: <FontAwesomeIcon icon={faHouseCrack} className="mr-3" fixedWidth />,
      command: () => navigate(`profile?section=${ProfileSection.INSURANCE}`),
    },
    {
      label: "Emergency Contacts",
      icon: <FontAwesomeIcon icon={faSms} className="mr-3" fixedWidth />,
      command: () => navigate(`profile?section=${ProfileSection.EMERGENCY_CONTACT}`),
    },
    {
      label: "Documents",
      icon: <FontAwesomeIcon icon={faFiles} className="mr-3" fixedWidth />,
      command: () => navigate(`profile?section=${ProfileSection.PATIENT_DOCUMENTS}`),
    },
    { separator: true },
    {
      label: "Policy & Terms",
      icon: <FontAwesomeIcon icon={faMemoCircleInfo} className="mr-3" fixedWidth />,
      command: () => window.open("https://www.chartedhealth.com/156-2/", "_blank", "noreferrer"),
    },
    { label: "Logout", icon: <FontAwesomeIcon icon={faSignOut} className="mr-3" fixedWidth />, command: logout },
  ]

  const { getIntercomHMac } = useUserHash((hmac) => {
    setUserHmac(hmac)
    if (user) {
      intercomInit(user.name, user.b2cUserId, user.email, hmac, datadogRum.getSessionReplayLink(), undefined, ehrUrl)
      intercomTrackEvent({
        email: user.email,
        event: "session-started",
        metadata: {
          session: {
            url: datadogRum.getSessionReplayLink() ?? "not available",
            value: format(new Date(), formatsByTypes.SHORT_DATETIME_WITH_TIMEZONE),
          },
        },
      })
    }
  })

  useEffect(() => {
    getIntercomHMac(user.b2cUserId)
  }, [])

  const showHomeLink = patientRefs.length > 1

  return (
    <div className={classNames("bg-white border-r-2 border-gray-200 relative shadow-sm min-w-max", className)}>
      {collapsible && (
        <div
          className="arrow-collapse absolute top-0.5 -right-5 flex items-center justify-center cursor-pointer border border-l-2 border-gray-200 w-5 h-7 transition-all duration-150 rounded-r-xl shadow-md z-10 bg-white"
          title={showCollapsed ? "Expand" : "Collapse"}
          onClick={toogleCollapse}
        >
          <span className={classNames("flex justify-center items-center", showCollapsed ? "is-right" : "is-left")}>
            <FontAwesomeIcon
              icon={showCollapsed ? faAngleRight : faAngleLeft}
              className={classNames(showCollapsed ? "text-lime-600/90" : "text-sky-600")}
            />
          </span>
        </div>
      )}
      <nav className="flex flex-col leading-6 h-full">
        <div className="flex mx-5 mt-2">
          <AppIconLogo showCollapsed={showCollapsed} />
        </div>

        <ul className={classNames("grow pt-5", showCollapsed ? "px-0" : "px-4")}>
          {showHomeLink && (
            <SidebarLink text="Home" icon={faHome} to="/" collapsed={showCollapsed} onLinkClicked={onLinkClicked} />
          )}
          <SidebarLink
            text="Dashboard"
            icon={faChartUser}
            to="dashboard"
            collapsed={showCollapsed}
            onLinkClicked={onLinkClicked}
          />
          {isModuleActive("survey") && (
            <SidebarLink
              text="Questionnaire"
              icon={faSquarePollHorizontal}
              to="questionnaires"
              qty={inProgress}
              collapsed={showCollapsed}
              onLinkClicked={onLinkClicked}
            />
          )}
          <SidebarLink
            text="Nutraceuticals"
            icon={faPills}
            to="nutraceuticals"
            qty={medicationRequestsCount}
            collapsed={showCollapsed}
            onLinkClicked={onLinkClicked}
          />
          {isModuleActive("labs") && (
            <SidebarLink
              text="Labs"
              icon={faVials}
              to="labs"
              collapsed={showCollapsed}
              showBadgeOnCollapsed
              onLinkClicked={onLinkClicked}
            />
          )}
          {isModuleActive("mc") && (
            <SidebarLink text="Metabolic Code" icon={faEhrMC} to="metabolic-code" collapsed={showCollapsed} />
          )}
          {isModuleActive("plans") && (
            <SidebarLink
              text="Treatment Plans"
              icon={faBooksMedical}
              to="plans"
              collapsed={showCollapsed}
              showBadgeOnCollapsed
              onLinkClicked={onLinkClicked}
            />
          )}
          {isModuleActive("communication") && (
            <SidebarLink
              text="Messages"
              icon={faMessages}
              to="messages"
              qty={unreadComms}
              collapsed={showCollapsed}
              showBadgeOnCollapsed
              onLinkClicked={onLinkClicked}
            />
          )}
          {purchaseEnabled && (
            <SidebarLink
              text="Shop"
              icon={faStore}
              to="shop"
              qty={ordersCount}
              collapsed={showCollapsed}
              showBadgeOnCollapsed
              onLinkClicked={onLinkClicked}
            />
          )}
          <SidebarLink
            text="Invoices"
            icon={faFileInvoiceDollar}
            to="invoices"
            qty={issuedInvoicesCount}
            collapsed={showCollapsed}
            showBadgeOnCollapsed
            onLinkClicked={onLinkClicked}
          />
          <SidebarLink
            text="Notifications"
            icon={faBell}
            to="notifications"
            collapsed={showCollapsed}
            qty={notificationCount}
            showBadgeOnCollapsed
            onLinkClicked={onLinkClicked}
          />
        </ul>

        <div
          className="flex items-center border-t border-gray-100 py-3 hover:bg-gray-50 cursor-pointer"
          onClick={(event) => menu?.current?.toggle(event)}
        >
          <div className="relative w-full">
            <div className={classNames("flex ease-in-out items-center", showCollapsed ? "justify-center" : "ml-3")}>
              <span className="sr-only">Open user menu</span>
              <AvatarImage
                photoUrl={loggedInPatient.photo?.[0]?.url}
                name={humanNameAsString(loggedInPatient.name?.[0])}
                className="h-8 w-8 rounded-full"
                onImageLoaded={
                  userHmac
                    ? (url) => {
                        intercomUpdate({
                          user_id: user.b2cUserId as string,
                          email: user.email,
                          avatar: { type: "avatar", image_url: url },
                          patient_id: loggedInPatientId,
                          name: humanNameAsString(loggedInPatient.name?.[0]),
                          gender: loggedInPatient.gender,
                          ...(modulesSettings
                            ? {
                                accessible_modules: Object.keys(modulesSettings)
                                  .map((key) => key.replace("-enabled", ""))
                                  .join(", "),
                              }
                            : {}),
                          user_role: "patient",
                          ...(loggedInPatient.managingOrganization
                            ? {
                                company: {
                                  company_id: loggedInPatient.managingOrganization.id as string,
                                  name: loggedInPatient.managingOrganization.display,
                                },
                              }
                            : {}),
                        })
                      }
                    : undefined
                }
              />
              {!showCollapsed && (
                <div className="pl-3 text-left">
                  <p className="text-sm">{user.name}</p>
                  <p className="text-xs text-gray-500">{user.email}</p>
                </div>
              )}
              <FontAwesomeIcon className="ml-2" icon={faAngleRight} />
            </div>

            <Menu className="ml-3 w-auto border-none" popup model={items} ref={menu} />
          </div>
        </div>
      </nav>
    </div>
  )
}

const SidebarLink = ({ text, to, icon, collapsed, qty = 0, showBadgeOnCollapsed, onLinkClicked }: LinkProps) => {
  const resolved = useResolvedPath(to)
  const match = useMatch({ path: resolved.pathname })

  const tooltipId = useId().replace(/:/g, "")

  return (
    <li
      id={tooltipId}
      className={classNames("sidebar-link rounded", {
        "bg-gray-100": match,
        "hover:bg-gray-50": !match,
        "flex justify-center w-full": collapsed,
      })}
      data-pr-tooltip={text}
    >
      <Link to={to} className="flex items-center text-slate-500 my-4" onClick={onLinkClicked}>
        <div className={classNames(collapsed ? "w-8 flex justify-center relative" : "flex-1 text-left")}>
          <span className="link-item items-center justify-center text-xl">
            <FontAwesomeIcon icon={icon} className="fa-fw" />
          </span>
          {collapsed && (
            <Tooltip target={`.sidebar-link#${tooltipId}`} className="text-xs">
              {text}
            </Tooltip>
          )}
          {!collapsed && <span className="pl-3">{text}</span>}
          {qty > 0 && collapsed && showBadgeOnCollapsed && <Badge className="sidebar-link-badge" />}
        </div>
        {qty > 0 && !collapsed && <span className="px-3 mx-3 bg-gray-50 rounded-xl text-sm">{qty}</span>}
      </Link>
    </li>
  )
}

const faEhrMC: IconDefinition = {
  prefix: "fas",
  iconName: "0",
  icon: [
    64,
    64,
    [],
    "e001",
    "m32.136 3.1162c-5.735 0.0114-9.7177 4.9245-12.148 9.5796-3.7914 6.6376-7.5827 13.275-11.374 19.913 6.6175-5.8986 15.203-10.431 24.291-10.101 6.6199 0.29583 12.526 5.5111 13.824 11.978 1.2378-9.6588-0.52965-20.32-7.0954-27.839-1.8261-2.0209-4.677-3.4999-7.4976-3.5305zm15.994 16.779c1.7576 8.8121 1.3203 18.631-3.584 26.404-3.5203 5.4764-10.776 8.1534-16.989 6.1199 7.693 5.9222 17.855 9.7217 27.594 7.5654 4.7019-1.1145 8.365-5.6688 7.8249-10.585-0.1666-2.9124-1.2506-5.6972-2.683-8.2104 1.6882-0.9809-0.49896 0.67577-0.58832-1.0305-3.8581-6.7544-7.7162-13.509-11.574-20.263zm-26.256 6.6166c-9.0852 3.7608-17.469 10.849-20.495 20.469-1.2907 4.4916 0.51736 9.8592 4.8849 11.961 5.026 2.7032 10.864 1.7304 16.333 1.9181h16.595c-8.4942-2.9224-16.783-8.2644-20.894-16.505-2.7854-5.8969-1.4191-13.451 3.575-17.843z",
  ],
}

type Props = {
  className: string
  collapsible?: boolean
  onLinkClicked?: () => void
}

type LinkProps = {
  to: string
  icon: IconProp
  text: string
  qty?: number
  collapsed?: boolean
  showBadgeOnCollapsed?: boolean
  onLinkClicked?: () => void
}

export { Sidebar }
