import { useLocation } from "react-router-dom"
import { classNames } from "primereact/utils"
import { Sidebar } from "primereact/sidebar"

import { NavigationBanner, BottomNav } from "navigation"
import { Sidebar as SidebarMenu, SidebarContextProvider, useWindowBounds } from "commons"
import { pathToRouteName } from "utils"
import { RoutesContainer } from "RoutesContainer"
import { CPOEContextProvider } from "orders"
import { LoginProvider } from "security"
import { VideoCallRender } from "communication"

const FIRST_LEVEL_ROUTE_SUBPATH_DIVISIONS = 4

function App() {
  const { isSmallScreen, sidebarCollapsed, setSidebarCollapsed } = useWindowBounds()
  const location = useLocation()

  const viewPath = location.pathname?.split("/") ?? []
  const isFirstLevelRoute = viewPath.length === FIRST_LEVEL_ROUTE_SUBPATH_DIVISIONS
  const currentRouteName = pathToRouteName(location.pathname)

  return (
    <LoginProvider>
      <div className={classNames("h-full flex bg-gray-100", { "flex-col": isSmallScreen })}>
        <SidebarContextProvider isSmallScreen={isSmallScreen}>
          <VideoCallRender />
          <CPOEContextProvider>
            {isSmallScreen ? (
              <>
                <Sidebar
                  visible={!sidebarCollapsed}
                  showCloseIcon={false}
                  className="sidebar-menu w-fit"
                  onHide={() => setSidebarCollapsed(true)}
                >
                  <SidebarMenu className="pt-3 h-full" onLinkClicked={() => setSidebarCollapsed(true)} />
                </Sidebar>

                {isFirstLevelRoute && (
                  <NavigationBanner page={currentRouteName} toggleMenu onIconClick={() => setSidebarCollapsed(false)} />
                )}

                <div className="overflow-hidden h-full w-full flex-1 flex-col text-start bg-white">
                  <RoutesContainer />
                </div>
                <BottomNav className="h-16 bg-white border-t-2 border-t-gray-200 py-2.5" />
              </>
            ) : (
              <>
                <SidebarMenu className="pt-3" collapsible />
                <div className="flex flex-col h-full w-full">
                  {isFirstLevelRoute && <NavigationBanner page={currentRouteName} />}
                  <div className="overflow-hidden flex flex-1 flex-col text-start bg-white">
                    <RoutesContainer />
                  </div>
                </div>
              </>
            )}
          </CPOEContextProvider>
        </SidebarContextProvider>
      </div>
    </LoginProvider>
  )
}

export default App
