import {
  ChargeItemDefinition,
  CodeableConcept,
  Coding,
  Invoice,
  Parameters,
  PaymentReconciliation,
  codeableConceptAsString,
} from "fhir"

import { getBasePrice, getDiscountPrice, getFeePrice, getMoneyCurrencyAlt, getTaxPrice } from "utils"

import { ActionGroupCode, CPOE_ACTION_GROUP_SYSTEM, CPOE_ACTION_SYSTEM, CpoeRequest, SummaryParameter } from "./types"

const checkIsActionType = ({
  codeableConcept,
  type,
  isNot,
}: {
  codeableConcept?: CodeableConcept[]
  type: ActionGroupCode
  isNot?: ActionGroupCode
}) =>
  codeableConcept?.some(
    (cc) => cc.coding?.find((coding) => coding.system === CPOE_ACTION_GROUP_SYSTEM)?.code === type,
  ) &&
  (isNot
    ? !codeableConcept.some(
        (cc) => cc.coding?.find((coding) => coding.system === CPOE_ACTION_GROUP_SYSTEM)?.code === isNot,
      )
    : true)

const LINE_ITEM_DISCOUNT_SYSTEM = "http://chartedhealth.com/fhir/payment/discount"
const LINE_ITEM_FEE_SYSTEM = "http://chartedhealth.com/fhir/payment/fee"
const LINE_ITEM_PRODUCT_FEE_SYSTEM = "http://chartedhealth.com/fhir/payment/product-fee"
const LINE_ITEM_SHIPPING_METHOD_SYSTEM = "http://chartedhealth.com/fhir/shipping-method"
const PRODUCT_CODE_SYSTEM = "http://chartedhealth.com/fhir/product-type"

const updateRequestStatus = (request: CpoeRequest, statusCode: Coding) => {
  const code = [
    {
      coding: [statusCode, ...(request.resource.code?.[0]?.coding?.slice(1) ?? [])],
    },
    ...(request.resource.code?.slice(1) ?? []),
  ]

  return { ...request, resource: { ...request.resource, code } } as CpoeRequest
}

const getSummaryParameter = (summary: Parameters, key: SummaryParameter) => {
  const param = summary.parameter?.find((param) => param.name === key)
  switch (key) {
    case "discounts":
      return param?.part?.reduce(
        (prev, part) => {
          return { ...prev, ...{ [part.name]: part.value?.decimal ?? 0 } }
        },
        {} as Record<string, number>,
      )

    default:
      return param?.value?.decimal ?? 0
  }
}

const buildAction = (cid: ChargeItemDefinition, actionCode: "add-fee" | "add-discount") => ({
  code: [
    {
      coding: [
        {
          code: actionCode,
          system: CPOE_ACTION_SYSTEM,
        },
      ],
    },
  ],
  resource: {
    localRef: cid.id,
  },
})

const getInvoiceMeta = (invoice: Invoice, paymentReconciliation?: PaymentReconciliation) => {
  const {
    paymentAmount,
    issuer,
    paymentIssuer,
    paymentStatus,
    paymentDate,
    totalGross,
    subject,
    creditCard,
    lineItem,
  } = {
    ...invoice,
    ...paymentReconciliation,
  }
  const price = paymentAmount
    ? `${getMoneyCurrencyAlt(paymentAmount.currency)}${paymentAmount.value ?? 0}`
    : totalGross
      ? `${getMoneyCurrencyAlt(totalGross.currency)}${totalGross.value ?? 0}`
      : "$0"

  const order_issuer = paymentIssuer?.display ?? issuer?.display
  const credit_card =
    creditCard &&
    JSON.stringify({
      card_holder: creditCard.cardHolderName,
      last4Digits: creditCard.last4Digits,
      expiration_month: creditCard.expirationMonth,
      expiration_year: creditCard.expirationYear,
      card_type: creditCard.type,
    })

  const products = lineItem?.reduce((acc, { chargeItem, priceComponent }) => {
    const itemPrice =
      getBasePrice(priceComponent) ??
      getTaxPrice(priceComponent) ??
      getFeePrice(priceComponent) ??
      getDiscountPrice(priceComponent)
    const priceStr = `${
      itemPrice?.factor && itemPrice?.type === "base" ? "x" + itemPrice.factor : ""
    } ${getMoneyCurrencyAlt(itemPrice?.amount?.currency)}${itemPrice?.amount?.value ?? 0}`.replaceAll("-", "")
    const product = chargeItem?.Reference?.display ?? codeableConceptAsString(chargeItem?.CodeableConcept)
    return [...acc, `${product} ${priceStr}`]
  }, Array<string>())

  return {
    price,
    order_issuer,
    credit_card,
    payment_status: paymentStatus,
    payment_date: paymentDate,
    order_for: subject?.display,
    products: products?.join("\n"),
  }
}

const RG_BILLING_ACTION_CODES = {
  PHARMA: "billing-type-rx",
  NUTRA: "billing-type-nutraceutical",
}

export {
  LINE_ITEM_DISCOUNT_SYSTEM,
  LINE_ITEM_FEE_SYSTEM,
  LINE_ITEM_PRODUCT_FEE_SYSTEM,
  LINE_ITEM_SHIPPING_METHOD_SYSTEM,
  PRODUCT_CODE_SYSTEM,
  RG_BILLING_ACTION_CODES,
  buildAction,
  checkIsActionType,
  getInvoiceMeta,
  getSummaryParameter,
  updateRequestStatus,
}
