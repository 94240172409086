import { ResourceObject } from "fhir"
import { FormikValues } from "formik"
import { classNames } from "primereact/utils"

import { CommonProps, StackedListItem, StackedListItemProps } from "./StackedListItem"

const StackedListContainer = <T extends ResourceObject | FormikValues>({
  data,
  itemModelBuilder,
  rowHover = true,
  itemPadding,
  itemsClassName,
  className,
}: Props<T>) => {
  return (
    <ul className={classNames("@container divide-y divide-gray-200", className)}>
      {data?.map((item, index) => (
        <StackedListItem
          key={item?.id ?? index}
          modelData={itemModelBuilder(item, index)}
          rowHover={rowHover}
          itemPadding={itemPadding}
          className={itemsClassName}
        />
      ))}
    </ul>
  )
}

type Props<T> = CommonProps & {
  data: T[]
  itemsClassName?: string
  itemModelBuilder(item: T, itemIndex: number): StackedListItemProps
  className?: string
}

export { StackedListContainer }
