import { useMutation } from "@tanstack/react-query"
import { Communication } from "fhir"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { CustomError } from "commons"

const useSendCommunication = (onSuccess: () => void) => {
  const { create } = useClient()

  const newCommunication = async (message: Communication) => {
    return create("Communication", message)
  }

  const { mutate: sendCommunication, isPending } = useMutation({
    mutationFn: newCommunication,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess,
  })

  return { sendCommunication, isLoading: isPending }
}
export { useSendCommunication }
