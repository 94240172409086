import { useEffect, useState } from "react"
import { Reference } from "fhir"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBuilding, faLocationDot } from "@fortawesome/pro-regular-svg-icons"
import { Dropdown } from "primereact/dropdown"
import { Button } from "primereact/button"

import { useAuth } from "security"
import { getStringAddress } from "utils"
import { useHomeContext } from "home"

const PatientSelection = ({ navToPatient }: Props) => {
  const { logout } = useAuth()
  const { patientOrganizations, patientRefs } = useHomeContext()
  const [selectedPat, setSelectedPatient] = useState<Reference>()

  useEffect(() => {
    setSelectedPatient(patientRefs[0])
  }, [patientOrganizations])

  const orgTemplate = (option: Reference) => {
    const org = patientOrganizations?.[option.id!]
    return (
      org && (
        <div className="text-sm">
          <div className="font-bold">
            <FontAwesomeIcon icon={faBuilding} className="fa-fw mr-1" />
            <span title="Organization" className="text-base">
              {org.name}
            </span>
          </div>
          <div title="Address">
            <FontAwesomeIcon icon={faLocationDot} className="fa-fw mr-1" />
            {getStringAddress(org.address?.[0])}
          </div>
        </div>
      )
    )
  }

  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full text-gray-400 text-sm">Please select your practice</div>
      <div className="flex flex-col mt-4 w-full">
        <Dropdown
          className="w-full"
          name="currentPatient"
          options={patientRefs}
          optionLabel="display"
          itemTemplate={orgTemplate}
          resetFilterOnHide
          showFilterClear
          filter
          onInvalid={() => {}}
          filterBy="display"
          value={selectedPat}
          onChange={(e) => {
            if (e.value) {
              setSelectedPatient(e.value)
            }
          }}
        />
      </div>
      <Button
        className="w-full button-primary text-center mt-10"
        label="Accept"
        onClick={selectedPat ? () => navToPatient(selectedPat) : undefined}
        disabled={!selectedPat}
      />
      <Button className="w-full button-default text-center mt-3" label="Logout" onClick={logout} />
    </div>
  )
}

type Props = {
  navToPatient: (patient: Reference) => void
}

export { PatientSelection }
