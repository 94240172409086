import { createElement } from "react"
import { toast } from "react-toastify"

import { CustomError } from "commons"

import { NotificationError } from "./components"

const email_support = window.VITE_APP_SUPPORT_EMAIL

const displayNotificationError = (error: CustomError) => {
  if (window.innerWidth > 768) {
    toast.error(createElement(NotificationError, { error }), { autoClose: 2000, closeOnClick: false })
  } else {
    toast.error(createElement(NotificationError, { error }), {
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: false,
      style: { bottom: "4rem", minHeight: 0 },
    })
  }
}

const getCustomErrorMessage = (error: CustomError) => {
  const cause = error.cause?.name
  const message = error.message

  if (["400", "405", "406", "413", "414", "422", "429", "500"].includes(cause as string)) {
    return "We're sorry. Your operation couldn't be completed due an internal error. We're looking into the causes"
  } else if (["401", "403", "407", "423"].includes(cause as string)) {
    return "We're sorry. It seems we couldn't grant you access for this request. Try to log in again"
  } else if (["404", "410"].includes(cause as string)) {
    return "We're sorry, but the target resource couldn't be found. We're looking into the causes"
  } else if (["402"].includes(cause as string)) {
    return "We sorry. It appears that your payment could not be processed. Please verify your payment information and try again"
  } else if (message) {
    return message
  } else return "We're sorry, but due an error we couldn't satisfy your request. We're looking into the causes"
}

const copyToClipboard = async (trace: string, onSuccess?: () => void) => {
  navigator.clipboard
    .writeText(trace)
    .then(onSuccess)
    .catch(() => {
      console.error("Failed to copy. Check navigator permits.")
      /* Rejected - text failed to copy to the clipboard */
    })
}
const getDDurl = (errorID: string) =>
  encodeURIComponent(
    `https://us5.datadoghq.com/logs?query=status:error service:patient-portal* -*AbortError @custom_trace:${errorID} &agg_m=count&agg_m_source=base&agg_q=service,env&agg_q_source=base,base&agg_t=count&cols=host,service&event=AgAAAZJDTdC4fdmwjQAAAAAAAAAYAAAAAEFaSkRUa1ZqQUFCTGJmaGFOUXhTRkFBQQAAACQAAAAAMDE5MjQzNGYtMmU3Ny00YjE3LWJmNTMtOTFmYzVlMzc4MWVj&fromUser=true&messageDisplay=inline&panel={"queryString":"env:dev service:ehr","filters":[{"isClicked":true,"source":"tag","path":"env","value":"dev"},{"isClicked":true,"source":"tag","path":"service","value":"ehr"}],"queryId":"a","timeRange":{"from":1727706000000,"to":1727706120000,"live":false}}&refresh_mode=sliding&sort_m=,&sort_m_source=,&sort_t=,&source=monitor_notif&storage=hot&stream_sort=desc&top_n=10,10&top_o=top,top&viz=timeseries&x_missing=true,true&from_ts=1727186202748&to_ts=1727200602748&live=true`,
  )

const getContactToSupportURL = (errorID: string) =>
  `mailto:${email_support}?subject=Error report ID: ${errorID}&body=Please do not delete the information below, it may be useful to solve your problem faster:%0D%0A%0D%0A${getDDurl(errorID)}%0D%0A%0D%0AIf you would like to tell us about your problem, please feel free to comment below %3A%0D%0A%0D%0A`

export { displayNotificationError, getCustomErrorMessage, copyToClipboard, getContactToSupportURL }
