import { faSend } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { InputTextarea } from "primereact/inputtextarea"
import { useState } from "react"
import { classNames } from "primereact/utils"

import "./ChatInput.css"

const ChatInput = ({ className, sendMessage }: Props) => {
  // const hiddenFileInput = useRef<HTMLInputElement>(null)

  const [currentMessage, setCurrentMessage] = useState<string | undefined>("")

  // const handleFilePicked = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   console.log(event?.target?.files?.[0])
  // }

  const handleChangeMessage = (mssg?: string) => {
    if (!mssg?.endsWith("\n") && !mssg?.endsWith("\r")) {
      setCurrentMessage(mssg)
    }
  }

  const handleSendMessage = () => {
    if (currentMessage) sendMessage(currentMessage.trim())
    setCurrentMessage("")
  }

  return (
    <div className={classNames("chat-input", className)}>
      <div className="p-inputgroup">
        {/* <span className="p-inputgroup-addon">
          <FontAwesomeIcon
            icon={faPaperclip}
            size="lg"
            className="cursor-pointer"
            onClick={() => {
              hiddenFileInput?.current?.click()
            }}
          />
          <input ref={hiddenFileInput} type="file" className="hidden" max={1} onChange={handleFilePicked} />
        </span> */}
        <InputTextarea
          value={currentMessage}
          rows={currentMessage && currentMessage?.length > 500 ? 3 : 1}
          cols={30}
          placeholder="Type your message here..."
          onInput={(e) => handleChangeMessage(e.currentTarget?.value)}
          onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
        />

        {/* <span className="p-inputgroup-addon">
          <FontAwesomeIcon icon={faFaceSmile} size="lg" />
        </span>
        */}
        {currentMessage && (
          <span className="mx-2 my-1 text-gray-400 flex rounded-full w-10 h-10 items-center justify-center hover:bg-gray-400 hover:text-white">
            <FontAwesomeIcon icon={faSend} size="lg" className="cursor-pointer " onClick={handleSendMessage} />
          </span>
        )}
      </div>
    </div>
  )
}

type Props = {
  className?: string
  sendMessage(mssg: string): void
}

export { ChatInput }
