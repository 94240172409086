import { faEllipsisVertical, faExternalLink, faSpinner } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Menu } from "primereact/menu"
import { MenuItem } from "primereact/menuitem"
import { classNames } from "primereact/utils"
import { useRef } from "react"

const CardListItem = ({
  rowImg,
  contentHeader,
  contentSubheader,
  className,
  contentClassName = "py-3",
  actionsClassName,
  dropdownMenuItems,
  actionItem,
  removeActionItem,
  onContentCliked,
  isLoading = false,
  externalAction,
  disabled,
  lastElementClass = "last:mb-6 border-none",
}: Props) => {
  const menu = useRef<Menu>(null)

  return (
    <div
      className={classNames(
        "flex items-center first:mt-1 border-gray-300 border-solid",
        { "opacity-65": disabled },
        lastElementClass,
        className,
      )}
    >
      {rowImg}

      <div
        className={classNames("item-content w-full flex items-center", contentClassName)}
        onClick={!disabled ? onContentCliked : undefined}
      >
        <div className="flex-1 flex flex-col space-y-1 text-sm w-full">
          <div className="inline-flex items-center">{contentHeader}</div>
          {contentSubheader && <div className="text-slate-400 inline-flex">{contentSubheader}</div>}
        </div>
      </div>

      {isLoading ? (
        <FontAwesomeIcon icon={faSpinner} spin className="text-gray-500 mr-1" size="lg" />
      ) : disabled ? (
        <></>
      ) : dropdownMenuItems?.length ? (
        <div
          className={classNames(
            "item-actions mr-2 flex items-center justify-center content-center h-6 w-6 p-1 font-bold cursor-pointer text-gray-500 text-2xl rounded-full hover:bg-gray-100 overflow-hidden",
            actionsClassName,
          )}
          aria-controls="popup_menu"
          aria-haspopup
          onClick={(event) => menu.current && menu.current.toggle(event)}
        >
          <span className="inline-flex h-1/2 items-center justify-center">
            <FontAwesomeIcon icon={faEllipsisVertical} className="text-[1.2rem]" />
          </span>
          <Menu model={dropdownMenuItems} popup ref={menu} id="popup_menu" style={{ fontSize: "small" }} />
        </div>
      ) : externalAction ? (
        <span
          className="inline-flex h-1/2 items-center justify-center text-gray-500 mr-2 cursor-pointer text-sm"
          title={externalAction}
          onClick={!disabled ? onContentCliked : undefined}
        >
          <FontAwesomeIcon icon={faExternalLink} />
        </span>
      ) : (
        actionItem && (
          <>
            <span
              title={actionItem.label}
              className={classNames(
                "item-actions flex justify-center items-center h-8 w-8 cursor-pointer text-gray-500 font-semibold rounded-full hover:bg-gray-100 mx-1",
                actionsClassName,
              )}
              onClick={(e) =>
                !actionItem.disabled && !disabled && actionItem.command?.({ originalEvent: e, item: actionItem })
              }
            >
              {actionItem.icon ?? actionItem.label}
            </span>
            {removeActionItem && (
              <span
                title={removeActionItem.label}
                className={classNames(
                  "item-actions flex justify-center items-center h-8 w-8 cursor-pointer text-gray-500 font-semibold rounded-full hover:bg-gray-100 mx-1",
                  actionsClassName,
                )}
                onClick={(e) =>
                  !removeActionItem.disabled &&
                  !disabled &&
                  removeActionItem.command?.({ originalEvent: e, item: removeActionItem })
                }
              >
                {removeActionItem.icon ?? removeActionItem.label}
              </span>
            )}
          </>
        )
      )}
    </div>
  )
}

type Props = {
  rowImg?: JSX.Element
  contentHeader?: JSX.Element
  contentSubheader?: JSX.Element
  className?: string
  contentClassName?: string
  actionsClassName?: string
  dropdownMenuItems?: MenuItem[]
  actionItem?: MenuItem
  removeActionItem?: MenuItem
  onContentCliked?: () => void
  isLoading?: boolean
  disabled?: boolean
  externalAction?: string
  lastElementClass?: string
}

export { CardListItem }
