import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { useFormikContext } from "formik"
import { Button } from "primereact/button"
import { humanNameAsString, Patient } from "fhir"

import { AvatarCrop, AvatarImage } from "commons"
import { faImage, faTrash } from "@fortawesome/pro-regular-svg-icons"

const ProfileImageField = ({ profileImageTempFile, setProfileImageTempFile }: Props) => {
  const [showAvatarCrop, setShowAvatarCrop] = useState(false)
  const {
    values: { name, photo },
  } = useFormikContext<Patient>()

  // If the temp image is not the same as the one in the form, we show the temp image
  const hasTempImage = !(profileImageTempFile === photo?.[0]?.url)
  const photoUrl = hasTempImage ? profileImageTempFile : photo?.[0]?.url

  return (
    <>
      <div className="flex flex-col flex-1 gap-2 items-center justify-center">
        <AvatarImage
          photoUrl={photoUrl}
          name={humanNameAsString(name?.[0])}
          className="w-48 h-48 rounded"
          hasTempImage={hasTempImage}
        />
        <div className="flex flex-col gap-2 justify-center">
          <Button
            type="button"
            label="Change avatar"
            className="p-button-sm button-primary h-8"
            icon={<FontAwesomeIcon icon={faImage} className="mr-1" />}
            onClick={() => setShowAvatarCrop(true)}
          />
          <Button
            type="button"
            label="Delete avatar"
            className="p-button-sm p-button-outlined h-8"
            icon={<FontAwesomeIcon icon={faTrash} className="mr-1" />}
            onClick={() =>
              setProfileImageTempFile((previous) => {
                return { ...previous, fileUrl: "" }
              })
            }
          />
        </div>
      </div>
      <AvatarCrop visible={showAvatarCrop} onHide={() => setShowAvatarCrop(false)} onSave={setProfileImageTempFile} />
    </>
  )
}

type Props = {
  profileImageTempFile: string
  setProfileImageTempFile: React.Dispatch<
    React.SetStateAction<{
      file: Blob
      fileUrl: string
    }>
  >
}

export { ProfileImageField }
