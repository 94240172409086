import { useInfiniteQuery } from "@tanstack/react-query"
import { format, isToday, isYesterday } from "date-fns"
import { asReference, Communication, getResources, getResourcesByTypeAsIndex, Patient, Practitioner } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { formatsByTypes } from "data"

import { communicationKeys } from "../query-keys"
import { IMessage } from "../types"

const useCommunications = (patientId: string) => {
  const { search } = useClient()
  const queryKey = communicationKeys.withPatientId(patientId)

  const {
    data,
    isLoading,
    isError,
    error,
    isFetching,
    isRefetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    refetch,
  } = useInfiniteQuery<CommunicationsQueryData, Error>({
    queryKey,
    queryFn: async ({ pageParam = 1 }) => {
      const filters = new URLSearchParams({
        status: "in-progress,completed",
        category: "message",
        _elements: "id,status,sender,recipient,payload,sent",
        _count: "20",
        _page: `${pageParam}`,
        _sort: "-sent",
        _include: "sender",
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/Communication`, filters })

      const communications = getResources<Communication>(bundle, "Communication")
      const practitioners = getResourcesByTypeAsIndex<Practitioner>(bundle, "Practitioner")
      const patients = getResourcesByTypeAsIndex<Patient>(bundle, "Patient")

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      const messages = communications.map<IMessage>((communication) => {
        const senderRef = practitioners?.[communication.sender?.id ?? ""] ?? patients?.[communication.sender?.id ?? ""]
        const photo = senderRef?.photo?.[0]?.url ?? ""
        const sender = { ...asReference(senderRef), photo }

        return {
          communication,
          sender,
        }
      })

      return { messages, next, total: bundle?.total ?? 0 }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, patientId } },
    refetchInterval: 30000,
    refetchIntervalInBackground: true,
  })

  const { communicationsByDate, communications, count } = useMemo(() => {
    const newData = data?.pages.flatMap((page) => page.messages).reverse() ?? []
    const count = newData?.length

    const initialMap = new Map<string, IMessage[]>([
      [newData[0]?.communication.sent ? getDateLabel(newData[0].communication.sent) : "Today", []],
    ])

    const groupMap = newData.reduce((map, message) => {
      const dateLabel = message.communication.sent ? getDateLabel(message.communication.sent) : "Today"
      const value = map.get(dateLabel)

      if (value) {
        value.push(message)
      } else {
        map.set(dateLabel, [message])
      }

      return map
    }, initialMap)

    return {
      communicationsByDate: groupMap
        ? Array.from(groupMap.entries()).map(([date, entries]) => ({
            date,
            communications: entries,
          }))
        : [],
      communications: newData,
      count,
    }
  }, [data?.pages])

  const reloadLatestCommunications = () => {
    //TODO: investigate how to do this in the new version
    // refetch({ refetchPage: (_page, index) => index === 0 })
    refetch()
  }

  if (isError) {
    throw error
  }

  return {
    communicationsByDate,
    communications,
    isLoading,
    count,
    total: data?.pages?.[0]?.total ?? 0,
    isFetching: isFetching || isRefetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    reloadLatestCommunications,
  }
}

const getDateLabel = (dateStr: string) => {
  const date = new Date(dateStr)

  return isToday(date) ? "Today" : isYesterday(date) ? "Yesterday" : format(date, formatsByTypes.ISO_8601_DATE)
}

type CommunicationsQueryData = {
  messages: IMessage[]
  next: number | undefined
  total: number
}

export { useCommunications }
