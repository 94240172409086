import { faSpinner, faEllipsis } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { classNames } from "primereact/utils"
import { Menu } from "primereact/menu"
import { MenuItem } from "primereact/menuitem"
import { ReactNode, useRef } from "react"

const DropdownMenu = ({ actionsClassName, dropdownMenuItems, triggerIcon, loading = false }: Props) => {
  const menu = useRef<Menu>(null)

  return (
    <div
      className={classNames(
        "item-actions flex justify-center content-center items-center h-8 w-8 font-bold cursor-pointer text-slate-400 text-2xl rounded-full hover:bg-slate-100",
        actionsClassName,
      )}
      onClick={(event) => menu.current && menu.current.toggle(event)}
    >
      <Menu model={dropdownMenuItems} popup ref={menu} id="popup_menu" style={{ fontSize: "small" }} />
      <span
        className="w-full h-1/2 inline-grid text-center justify-center items-center content-center"
        aria-controls="popup_menu"
        aria-haspopup
      >
        {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : triggerIcon ?? <FontAwesomeIcon icon={faEllipsis} />}
      </span>
    </div>
  )
}

type Props = {
  actionsClassName?: string
  dropdownMenuItems: MenuItem[]
  triggerIcon?: ReactNode
  loading?: boolean
}

export { DropdownMenu }
