import { useContext } from "react"

import { registerErrorTrace } from "logger"
import { CustomError } from "commons/types"

import { SidebarCtx } from "../components/SidebarContextProvider"

const useSidebarContext = () => {
  const context = useContext(SidebarCtx)

  if (context === undefined) {
    throw registerErrorTrace(new Error("Couldn't create Sidebar Context") as CustomError)
  }

  return context
}

export { useSidebarContext }
