import { CodeableConcept, codeableConceptAsString } from "fhir"
import { FC, useState } from "react"

import { useValueSet } from "value-set"

import { FormFieldBaseProps } from "./FormField"
import { AutocompleteField } from "./AutocompleteField"

const AutocompleteCodeableConceptField: FC<Props> = ({ valueSetId, ...fieldProps }) => {
  const [filter, setFilter] = useState<string | undefined>()
  const { codes, isLoading } = useValueSet(valueSetId, filter)

  return (
    <AutocompleteField
      setFilter={setFilter}
      isLoading={isLoading}
      optionList={codes as CodeableConcept[]}
      selectedItemTemplate={(item) => codeableConceptAsString(item)}
      onSelectValue={(e) => ({ coding: [e.value], text: e.value?.display })}
      setValue={(item) => item?.coding?.[0]}
      emptyValue={{ coding: undefined, text: undefined }}
      invalidCondition={(item) => !item?.coding?.[0]?.code}
      {...fieldProps}
    />
  )
}

type ValueSetIds =
  | "icd-10"
  | "ch-allergy-intolerance-code"
  | "v3-FamilyMember"
  | "rxnorm"
  | "usps-state"
  | "relatedperson-relationshiptype"

type Props = FormFieldBaseProps<CodeableConcept> & {
  readonly?: boolean
  disabled?: boolean
  multiple?: boolean
  valueSetId: ValueSetIds
}

export { AutocompleteCodeableConceptField }
