import { useQuery } from "@tanstack/react-query"
import { Duration, MedicationDispense, MedicationKnowledge, MedicationRequest, getResources, isPatient } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { CartMedData } from "orders"
import { getCommonCode, getMedCodes } from "utils"

import { draftMedicationRequestKeys } from "../query-keys"

const useDraftMedicationRequests = (patientId: string) => {
  const { search } = useClient()
  const queryKey = draftMedicationRequestKeys.withPatientId(patientId)

  const { data, isLoading, isFetching } = useQuery({
    queryKey,
    queryFn: async () => {
      const filters = new URLSearchParams({
        _query: "patient-prescriptions",
        _patient: patientId,
        _status: "draft",
        category: "nutraceutical",
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/MedicationRequest`, filters })

      const medicationRequests = getResources<MedicationRequest>(bundle, "MedicationRequest")
      const medicationDispenses = getResources<MedicationDispense>(bundle, "MedicationDispense")
      const medicationKnowledges = getResources<MedicationKnowledge>(bundle, "MedicationKnowledge")

      return {
        medicationRequests,
        medicationDispenses,
        medicationKnowledges,
        total: bundle?.total ?? 0,
      }
    },
    refetchOnWindowFocus: false,
    meta: { context: { queryKey, patientId } },
  })

  const { medicationRequests, medicationKnowledges, medicationDispenses, addedMeds, medCodes } = useMemo(() => {
    const addedMeds =
      data?.medicationRequests.reduce(
        (acc, item) => {
          const med = getCommonCode(item.medication?.CodeableConcept?.coding)
          return {
            ...acc,
            [med]: {
              id: item.id as string,
              quantity: item.dispenseRequest?.quantity?.value as number,
              interval: item.dispenseRequest?.dispenseInterval as Duration,
              requestedByPract: !isPatient(item.recorder),
            },
          }
        },
        {} as Record<string, CartMedData>,
      ) ?? {}

    const medCodes = getMedCodes({ meds: data?.medicationRequests, withQty: true })

    return {
      medicationRequests: data?.medicationRequests,
      medicationKnowledges: data?.medicationKnowledges,
      medicationDispenses: data?.medicationDispenses,
      addedMeds,
      medCodes,
    }
  }, [data])

  return {
    medicationRequests,
    medicationDispenses,
    medicationKnowledges,
    addedMeds,
    medCodes,
    isLoading,
    isFetching,
    total: medicationRequests?.length ?? 0,
  }
}

export { useDraftMedicationRequests }
