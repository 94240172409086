import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Parameters } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { medicationOrderKeys } from "../query-keys"

const useRescheduleMrOrder = (onSettled?: () => void) => {
  const { operationRequest } = useClient()
  const queryClient = useQueryClient()

  const rescheduleOrder = async ({ id, newDate }: { id: string; newDate?: Date }) => {
    const parameters = newDate
      ? ({
          resourceType: "Parameters",
          parameter: [
            {
              name: "date",
              value: {
                date: newDate.toISOString(),
              },
            },
          ],
        } as Parameters)
      : undefined

    return operationRequest<Parameters>({
      endpoint: "ServiceRequest",
      method: "POST",
      operation: "reschedule",
      id,
      parameters,
    })
  }

  const { mutate: rescheduleMrOrder, isPending } = useMutation({
    mutationFn: rescheduleOrder,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (_, { id, newDate }) => {
      await queryClient.invalidateQueries({ queryKey: medicationOrderKeys.detail(id) })

      displayNotificationSuccess(newDate ? "Order rescheduled successfully!" : "Order hold on successfully!")
    },
    onSettled: onSettled,
  })

  return { rescheduleMrOrder, isRescheduling: isPending }
}

export { useRescheduleMrOrder }
