import { faEye, faPaperPlane, faTag } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Consent } from "fhir"

import { MenuStyles, StackedListItemProps } from "commons"
import { formatsByTypes } from "data"
import { formatDate, getBadgeColor } from "utils"

const consentModelBuilder = ({
  consent,
  download,
  resend,
  isLoading,
  showAsExternalAction,
}: ConsentModelBuilderArgs): StackedListItemProps => {
  const createdAt = consent.meta?.createdAt && formatDate(new Date(consent.meta.createdAt), formatsByTypes.SHORT_DATE)
  const category = consent.category?.[0].coding?.[0].display ?? consent.category?.[0].coding?.[0].code ?? "No category"

  return {
    isLoading,
    leftData: [
      {
        lineItems: [{ name: "Date", value: createdAt ?? "Unspecified date" }],
      },
      {
        lineItems: [{ name: "Category", value: category, icon: faTag }],
      },
    ],
    badge: {
      ...getBadgeColor(consent.status),
    },
    menuStyle: showAsExternalAction ? MenuStyles.ExternalAction : undefined,
    menu: [
      {
        label: "View",
        icon: <FontAwesomeIcon icon={faEye} />,
        command: download,
        disabled: consent.status !== "active",
      },
      ...(resend
        ? [
            {
              label: "Resend",
              icon: <FontAwesomeIcon icon={faPaperPlane} />,
              command: resend,
            },
          ]
        : []),
    ],
  }
}

interface ConsentModelBuilderArgs {
  consent: Consent
  download: () => void
  resend?: () => void
  isLoading?: boolean
  showAsExternalAction?: boolean
}

export { consentModelBuilder }
