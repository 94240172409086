import { Patient } from "fhir"
import { getFirstEmail, getFirstPhone } from "fhir/utils"

import { PhoneField } from "commons"
import { useLoginContext } from "security"
import { ProfileSection } from "data"

import { usePatchPatient } from "../hooks"
import { telecomValidationSchema } from "./validations"
import { InformationCard } from "./InformationCard"
import { ProfileEmailField } from "./ProfileEmailField"

const ContactInformation = () => {
  const { loggedInPatient, loggedInPatientId } = useLoginContext()
  const { patchPatient, isPatching } = usePatchPatient()

  const initialValues: Partial<Patient> = { telecom: loggedInPatient.telecom }
  const { telecom } = initialValues

  const onSubmit = (patientData: Partial<Patient>) =>
    patchPatient({ patientId: loggedInPatientId, patientData: { ...patientData, meta: loggedInPatient.meta } })

  return (
    <InformationCard
      id={ProfileSection.CONTACT}
      title="Contact Information"
      data={{
        Email: getFirstEmail(telecom),
        Telephone: getFirstPhone(telecom),
      }}
      initialValue={initialValues}
      validationSchema={telecomValidationSchema}
      isUpdating={isPatching}
      onSubmit={onSubmit}
      className="profile-card-section"
    >
      <ProfileEmailField label="Email" email={telecom?.[0].value} />

      <PhoneField field="telecom[1].value" label="Phone" className="md:col-span-2" />
    </InformationCard>
  )
}

export { ContactInformation }
