import { Reference } from "fhir"

const cpoeOrdersKeys = {
  all: ["CPOERequests"] as const,
  count: ["RequestGroupCount"] as const,
  extraPrices: ["extras-prices"] as const,
  withPatientId: (patientId: string) => [...cpoeOrdersKeys.all, patientId] as const,
  countWithPatientId: (patientId: string) => [...cpoeOrdersKeys.count, patientId] as const,
  extraPricesWithItemList: (items: Reference[]) => [...cpoeOrdersKeys.extraPrices, items] as const,
}

export { cpoeOrdersKeys }
