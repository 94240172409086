import { GroupedChargeItemDefinitionsCodes } from "./types"

//uses the names of the functions there are been use to keep it `commons`
const commonsQueryKeys = {
  chargeItemsDefinitions: (organizationID: string, codes?: GroupedChargeItemDefinitionsCodes) => [
    "organization/cid",
    organizationID,
    codes,
  ],
  signed: ["signedUrl"] as const,
  signedWithUrl: (url: string) => [...commonsQueryKeys.signed, url],
  loggedIn: (userId?: string) => ["logged-in-patient", ...(userId ? [userId] : [])] as const,
}

export { commonsQueryKeys }
