import { useCountInvoices } from "invoices"

const useCountNotifications = (patientId: string) => {
  const { issuedInvoicesCount } = useCountInvoices(patientId, ["issued"])

  return {
    notificationCount: issuedInvoicesCount,
  }
}

export { useCountNotifications }
