import { useQuery } from "@tanstack/react-query"
import { Patient, PractitionerRole, getResources } from "fhir"

import { useClient } from "api"

const usePatientGeneralPractitionerRole = (patient: Patient, organizationId: string) => {
  const { search } = useClient()
  const queryKey = ["patient", patient.id, "general-pract-role"]

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async () => {
      const filters = new URLSearchParams({
        practitioner: patient.generalPractitioner?.[0].id ?? "",
        role: "practitioner",
      })

      const bundle = await search({ endpoint: `Organization/${organizationId}/PractitionerRole`, filters })

      const practitionerRoles = getResources<PractitionerRole>(bundle, "PractitionerRole")

      return practitionerRoles?.[0]
    },
    meta: { context: { queryKey, patient } },
    enabled: !!patient,
  })

  return { practitionerRole: data, isLoading }
}

export { usePatientGeneralPractitionerRole }
