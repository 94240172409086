import InfiniteScroll from "react-infinite-scroller"
import { faMessages } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Fragment, useEffect, useId, useRef } from "react"
import { Communication } from "fhir"
import { classNames } from "primereact/utils"

import { LoadingView } from "commons"
import { useUnreadedCommunications } from "communication"
import { useLoginContext } from "security"

import { useCommunications, useReadCommunications, useSendCommunication, useFilterUnreadedComms } from "../hooks"
import { BubbleMessage } from "./BubbleMessage"
import { ChatInput } from "./ChatInput"
import "./CommunicationContainer.css"

const CommunicationContainer = () => {
  const loaderKey = useId()
  const refBottom = useRef<HTMLDivElement>(null)
  const refUnreaded = useRef<HTMLDivElement>(null)

  const { loggedInPatientId, managingOrganizationId } = useLoginContext()
  const user = { id: loggedInPatientId, resourceType: "Patient" }

  const {
    communicationsByDate,
    communications,
    reloadLatestCommunications,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
  } = useCommunications(loggedInPatientId)
  const { unreadedCount, unreadedCommunications } = useFilterUnreadedComms(loggedInPatientId, communications)
  const { reloadUnreadCommunications } = useUnreadedCommunications(loggedInPatientId)

  const { readCommunications } = useReadCommunications(() => {
    reloadLatestCommunications()
    if (unreadedCount) reloadUnreadCommunications()
  })
  const readAllUnreaded = () => unreadedCount && readCommunications(unreadedCommunications)

  const { sendCommunication } = useSendCommunication(reloadLatestCommunications)

  const scrollHook = () => {
    if (refBottom.current && refBottom.current.getBoundingClientRect().bottom <= window.innerHeight) readAllUnreaded()
  }

  useEffect(() => {
    if (refUnreaded.current) refUnreaded.current.scrollIntoView()
    else refBottom.current?.scrollIntoView({ block: "end" })
  }, [isLoading])

  useEffect(() => {
    scrollHook()
  }, [isFetching])

  const loader = () => (
    <div className="h-32 my-4" key={loaderKey}>
      <LoadingView />
    </div>
  )

  if (isLoading) return <LoadingView />

  const addMessage = (msg: Communication) => {
    communicationsByDate[communicationsByDate.length - 1].communications.push({
      communication: { ...msg, status: "preparation" },
      sender: { ...user, photo: "" },
    })

    sendCommunication(msg)

    setTimeout(() => {
      refBottom.current?.scrollIntoView()
    }, 100)
    readAllUnreaded()
  }

  return (
    <div className="h-full relative grid grid-rows-6 bg-white pt-1 p-5 pb-2">
      <div className="flex flex-col overflow-auto pb-3 row-span-full" onScroll={scrollHook}>
        {!communicationsByDate?.length ||
        (communicationsByDate.length === 1 && communicationsByDate[0]?.communications.length === 0) ? (
          <div className="flex flex-col items-center justify-center h-full">
            <FontAwesomeIcon icon={faMessages} size="3x" className="text-slate-400" />
            <p className="text-md text-slate-400 pt-4 pb-2 place-self-center">No messages</p>
          </div>
        ) : (
          <>
            <InfiniteScroll
              loadMore={() => fetchNextPage()}
              hasMore={hasNextPage}
              useWindow={false}
              loader={loader()}
              isReverse={true}
              initialLoad={false}
            >
              {communicationsByDate.map((data, index) => (
                <div className="w-full relative pt-8 pb-3" key={data.date}>
                  <div
                    className={classNames("chat-divider absolute top-0 flex justify-center h-full w-full", {
                      "border-t": index !== 0,
                    })}
                  >
                    <span className="sticky top-4 max-h-[2rem] -translate-y-4 bg-white py-1 px-2 rounded-full border text-slate-400 text-sm z-10">
                      {data.date}
                    </span>
                  </div>

                  {data.communications.map(({ communication: { payload, sent, status }, sender }, index) => {
                    const previousMssg = data.communications[index - 1] ?? undefined
                    const nextMssg = data.communications[index + 1] ?? undefined

                    return (
                      <Fragment key={index}>
                        {unreadedCount > 0 &&
                          status === "in-progress" &&
                          sender?.id !== loggedInPatientId &&
                          previousMssg?.sender?.id === loggedInPatientId && (
                            <div
                              className="bg-white px-2 py-1 rounded-full border text-sm text-blue-500/70 font-bold flex my-3 mx-auto w-fit whitespace-nowrap"
                              ref={refUnreaded}
                            >
                              {unreadedCount === 1 ? `${unreadedCount} new message` : `${unreadedCount} new messages`}
                            </div>
                          )}
                        <BubbleMessage
                          name={sender.display as string}
                          date={sent ? new Date(sent) : undefined}
                          grouped={previousMssg?.sender?.id === sender?.id}
                          own={sender?.id === user.id}
                          message={payload?.[0]?.content?.string ?? ""}
                          className={classNames({ "bubble-thread-close": nextMssg?.sender?.id !== sender?.id })}
                          thread_close={nextMssg?.sender?.id !== sender?.id}
                          sended={status === "in-progress"}
                          received={status === "completed"}
                          avatar={sender.photo}
                        />
                      </Fragment>
                    )
                  })}
                </div>
              ))}
            </InfiniteScroll>
            <div ref={refBottom} />
          </>
        )}
      </div>
      <div className="w-full mt-2 row-span-1">
        <ChatInput
          sendMessage={(mssg) =>
            addMessage({
              status: "in-progress",
              sent: new Date().toISOString(),
              sender: user,
              payload: [{ content: { string: mssg } }],
              recipient: [{ id: managingOrganizationId, resourceType: "Organization" }],
              category: [
                {
                  coding: [
                    {
                      system: "http://terminology.hl7.org/CodeSystem/communication-category",
                      code: "message",
                    },
                  ],
                },
              ],
            })
          }
        />
      </div>
    </div>
  )
}

export { CommunicationContainer }
