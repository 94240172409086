import { Patient, Address, ResourceObject } from "fhir"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { commonsQueryKeys, CustomError } from "commons"

const useUpdatePatientShippingAddress = (onSuccess?: () => void) => {
  const queryClient = useQueryClient()
  const { patch } = useClient()

  const updatedPatient = async ({ address, patient }: { address: Address; patient: Patient }) => {
    address.use = "home"
    address.type = "postal"
    let newAddress = [address]

    if (patient.address) {
      newAddress = [...(patient?.address as Address[]), address]
      const index = patient.address?.findIndex((address) => address.type === "postal")
      if (index !== -1 && patient?.address) {
        newAddress = [...patient.address.slice(0, index), address, ...patient.address.slice(index + 1)]
      }
    }

    return patch("Patient", patient.id as string, { address: newAddress, meta: patient.meta } as ResourceObject)
  }

  const { mutate: updateShippingAddress, isPending: isUpdating } = useMutation({
    mutationFn: updatedPatient,
    onError: (error: CustomError, context) => {
      if (!!error.cause?.name && ["409", "412"].includes(error.cause.name)) {
        // Conflict error or precondition failed error are thrown when the resource has been modified by someone
        queryClient.invalidateQueries({ queryKey: commonsQueryKeys.loggedIn(context.patient.id) })
      }
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (_, { patient }) => {
      await queryClient.invalidateQueries({ queryKey: commonsQueryKeys.loggedIn(patient.id) })
      await queryClient.invalidateQueries({ queryKey: ["logged-user-patients"] })

      onSuccess?.()
    },
  })

  return { updateShippingAddress, isUpdating }
}

export { useUpdatePatientShippingAddress }
