import { MedicationRequestDispenseRequest } from "fhir"
import { useMutation } from "@tanstack/react-query"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { CustomError } from "commons"
import { registerErrorTrace } from "logger"

import { sanitizeDispenseRequest } from "../components/validations"

const useUpdateMedicationRequestsDispenseInterval = (onSuccess?: () => void) => {
  const { patch } = useClient()

  const updateDispenseInterval = async (medicationRequest: {
    mrId: string
    dispenseRequest: MedicationRequestDispenseRequest
  }) => {
    return patch("MedicationRequest", medicationRequest.mrId, {
      dispenseRequest: sanitizeDispenseRequest(medicationRequest.dispenseRequest),
      resourceType: "MedicationRequest",
    })
  }

  const { mutate: updateMRDispenseInterval, isPending: isUpdating } = useMutation({
    mutationFn: updateDispenseInterval,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: onSuccess,
  })

  return { updateMRDispenseInterval, isUpdating }
}

export { useUpdateMedicationRequestsDispenseInterval }
