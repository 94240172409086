import { FC } from "react"
import { Duration } from "fhir"

import { CheckoutSection } from "./CheckoutSection"
import { ACTION_GROUP_CODES, CpoeRequest } from "../types"

const OrderList: FC<Props> = ({ requests, editable, onChangeMed, onDelete, mrToDelete }) => (
  <div className="flex flex-col space-y-0 xl:space-y-1 grow overflow-y-auto">
    <CheckoutSection
      requests={requests}
      label="Nutraceuticals"
      sectionRequestsType={ACTION_GROUP_CODES.NUTRA}
      onChangeMed={onChangeMed}
      editableRequests={editable}
      onDelete={onDelete}
      mrToDelete={mrToDelete}
    />
  </div>
)

type Props = {
  requests: CpoeRequest[]
  editable: boolean
  onChangeMed?(field: string, value: boolean | Duration, id?: string): void
  onDelete?(requestId: string): void
  mrToDelete?: string
}

export default OrderList
