import { codeableConceptAsString, Task } from "fhir"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarDays, faCalendarCheck } from "@fortawesome/pro-solid-svg-icons"
import { Chip } from "primereact/chip"
import { classNames } from "primereact/utils"
import { parseISO } from "date-fns"
import { useNavigate, createSearchParams, useParams } from "react-router-dom"

import { formatDate, getSubviewPath } from "utils"
import { CardListItem } from "commons"
import { BillingTypeCodes, formatsByTypes } from "data"

const TaskListItem = ({ task, billingType }: Props) => {
  const { patientId } = useParams()
  const navigate = useNavigate()

  const isAllowedToSeeInvoice = ![BillingTypeCodes.BILL_PRACTICE, BillingTypeCodes.INSURANCE].includes(billingType)

  const navigateTo = (code: string) => {
    switch (code) {
      case TaskType.PAY_INVOICE:
        return navigate({
          pathname: getSubviewPath(patientId as string, "invoices"),
          search: `?${createSearchParams({ invoiceId: task.focus?.id as string })}`,
        })
      case TaskType.COMPLETE_SM:
        return (
          task.status === "failed" ??
          navigate({ pathname: getSubviewPath(patientId as string, "profile"), search: "?section=credit_cards" })
        )
      default:
        return {}
    }
  }
  const getStatusColor = (status: string) => {
    return {
      "is-info": status === "ready",
      "is-secondary": status === "requested",
      "is-warning": status === "on-hold",
      "is-draft": status === "entered-in-error",
      "is-success": status === "completed",
      "is-danger": status === "cancelled",
    }
  }

  const getTaskDate = (task: Task) => {
    const startDate = task.restriction?.period?.start
    if (!startDate) return "Not specified date"
    const endDate = task.restriction?.period?.end
    return `${taskDateToString(startDate)}${endDate ? ` - ${taskDateToString(endDate)}` : ""}`
  }

  const taskDateToString = (fieldDate: string) =>
    formatDate(
      parseISO(fieldDate.replace("Z", "")),
      fieldDate.includes("T") ? formatsByTypes.LONG_DATETIME : formatsByTypes.LONG_DATE,
    )

  const Head = (
    <>
      {task.code && (
        <p title="Code" className="whitespace-nowrap overflow-hidden text-ellipsis">
          {codeableConceptAsString(task.code)}
        </p>
      )}
      {task.status && (
        <span title="Status">
          <Chip
            label={task.status}
            className={classNames("ml-2 custom-chip custom-chip", getStatusColor(task.status))}
          />
        </span>
      )}
    </>
  )

  const Details = (
    <div className="flex flex-col md:flex-row">
      <span title="Authored on">
        <FontAwesomeIcon icon={faCalendarDays} className="mr-1.5 text-gray-400" />
        {taskDateToString(task.authoredOn as string)}
      </span>
      <span title="Scheduled for" className="md:pl-4">
        <FontAwesomeIcon icon={faCalendarCheck} className="mr-1.5 text-gray-400" />
        {getTaskDate(task)}
      </span>
    </div>
  )

  return (
    <CardListItem
      contentHeader={Head}
      contentSubheader={Details}
      onContentCliked={isAllowedToSeeInvoice ? () => navigateTo(task.code?.coding?.[0].code ?? "") : undefined}
      className={classNames(
        isAllowedToSeeInvoice &&
          (task.code?.coding?.[0].code === TaskType.PAY_INVOICE ||
            (task.code?.coding?.[0].code === TaskType.COMPLETE_SM && task.status === "failed")) &&
          "cursor-pointer",
      )}
    />
  )
}

type Props = {
  task: Task
  billingType: BillingTypeCodes
}

const TaskType = {
  PAY_INVOICE: "pay-invoice",
  COMPLETE_SM: "complete-shipping-method",
}

export { TaskListItem }
