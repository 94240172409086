import { useQuery } from "@tanstack/react-query"
import { Bundle, ChargeItemDefinition, getResources, Parameters } from "fhir"

import { useClient } from "api"
import { getIndexedCID } from "utils"

import { commonsQueryKeys } from "../query-keys"
import { GroupedChargeItemDefinitionsCodes } from "../types"
import { getParamsSkuCodesByQty } from "../utils"

const useChargeItemDefinitions = (organizationId: string, codes?: GroupedChargeItemDefinitionsCodes) => {
  const getChargeItemDefinitions = useCIDQueryFunction()
  const queryKey = commonsQueryKeys.chargeItemsDefinitions(organizationId, codes)

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async () => getChargeItemDefinitions(organizationId, codes),
    enabled: !!codes?.billToPracticeOrInsuranceCIDs?.length || !!codes?.billToPatientCIDs?.length,
    meta: { context: { queryKey, codes } },
  })

  return {
    isLoading,
    chargeItemDefinitions: data ?? { billToPatientCIDs: {}, billToPracticeOrInsuranceCIDs: {} },
  }
}

const useCIDQueryFunction = () => {
  const { operationRequest } = useClient()

  return async (organizationId: string, codes?: GroupedChargeItemDefinitionsCodes) => {
    const billToPracticeOrInsuranceCIDsPart = codes?.billToPracticeOrInsuranceCIDs?.length
      ? getParamsSkuCodesByQty(codes?.billToPracticeOrInsuranceCIDs)
      : []
    const billToPatientCIDsPart = codes?.billToPatientCIDs?.length
      ? [
          ...getParamsSkuCodesByQty(codes?.billToPatientCIDs),
          {
            name: "order-type",
            value: {
              string: "bill-patient",
            },
          },
        ]
      : []

    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "order-info",
          part: billToPracticeOrInsuranceCIDsPart,
        },
        {
          name: "order-info",
          part: billToPatientCIDsPart,
        },
      ],
    }

    const bundle: Parameters = await operationRequest<Parameters>({
      endpoint: `Organization/${organizationId}`,
      method: "POST",
      operation: "product-prices",
      parameters,
    })

    let billToPracticeOrInsuranceCIDs: ChargeItemDefinition[] = []

    if (bundle?.parameter?.[0]) {
      billToPracticeOrInsuranceCIDs = getResources<ChargeItemDefinition>(
        bundle?.parameter?.[0]?.resource as Bundle,
        "ChargeItemDefinition",
      )
    }

    let billToPatientCIDs: ChargeItemDefinition[] = []

    if (bundle?.parameter?.[1]) {
      billToPatientCIDs = getResources<ChargeItemDefinition>(
        bundle?.parameter[1].resource as Bundle,
        "ChargeItemDefinition",
      )
    }

    return {
      billToPracticeOrInsuranceCIDs: getIndexedCID(billToPracticeOrInsuranceCIDs),
      billToPatientCIDs: getIndexedCID(billToPatientCIDs),
    }
  }
}

export { useChargeItemDefinitions, useCIDQueryFunction }
