import { useQuery } from "@tanstack/react-query"

import { displayNotificationWarningUpdateAvailable } from "utils"

const useCheckNewReleaseAvailable = () => {
  const currentDomain = window.location.origin
  const queryKey = ["check-new-realease-available", currentDomain]
  const isDevelopment = process.env.NODE_ENV === "development"

  useQuery({
    queryKey,
    queryFn: async () => {
      const response = await fetch(`${currentDomain}/env-vars.js`, {
        cache: "no-store",
        headers: {
          Origin: currentDomain,
        },
      }).then((res) => res.text())

      const currentAppVersion = window?.VITE_APP_VERSION ?? ""

      const versionMatch = response.match(/window\.VITE_APP_VERSION="([\d.]+)";/)
      const newAppVersion = versionMatch?.[1] ?? ""

      const isNewAvailableVersion = currentAppVersion !== newAppVersion

      isNewAvailableVersion &&
        displayNotificationWarningUpdateAvailable(
          "An updated version of the application is available. Update to get the latest version.",
        )

      return null
    },
    meta: { context: { queryKey } },
    refetchInterval: 240000,
    enabled: !isDevelopment,
  })
}

export { useCheckNewReleaseAvailable }
