import { useContext } from "react"

import { registerErrorTrace } from "logger"
import { CustomError } from "commons"

import { CPOERequestsContext } from "../context/CPOERequestsProvider"

const useCPOERequestsContext = () => {
  const context = useContext(CPOERequestsContext)

  if (context === undefined) {
    throw registerErrorTrace(new Error("Couldn't create CPOERequests context") as CustomError)
  }

  return context
}

export { useCPOERequestsContext }
