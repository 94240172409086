import { useMutation } from "@tanstack/react-query"
import { DocumentReference } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { openLinkInNewTab, sanitizeURL } from "utils"

const useConsentDocument = () => {
  const { operationRequest, getSignedUrl } = useClient()

  const getConsentDR = async (consentId: string) =>
    operationRequest<DocumentReference>({
      endpoint: "Consent",
      method: "GET",
      operation: "waiver-signed",
      id: consentId,
    })

  const { mutate, isPending } = useMutation({
    mutationFn: getConsentDR,
    onError: (error: CustomError, consentId) => {
      displayNotificationError(registerErrorTrace(error, { consentId }))
    },
    onSuccess: async (data) => {
      if (data.content[0].attachment.url) {
        const url = data.content[0].attachment.url.startsWith("/")
          ? data.content[0].attachment.url.slice(1)
          : data.content[0].attachment.url
        const signedUrl = await getSignedUrl(sanitizeURL(url))
        if (signedUrl.url) {
          openLinkInNewTab(signedUrl.url)
        }
      } else {
        displayNotificationError(new Error("Wrong consent url") as CustomError)
      }
    },
  })

  return { getConsentDocument: mutate, isGettingDR: isPending }
}

export { useConsentDocument }
