import { ERROR_CAUSE } from "data"
import { useAuth } from "security"
import { CustomError } from "commons"

const ForbiddenErrorView = ({ error }: { error?: CustomError }) => {
  const { logout } = useAuth()

  setTimeout(() => {
    logout()
  }, 1000)

  return (
    <div className="flex items-center justify-center w-full h-full">
      <div className="text-center">
        <p className="p-error text-5xl">403</p>
        <h1 className="text-4xl py-2 font-bold">{ERROR_CAUSE.FORBIDDEN}</h1>
        <p className="text-gray-400 py-2 pb-4">You are about to be logged out, your session is expired</p>
        {error && <p className="text-sm text-gray-400 py-2 pb-4">{error?.cause?.message}</p>}
      </div>
    </div>
  )
}

export { ForbiddenErrorView }
