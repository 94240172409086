import { Patient } from "fhir"
import { createContext, FC, ReactNode } from "react"
import { useParams } from "react-router-dom"

import { useLoggedInPatient } from "patient"

const LoginContext = createContext<State | undefined>(undefined)
LoginContext.displayName = "LoginContext"

const LoginProvider: FC<Props> = ({ children }) => {
  const { patientId } = useParams()
  const { loggedInPatient, isLoading, error } = useLoggedInPatient(patientId)

  if (error) {
    throw error
  }

  return (
    <LoginContext.Provider
      value={{
        loggedInPatient,
        loggedInPatientId: loggedInPatient.id ?? "",
        managingOrganizationId: loggedInPatient.managingOrganization?.id ?? "",
        isLoadingPatient: isLoading,
      }}
    >
      {children}
    </LoginContext.Provider>
  )
}

type State = {
  loggedInPatient: Patient
  loggedInPatientId: string
  managingOrganizationId: string
  isLoadingPatient: boolean
}

type Props = {
  children: ReactNode
}

export { LoginProvider, LoginContext }
