import { faEdit } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { classNames } from "primereact/utils"
import { Link } from "react-router-dom"

const ProfileEmailField = ({ label, email, className, horizontal, floatLabel }: Props) => (
  <div
    className={classNames(
      "field relative",
      {
        "inline-flex justify-between": horizontal,
        "float-label relative block mt-1": floatLabel,
        "flex flex-col": !horizontal && !floatLabel,
      },
      className,
    )}
  >
    {label && !floatLabel && (
      <label className={classNames("text-sm font-medium text-gray-700 mb-2", { "mr-3 mb-0 mt-2": horizontal })}>
        {label}
      </label>
    )}

    <div className="flex  items-center border-solid border-2 border-slate-200/90 rounded-md p-2.5 text-xs ">
      <span className="mr-2 opacity-60 flex flex-grow ">{email}</span>
      <Link to={`${window.VITE_APP_AUTH_URL}/edit-email?redirectUrl=${window.location.href}`}>
        <div className="hover:text-gray-500 text-primary cursor-pointer">
          <span>Edit email </span>
          <FontAwesomeIcon icon={faEdit} className="text-base ml-1" />
        </div>
      </Link>
    </div>

    {label && floatLabel && (
      <label className="text-sm text-gray-400 left-3 top-2 absolute transition-all ease-in-out">{label}</label>
    )}
  </div>
)

type Props = {
  label?: string
  email?: string
  className?: string
  horizontal?: boolean
  floatLabel?: boolean
}

export { ProfileEmailField }
