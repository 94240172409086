const invoicesKeys = {
  all: ["patient/invoice"] as const,
  withPatientId: (patientId: string) => [...invoicesKeys.all, patientId] as const,
  withStatusFilter: (patientId: string, statusFilter: string[]) =>
    [...invoicesKeys.withPatientId(patientId), statusFilter] as const,
  withStatusAndSearchFilter: (patientId: string, statusFilter: string[], searchFilter?: string) =>
    [...invoicesKeys.withStatusFilter(patientId, statusFilter), searchFilter] as const,
}

export { invoicesKeys }
