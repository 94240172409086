import { Fragment, ReactNode } from "react"

const GroupedList = <T,>({ groups, renderItem, renderDivider, renderEmptyState }: Props<T>) => {
  if (!groups.length) {
    return (
      <>
        {renderEmptyState?.() ?? (
          <div className="px-4 py-2">
            <p>No items found</p>
          </div>
        )}
      </>
    )
  }

  return (
    <nav>
      {groups.map(({ key, name, items }) => (
        <div key={key} className="relative z-0">
          {renderDivider ? renderDivider(name) : <DefaultDivider name={name} />}
          <ul className="@container relative z-0 divide-y divide-gray-200">
            {items.map((item, index) => (
              <Fragment key={index}>{renderItem(item)}</Fragment>
            ))}
          </ul>
        </div>
      ))}
    </nav>
  )
}

const DefaultDivider = ({ name }: Pick<ListGroup, "name">) => (
  <div className="sticky top-0 z-10 border-t border-b border-gray-200 bg-gray-50 px-6 py-1 text-sm font-medium text-gray-500">
    <h3>{name}</h3>
  </div>
)

type Props<T = unknown> = {
  className?: string
  groups: ListGroup<T>[]
  renderItem(item: T): ReactNode
  renderDivider?(name: string): ReactNode
  renderEmptyState?(): ReactNode
}

export type ListGroup<T = unknown> = {
  key: string
  name: string
  items: T[]
}

export { GroupedList }
