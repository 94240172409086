import { faCalendarCheck } from "@fortawesome/pro-regular-svg-icons"
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { DiagnosticReport } from "fhir"
import { classNames } from "primereact/utils"
import { FC, useState } from "react"
import { format } from "date-fns/format"

import { formatsByTypes } from "data"
import { useShowSignedUrlDocument } from "commons"
import { openLinkInNewTab } from "utils"

const LaboratoryOrderPreviousResults: FC<Props> = ({ previousResults }) => {
  const [expandedAct, setExpandedAct] = useState(false)

  const { showDocument } = useShowSignedUrlDocument(openLinkInNewTab)

  return (
    <div className="border p-4 rounded-lg transition-all ease-in-out duration-1000">
      <div className="flex justify-between">
        <label>Activity</label>
        <div className="flex gap-4 text-gray-900 cursor-pointer" onClick={() => setExpandedAct(!expandedAct)}>
          <span className="text-sm text-gray-300">{expandedAct ? "Show less" : "Show more"} </span>
          <FontAwesomeIcon icon={expandedAct ? faChevronUp : faChevronDown} />
        </div>
      </div>

      <div className={classNames("text-gray-400", { hidden: !expandedAct })}>
        <ul className=" mt-3  grid lg:grid-cols-2 2xl:grid-cols-3 whitespace-nowrap">
          {previousResults.map((dr) => {
            const releasedDate =
              dr.released && format(new Date(dr.released), formatsByTypes.SHORT_DATETIME_WITH_TIMEZONE)
            return (
              <li key={dr.id} className="mb-1 p-1 last:mb-3 inline-flex">
                <div
                  className="text-sm text-gray-500 underline cursor-pointer hover:text-primary-hover"
                  title="See results"
                  onClick={() => showDocument(dr.presentedForm?.[0]?.url ?? "")}
                >
                  {dr.status === "final" ? "Final" : "Partial"} results received:{" "}
                  {format(new Date(dr.issued as Date), formatsByTypes.SHORT_DATETIME_WITH_TIMEZONE)}
                </div>

                {releasedDate && (
                  <FontAwesomeIcon
                    icon={faCalendarCheck}
                    className="hover:text-primary-hover cursor-pointer ml-1 text-gray-500"
                    title={`Released on ${releasedDate}`}
                  />
                )}
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

type Props = {
  previousResults: DiagnosticReport[]
}

export { LaboratoryOrderPreviousResults }
