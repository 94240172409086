import { useMutation } from "@tanstack/react-query"
import { Bundle, getResource, getResources, Invoice, ServiceRequest } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

const useCpoeOrdersFinish = (
  patientId: string,
  refreshCpoeCount: () => void,
  onSuccess: (srId: string, invoice?: Invoice) => void,
) => {
  const { operationRequest } = useClient()

  const finish = async () => {
    const bundle = await operationRequest<Bundle>({
      endpoint: `Patient/${patientId}/cpoe`,
      method: "POST",
      operation: "finish",
    })
    const sr = getResources<ServiceRequest>(bundle, "ServiceRequest")
    const invoice = getResource<Invoice>(bundle, "Invoice")

    return { serviceRequestId: sr?.[0]?.id, invoice }
  }

  const { mutate: ordersFinish, isPending: isFinishing } = useMutation({
    mutationFn: finish,
    onSuccess: (data) => {
      displayNotificationSuccess("Order processed successfully!")
      refreshCpoeCount()
      data?.serviceRequestId && onSuccess(data.serviceRequestId, data.invoice)
    },
    onError: (error: CustomError, patientId) => {
      displayNotificationError(registerErrorTrace(error, { finish: "CPOE finish", patientId }))
    },
  })

  return { ordersFinish, isFinishing }
}

export { useCpoeOrdersFinish }
