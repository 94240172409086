import { useQuery } from "@tanstack/react-query"
import { Coverage, getResources } from "fhir"

import { useClient } from "api"

import { coverageKeys } from "../query-keys"

const useCoverages = (patientId: string) => {
  const { search } = useClient()
  const queryKey = coverageKeys.withPatientId(patientId)

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async () => {
      const filters = new URLSearchParams({
        _count: "20",
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/Coverage`, filters })

      const coverages = getResources<Coverage>(bundle, "Coverage")

      return { coverages }
    },
    meta: { context: { queryKey } },
  })

  return {
    isLoading,
    coverages: data?.coverages ?? [],
  }
}

export { useCoverages }
