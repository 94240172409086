import { InputMask } from "primereact/inputmask"
import { classNames } from "primereact/utils"
import { FieldProps } from "formik"
import { FC } from "react"

import { FormField, FormFieldBaseProps } from "./FormField"

const PhoneField: FC<Props> = ({ inputClassName, disabled, ...fieldProps }) => (
  <FormField {...fieldProps}>
    {({ field: { name, value, onChange }, meta: { touched, error } }: FieldProps) => (
      <div className={classNames("flex items-center relative", inputClassName)}>
        <InputMask
          type="tel"
          mask="(999) 999-9999"
          unmask
          id={name}
          name={name}
          onChange={onChange}
          value={value}
          disabled={disabled}
          className={classNames("grow p-inputtext-sm pl-7", { "p-invalid": touched && error })}
        />
        <span className="absolute left-0 ml-2 text-xs">+1</span>
      </div>
    )}
  </FormField>
)

type Props = FormFieldBaseProps<string> & {
  inputClassName?: string
  disabled?: boolean
}

export { PhoneField }
