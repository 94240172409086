import OT from "@opentok/client"
import { HubConnection } from "@microsoft/signalr"
import { Dispatch, FC, PropsWithChildren, SetStateAction, createContext, useEffect, useRef, useState } from "react"
import { useSignalR } from "signalR"

import { TVideoStates, useVideoCall, useVonageAPIIntegration } from "../hooks"
import { KeysNames, NotificationVCType } from "../data"
import { TNotificationVCType, TSettings, TSubscriberConnection } from "../types"
import { useLoginContext } from "security"

const VideoCallContext = createContext({} as State)

const VideoCallProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isInterviewStarted, setIsInterviewStarted] = useState(false)
  const [notificationType, setNotificationType] = useState<TNotificationVCType | undefined>(undefined)
  const [recieveVC, setRecieveVC] = useState<TRecieveVCResponse>({} as TRecieveVCResponse)
  const [destroyedSession, setDestroyedSession] = useState<string | undefined>(undefined)
  const { practitionerId, sessionId } = recieveVC
  const hasRecieveVC = !!Object.keys(recieveVC).length
  const browserNotSupportedPIP = !("pictureInPictureElement" in document) || !document.pictureInPictureEnabled
  const [browserNotSupportedShareScreen, setBrowserNotSupportedShareScreen] = useState(false)
  const [warningBeforeUnload, setWarningBeforeUnload] = useState(false)
  const { loggedInPatientId: patientId } = useLoginContext()

  const { connection } = useSignalR({
    url: `${window.VITE_APP_SIGNALR_CALL_NOTIFICATION}?role=patient&patientId=${patientId}`,
  })

  const {
    initializeSession,
    stopStreaming,
    toggleAudio,
    toggleAudioSubscribtion,
    toggleVideo,
    toggleVideoSubscribtion,
    handlePictureInPicture,
    setIsPictureInPictureActive,
    isPictureInPictureActive,
    videoStates,
    subscriberSettings,
    subscriberConnection,
    shareScreen,
    endShareScreen,
  } = useVonageAPIIntegration()
  const {
    joinVideoCall,
    joinIsPending,
    rejectVideoCall,
    rejectIsPending,
    finishVideoCall,
    finishIsPending,
    pendingActiveVC,
  } = useVideoCall({
    join(keysDicc) {
      initializeSession({
        apiKey: keysDicc[KeysNames.applicationId]?.value?.string ?? "",
        sessionId: keysDicc[KeysNames.sessionId]?.value?.string ?? "",
        token: keysDicc[KeysNames.accessToken]?.value?.string ?? "",
      })

      setIsInterviewStarted(true)
    },
    reject() {
      setRecieveVC({} as TRecieveVCResponse)
      setNotificationType(undefined)
    },
    finish() {
      if (warningBeforeUnload) {
        location.reload()
        setWarningBeforeUnload(false)
      }
    },
  })

  const handleCancelVCEvent = () =>
    connection?.on("CancelVideoCall", (data: { sessionId: string }) => {
      data.sessionId && setNotificationType(NotificationVCType.error)
    })

  const handleDestroyedVCEvent = () =>
    connection?.on("FinishVideoCall", (data: { sessionId: string }) => {
      setDestroyedSession((prevId) => (prevId !== data.sessionId ? data.sessionId : prevId))
    })

  const handleRecieveVCEvent = () =>
    connection?.on("ReceiveVideoCall", (data: TRecieveVCResponse) => {
      setRecieveVC(data)
      setNotificationType(NotificationVCType.success)
    })

  const handleJoinCall = () => hasRecieveVC && joinVideoCall({ patientId, sessionId })

  const handleStopStreaming = () => {
    if (isPictureInPictureActive) {
      document.exitPictureInPicture()
      setIsPictureInPictureActive(false)
    }

    !!videoStates?.subscriber && !destroyedSession && finishVideoCall({ patientId, sessionId })

    destroyedSession && setDestroyedSession(undefined)

    setIsInterviewStarted(false)

    stopStreaming()
  }

  const handleHangUpVC = () => hasRecieveVC && rejectVideoCall({ patientId, practitionerId, sessionId })

  const pendingActiveVCCalled = useRef(false)
  const callPendingActiveVC = (called: boolean) => {
    if (!called) pendingActiveVC({ patientId }), (pendingActiveVCCalled.current = true)
  }

  const sendPatientStatus = () =>
    setInterval(() => connection?.invoke("SendPatientStatus", patientId, "connected"), 2500)

  useEffect(() => {
    if (connection) {
      handleRecieveVCEvent()
      handleCancelVCEvent()
      handleDestroyedVCEvent()
      callPendingActiveVC(pendingActiveVCCalled.current)
      sendPatientStatus()
    }
  }, [connection])

  useEffect(() => {
    OT.checkScreenSharingCapability((response) =>
      setBrowserNotSupportedShareScreen(!response.supported || response.extensionRegistered === false),
    )
  }, [])

  useEffect(() => endShareScreen(!!destroyedSession), [destroyedSession, videoStates])

  const isPending = { join: joinIsPending, reject: rejectIsPending, finish: finishIsPending }

  const value = {
    recieveVC,
    isPending,
    handleStopStreaming,
    toggleAudio,
    toggleAudioSubscribtion,
    toggleVideo,
    toggleVideoSubscribtion,
    handlePictureInPicture,
    destroyedSession,
    isPictureInPictureActive,
    subscriberConnection,
    videoStates,
    subscriberSettings,
    handleJoinCall,
    isInterviewStarted,
    handleHangUpVC,
    connection,
    notificationType,
    browserNotSupportedPIP,
    browserNotSupportedShareScreen,
    shareScreen,
    warningBeforeUnload,
    setWarningBeforeUnload,
    setNotificationType,
  }

  return <VideoCallContext.Provider value={value}>{children}</VideoCallContext.Provider>
}

type State = {
  recieveVC: TRecieveVCResponse
  isPending?: { join?: boolean; reject?: boolean; finish?: boolean }
  handleStopStreaming: () => void
  toggleVideo: (state: boolean) => void | undefined
  toggleAudio: (state: boolean) => void | undefined
  toggleAudioSubscribtion: (state: boolean) => void | undefined
  toggleVideoSubscribtion: (state: boolean) => void | undefined
  handlePictureInPicture: () => void
  isPictureInPictureActive: boolean
  destroyedSession?: string
  subscriberConnection: TSubscriberConnection
  videoStates: TVideoStates | null
  subscriberSettings: TSettings
  handleJoinCall: () => void
  handleHangUpVC: () => void
  isInterviewStarted: boolean
  connection?: HubConnection
  notificationType?: TNotificationVCType
  browserNotSupportedPIP: boolean
  browserNotSupportedShareScreen: boolean
  shareScreen: () => void
  warningBeforeUnload: boolean
  setWarningBeforeUnload: Dispatch<SetStateAction<boolean>>
  setNotificationType: Dispatch<SetStateAction<TNotificationVCType | undefined>>
}

type TRecieveVCResponse = {
  patientId: string
  sessionId: string
  userId: string
  practitionerFullName: string
  practitionerId: string
}

export { VideoCallContext, VideoCallProvider }
