import { unitOfTime } from "data"

import { DispenseIntervalOption } from "./types"

const dispenseInterval: DispenseIntervalOption[] = [
  {
    label: "One time",
    value: {
      value: 0,
      code: unitOfTime[3].code,
      system: unitOfTime[3].system,
      unit: unitOfTime[3].display.toLowerCase(),
    },
  },
  {
    label: "Monthly",
    value: {
      value: 1,
      code: unitOfTime[5].code,
      system: unitOfTime[5].system,
      unit: unitOfTime[5].display.toLowerCase(),
    },
  },
  {
    label: "Every 2 months",
    value: {
      value: 2,
      code: unitOfTime[5].code,
      system: unitOfTime[5].system,
      unit: unitOfTime[5].display.toLowerCase(),
    },
  },
  {
    label: "Every 3 months",
    value: {
      value: 3,
      code: unitOfTime[5].code,
      system: unitOfTime[5].system,
      unit: unitOfTime[5].display.toLowerCase(),
    },
  },
  {
    label: "Every 4 months",
    value: {
      value: 4,
      code: unitOfTime[5].code,
      system: unitOfTime[5].system,
      unit: unitOfTime[5].display.toLowerCase(),
    },
  },
  {
    label: "Every 6 months",
    value: {
      value: 6,
      code: unitOfTime[5].code,
      system: unitOfTime[5].system,
      unit: unitOfTime[5].display.toLowerCase(),
    },
  },
]

export { dispenseInterval }
