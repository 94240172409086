import { faAddressBook, faEnvelope, faMobile } from "@fortawesome/pro-regular-svg-icons"
import { faPlus } from "@fortawesome/pro-solid-svg-icons"
import { PatientContactArray } from "fhir"
import { codeableConceptAsString, getFirstPhone, humanNameAsString } from "fhir/utils"

import {
  Button,
  ConfirmDialog,
  DataContainerSlideoverForm,
  InformationCardContainer,
  SkeletonLoader,
  StackedListContainer,
  StackedListItemProps,
  useCrudReducer,
} from "commons"
import { ProfileSection } from "data"
import { useLoginContext } from "security"
import { strCapitalize } from "utils"
import { defaultEditRemoveMenu } from "utils-renders"

import { usePatchPatient } from "../hooks"
import { PatientContactForm } from "./PatientContactForm"
import { CONTACT_INITIAL_VALUES, contactValidationSchema, sanitizeContact } from "./validations"

const ContactsInformation = () => {
  const {
    loggedInPatient: { contact, meta },
    loggedInPatientId,
  } = useLoginContext()
  const { isNew, showSlide, initialValue, deleteIndex, editIndex, add, editWithIndex, reset, setDeleteIndex } =
    useCrudReducer({
      defaultEntity: CONTACT_INITIAL_VALUES,
    })
  const { patchPatient, isPatching } = usePatchPatient(() => {
    reset()
  })

  const onSubmit = (patientContact: PatientContactArray) => {
    let newContacts = contact ?? []
    if (isNew) newContacts = [...newContacts, sanitizeContact(patientContact)]
    else newContacts.splice(editIndex as number, 1, sanitizeContact(patientContact))
    patchPatient({
      patientId: loggedInPatientId,
      patientData: { contact: newContacts, meta },
      successMsg: `Contact ${isNew ? "added" : "updated"} successfully!`,
    })
  }

  const onDelete = () => {
    const newContacts = [...(contact ?? [])]
    newContacts.splice(deleteIndex as number, 1)
    patchPatient({
      patientId: loggedInPatientId,
      patientData: { contact: newContacts, meta },
      successMsg: "Contact deleted successfully!",
    })
  }

  return (
    <InformationCardContainer
      id={ProfileSection.EMERGENCY_CONTACT}
      title="Emergency Contacts"
      showEdit={!!contact?.length}
      customButton={<Button buttonStyle="default" label="Add new" icon={faPlus} onClick={add} />}
      className="profile-card-section"
    >
      {isPatching ? (
        <SkeletonLoader repeats={2} loaderType="two-lines" />
      ) : (
        <DataContainerSlideoverForm
          hasData={!!contact?.length}
          showSlide={showSlide}
          formTitle="Contact"
          iconDataNotFound={faAddressBook}
          formInitialValue={initialValue}
          validationSchema={contactValidationSchema}
          onSubmit={onSubmit}
          onCancel={reset}
          form={<PatientContactForm />}
          showAddButton={false}
          onButtonAddClick={add}
        >
          <StackedListContainer
            data={contact ?? []}
            itemModelBuilder={(item, index) =>
              contactsModel(
                item,
                () => editWithIndex(item, index),
                () => setDeleteIndex(index),
              )
            }
          />
          <ConfirmDialog
            confirmText="Are you sure you want to remove this contact?"
            actionName="Remove"
            visible={deleteIndex !== undefined}
            onConfirm={onDelete}
            hideDialog={() => setDeleteIndex(undefined)}
          />
        </DataContainerSlideoverForm>
      )}
    </InformationCardContainer>
  )
}

const contactsModel = (
  contact: PatientContactArray,
  onEdit: () => void,
  onDelete: () => void,
): StackedListItemProps => ({
  leftData: [
    {
      lineItems: [
        { name: "Name", value: humanNameAsString(contact.name) },
        { name: "Relationship", value: codeableConceptAsString(contact.relationship?.[0]) },
      ],
    },
    {
      lineItems: [
        ...(contact.telecom?.[0]
          ? [
              {
                name: strCapitalize(contact.telecom[0].system as string),
                value: contact.telecom[0].value,
                icon: faEnvelope,
              },
            ]
          : []),
        ...(contact.telecom?.[1]
          ? [
              {
                name: strCapitalize(contact.telecom[1].system as string),
                value: getFirstPhone(contact.telecom),
                icon: faMobile,
              },
            ]
          : []),
        {
          name: strCapitalize(contact.gender as string),
        },
      ],
    },
  ],
  menu: defaultEditRemoveMenu({ onEdit, onDelete }),
})

export { ContactsInformation }
