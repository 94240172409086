import { useMutation, useQueryClient } from "@tanstack/react-query"
import { List, Parameters } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { medicationOrderKeys } from "../query-keys"

const useCancelMrOrder = (onSettled?: () => void) => {
  const { operationRequest } = useClient()
  const queryClient = useQueryClient()

  const cancelOrder = ({
    patientId,
    serviceRequestId,
    cancelReason,
    stopSequence,
  }: {
    patientId: string
    serviceRequestId: string
    cancelReason: string
    stopSequence: boolean
  }) => {
    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "orders",
          resource: {
            resourceType: "List",
            mode: "working",
            status: "current",
            entry: [
              {
                item: {
                  resourceType: "ServiceRequest",
                  id: serviceRequestId,
                },
                flag: {
                  coding: [
                    {
                      code: stopSequence ? "stop" : "skip",
                      system: "http://chartedhealth.com/fhir/order/cancel-mode",
                    },
                  ],
                },
              },
            ],
          } as List,
        },
        {
          name: "reason",
          value: {
            string: cancelReason,
          },
        },
      ],
    }

    return operationRequest({ endpoint: `Patient/${patientId}/cpoe`, method: "POST", operation: "cancel", parameters })
  }

  const { mutate: cancelMrOrder, isPending } = useMutation({
    mutationFn: cancelOrder,
    onError: (error: CustomError, serviceRequestId) => {
      displayNotificationError(registerErrorTrace(error, { serviceRequestId }))
    },
    onSuccess: async (_, { serviceRequestId, patientId }) => {
      await queryClient.invalidateQueries({ queryKey: medicationOrderKeys.detail(serviceRequestId) })
      await queryClient.invalidateQueries({ queryKey: medicationOrderKeys.withPatientId(patientId) })

      displayNotificationSuccess("Order cancelled successfully!")
    },
    onSettled,
  })

  return { cancelMrOrder, isCancelling: isPending }
}

export { useCancelMrOrder }
