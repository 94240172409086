import { Money } from "fhir"
import { ErrorMessage, Field, FieldProps } from "formik"
import { Dropdown } from "primereact/dropdown"
import { classNames } from "primereact/utils"
import pluralize from "pluralize"

import { getMoneyCurrencyAlt } from "utils"

const MedicationUnitsDropdown = ({ field, pricePerUnit, unitName }: Props) => {
  const itemTemplate = (item: number) => (
    <span>
      {item} {pluralize(unitName ?? "unit", item)}
      {pricePerUnit && ` (${getMoneyCurrencyAlt(pricePerUnit.currency)}${pricePerUnit.value?.toFixed(2)} each)`}
    </span>
  )

  return (
    <Field name={field}>
      {({ field: { name, value, onChange }, meta: { touched, error } }: FieldProps) => (
        <div className="flex flex-col">
          <Dropdown
            id={name}
            name={name}
            options={Array.from({ length: 20 }, (_, i) => i + 1)}
            valueTemplate={itemTemplate}
            value={value}
            className={classNames("slashed", { "p-invalid": touched && error })}
            onChange={onChange}
          />
          <ErrorMessage name={field}>
            {(msg) => <small className="p-error absolute -bottom-6 ">{msg}</small>}
          </ErrorMessage>
        </div>
      )}
    </Field>
  )
}

type Props = {
  pricePerUnit?: Money
  unitName?: string
  field: string
}

export { MedicationUnitsDropdown }
