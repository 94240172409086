import { useQuery } from "@tanstack/react-query"
import { DocumentReference, getResources } from "fhir"

import { useClient } from "api"

import { monographKeys } from "../../../medication-requests/query-keys"

const useMonograph = (monographId: string | undefined) => {
  const { search, getSignedUrl } = useClient()
  const queryKey = monographKeys.detail(monographId)

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async () => {
      const filters = new URLSearchParams({ _id: `${monographId}` })

      const bundle = await search({ endpoint: "DocumentReference", filters })
      const monohraph = getResources<DocumentReference>(bundle)?.[0]

      const fullUrl = monohraph?.content[0].attachment.url?.startsWith("/")
        ? monohraph?.content[0].attachment.url.slice(1)
        : monohraph?.content[0].attachment.url

      if (fullUrl) {
        const signedUrl = await getSignedUrl(fullUrl)
        const newMonograph: DocumentReference = {
          ...monohraph,
          content: [
            {
              attachment: {
                url: signedUrl.url,
                title: monohraph.content[0].attachment.title,
              },
            },
          ],
        }
        return newMonograph
      }
      return monohraph
    },
    enabled: !!monographId,
    meta: { context: { queryKey, monographId } },
  })

  return { monograph: data, isLoading }
}

export { useMonograph }
