import { useQuery } from "@tanstack/react-query"
import { Composition, getResources } from "fhir"

import { useClient } from "api"

import { medicationCatalogsKeys } from "../query-keys"

const useMedicationCatalogs = (catalogIds: string[]) => {
  const { search } = useClient()
  const queryKey = medicationCatalogsKeys.all

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async () => {
      const filters = new URLSearchParams({
        type: "catalog",
        category: "medication",
        _id: catalogIds.join(","),
      })

      const bundle = await search({ endpoint: `Composition`, filters })

      const catalogs = getResources(bundle) as Composition[]

      return { catalogs, count: catalogs.length }
    },
    meta: { context: { queryKey } },
  })

  return {
    catalogs: data?.catalogs ?? [],
    count: data?.count ?? 0,
    isLoading,
  }
}

export { useMedicationCatalogs }
