import { useQuery } from "@tanstack/react-query"
import { asReference, getResources, Reference, RelatedPerson } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"

import { linkedCoveragesKeys } from "../query-keys"

const useRelatedPerson = (patientId: string) => {
  const { search } = useClient()
  const queryKey = linkedCoveragesKeys.withPatientId(patientId)

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async () => {
      const filters = new URLSearchParams({
        _count: "20",
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/RelatedPerson`, filters })

      const relatedPerson = getResources<RelatedPerson>(bundle, "RelatedPerson")

      return { relatedPerson }
    },
    meta: { context: { queryKey, patientId } },
  })

  const { personRefbyCode, prIds } = useMemo(() => {
    const personRefbyCode =
      data?.relatedPerson?.reduce<Record<string, Reference>>(
        (acc, person) => ({ ...acc, [person.relationship?.[0]?.coding?.[0]?.display as string]: asReference(person) }),
        {},
      ) ?? {}
    const prIds = data?.relatedPerson?.map((person) => person.id as string) ?? []

    return {
      personRefbyCode,
      prIds,
    }
  }, [data?.relatedPerson])

  return {
    relatedPeople: data?.relatedPerson ?? [],
    personRefbyCode,
    prIds,
    isLoading,
  }
}

export { useRelatedPerson }
