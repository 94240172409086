import { useMutation, useQueryClient } from "@tanstack/react-query"
import { RelatedPerson } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { linkedCoveragesKeys, relatedPersonKeys } from "../query-keys"

const useCreateRelatedPerson = (onSuccess?: (r: RelatedPerson) => void, onSettled?: () => void) => {
  const { create, update } = useClient()
  const queryClient = useQueryClient()

  const createFn = async (relatedPerson: RelatedPerson) =>
    relatedPerson.id ? update("RelatedPerson", relatedPerson.id, relatedPerson) : create("RelatedPerson", relatedPerson)

  const { mutate: createRelatedPerson, isPending: isAdding } = useMutation({
    mutationFn: createFn,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (relatedPerson) => {
      await queryClient.invalidateQueries({ queryKey: relatedPersonKeys.withPatientId(relatedPerson.patient.id) })
      await queryClient.invalidateQueries({
        queryKey: linkedCoveragesKeys.withPatientId(relatedPerson.patient.id as string),
      })

      onSuccess?.(relatedPerson)
      displayNotificationSuccess(
        relatedPerson.id ? "Related person updated successfully!" : "Related person created successfully!",
      )
    },
    onSettled,
  })

  return { createRelatedPerson, isAdding }
}

export { useCreateRelatedPerson }
