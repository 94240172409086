const coverageKeys = {
  all: ["patient/coverages"] as const,
  withPatientId: (patientId: string) => [...coverageKeys.all, patientId] as const,
}

const insuranceProvidersKeys = {
  all: ["insuranceProviders"] as const,
  withFilter: (filter?: string) => [...insuranceProvidersKeys.all, filter] as const,
}

const relatedPersonKeys = {
  all: ["patient/related-person"] as const,
  withPatientId: (patientId?: string) => [...relatedPersonKeys.all, patientId] as const,
}

const linkedCoveragesKeys = {
  all: ["patient/linked-coverages"] as const,
  withPatientId: (patientId: string) => [...linkedCoveragesKeys.all, patientId] as const,
  withRelatedPeople: (patientId: string, relatedPeople: string[]) =>
    [...linkedCoveragesKeys.withPatientId(patientId), relatedPeople] as const,
}

export { coverageKeys, insuranceProvidersKeys, relatedPersonKeys, linkedCoveragesKeys }
