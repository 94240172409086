import { useSearchParams } from "react-router-dom"

import { MedicationRequestDataContainer } from "./medications"
import { MedicationOrdersList, MedicationOrderDetails } from "./orders"
import { Contents } from "../types"
import { MedicationNextRefills } from "./MedicationNextRefills"
import "./MedicationRequestContainer.css"

const MedicationRequestContainer = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const contentOptions = [
    { name: "Medications", value: "medications" as Contents },
    { name: "Auto ship", value: "refills" as Contents },
    { name: "Orders", value: "orders" as Contents },
  ]

  const onUpdateContent = (activeContent: Contents) =>
    setSearchParams({ ...(activeContent === "medications" ? {} : { view: activeContent }) })

  if (searchParams.get("order") !== null) {
    return <MedicationOrderDetails />
  }

  const mrViews: Record<Exclude<Contents, "medications">, JSX.Element> = {
    orders: (
      <MedicationOrdersList activeContent="orders" contentOptions={contentOptions} onUpdateContent={onUpdateContent} />
    ),
    refills: (
      <MedicationNextRefills
        activeContent="refills"
        contentOptions={contentOptions}
        onUpdateContent={onUpdateContent}
      />
    ),
  }

  const viewParam = searchParams.get("view") as Exclude<Contents, "medications">

  if (viewParam) {
    return mrViews[viewParam]
  }

  return (
    <MedicationRequestDataContainer
      activeContent="medications"
      contentOptions={contentOptions}
      onUpdateContent={onUpdateContent}
    />
  )
}

export { MedicationRequestContainer }
