import { faEye } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { classNames } from "primereact/utils"
import { InputText } from "primereact/inputtext"
import { MultiSelect } from "primereact/multiselect"

import "./SearchWithStatus.css"

const SearchWithStatus = ({
  selectedItems,
  options,
  className,
  placeholder = "Search",
  onStatusCheck,
  onSearch,
}: Props) => (
  <div className={classNames("search-status-dropdown field flex flex-col relative", className)}>
    <div className="p-inputgroup h-full relative">
      <InputText
        placeholder={placeholder}
        className="text-xs search-input"
        type="search"
        onChange={(e) => onSearch(e.currentTarget?.value)}
      />
      {options && options.length > 1 && (
        <span className="p-inputgroup-addon relative">
          <FontAwesomeIcon className="text-slate-400" size="1x" icon={faEye} />
          <MultiSelect
            value={selectedItems}
            options={options}
            optionLabel="display"
            optionValue="code"
            filter={false}
            showSelectAll={false}
            onChange={(e) => onStatusCheck?.(e.value)}
            panelClassName="hidden-header"
            className="hidden-labels custom-trigger"
          />
        </span>
      )}
    </div>
    <span className="absolute left-2.5 h-full flex items-center z-10">
      <i className="pi pi-search text-xs" />
    </span>
  </div>
)

type Props = {
  className?: string
  placeholder?: string
  options?: Array<{ code: string; display: string }>
  selectedItems?: string[]
  onStatusCheck?(codes: string[]): void
  onSearch(filter?: string): void
}

export { SearchWithStatus }
