import { SkeletonLoader } from "commons"

const NetworkError = () => (
  <div className="banner-container h-24 border-b drop-shadow-sm border-gray-200">
    <div className="flex relative h-full ">
      <SkeletonLoader repeats={1} loaderType="list" containerClassName="w-1/2" />
      <SkeletonLoader repeats={1} loaderType="two-lines" containerClassName="w-1/2" />
    </div>
    <div className="py-2 px-4">
      <SkeletonLoader repeats={8} loaderType="two-lines" />
    </div>
  </div>
)

export { NetworkError }
