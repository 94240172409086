import { BreadCrumb } from "primereact/breadcrumb"

const SimpleBreadCrumb = ({ title, goHome }: Props) => (
  <BreadCrumb
    model={[{ label: title }]}
    home={{ icon: "pi pi-home", command: goHome }}
    className="breadcrumb-small flex items-center h-8 mb-3"
  />
)

type Props = {
  title: string
  goHome(): void
}

export { SimpleBreadCrumb }
