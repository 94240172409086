import { faSearch, faSpinner } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { classNames } from "primereact/utils"
import { startTransition } from "react"

import { Input } from "./Input"

const SearchInput = ({ isLoading, className, placeholder = "Search", search }: Props) => (
  <div className={classNames("relative block w-full", className)}>
    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
      <FontAwesomeIcon className="text-gray-400" icon={faSearch} />
    </span>
    <Input
      placeholder={placeholder}
      className="pl-9 pr-3 h-full w-full"
      type={!isLoading ? "search" : undefined}
      onChange={(event) => startTransition(() => search(event.currentTarget?.value))}
    />
    <span
      className={classNames("absolute inset-y-0 right-0 flex items-center pr-3", {
        hidden: !isLoading,
      })}
    >
      <FontAwesomeIcon className="text-gray-400" icon={faSpinner} spin />
    </span>
  </div>
)

type Props = {
  isLoading?: boolean
  placeholder?: string
  className?: string
  search(searchText?: string): void
}

export { SearchInput }
