import { classNames } from "primereact/utils"
import { useNavigate } from "react-router-dom"

import { AzureImage, useSidebarContext } from "commons"
import chIconLogo from "images/chIconLogo.webp"

const AppIconLogo = ({ showCollapsed, className }: Props) => {
  const { branding } = useSidebarContext()
  const navigate = useNavigate()

  const logoUrl = showCollapsed ? branding.iconLogo : branding.logo
  const goHome = () => navigate("/")

  const fallbackLogo = (
    <div onClick={goHome} className="cursor-pointer">
      <img src={chIconLogo} alt="app logo" className="w-12" />
      {!showCollapsed ? <span className="text-3xl ml-1 mt-1 mr-5"> ChartedHealth</span> : null}
    </div>
  )

  return (
    <div className={classNames("flex justify-center w-full h-fit cursor-pointer", className)} onClick={goHome}>
      <AzureImage
        url={logoUrl as string}
        imageClassName={showCollapsed ? "w-12" : "w-48"}
        fallbackIconNode={fallbackLogo}
      />
    </div>
  )
}

type Props = {
  showCollapsed?: boolean
  className?: string
}

export { AppIconLogo }
