import { Route, Routes } from "react-router-dom"

import { NotFoundView } from "navigation"
import { Dashboard } from "dashboard"
import { InvoiceContainer } from "invoices"
import { MedicationRequestContainer } from "medication-requests"
import { PatientInformation } from "patient"
import { useSidebarContext } from "commons"
import { CommunicationContainer } from "communication"
import { PatientSurveys, SurveyContainer } from "surveys"
import { NotificationsView } from "notifications"
import { CheckoutView } from "orders"
import { LaboratoryOrdersContainer } from "laboratory"
import { MetabolicCodeReportsContainer } from "mc"
import { PlansContainer } from "plans"

const RoutesContainer = () => {
  const { isModuleActive, purchaseEnabled } = useSidebarContext()

  return (
    <Routes>
      <Route path="/">
        <Route index element={<Dashboard />} />
        <Route path="dashboard" element={<Dashboard />} />
        {isModuleActive("survey") && (
          <Route path="questionnaires">
            <Route index element={<PatientSurveys />} />
            <Route path=":id" element={<SurveyContainer />} />
          </Route>
        )}
        <Route path="nutraceuticals" element={<MedicationRequestContainer />} />
        <Route path="profile" element={<PatientInformation />} />
        {isModuleActive("communication") && <Route path="messages" element={<CommunicationContainer />} />}
        {purchaseEnabled && (
          <>
            <Route path="shop" element={<CheckoutView />} />
            <Route path="checkout" element={<CheckoutView />} />
          </>
        )}
        <Route path="invoices" element={<InvoiceContainer />} />
        <Route path="notifications" element={<NotificationsView />} />
        {isModuleActive("labs") && <Route path="labs" element={<LaboratoryOrdersContainer />} />}
        {isModuleActive("plans") && <Route path="plans" element={<PlansContainer />} />}
        {isModuleActive("mc") && <Route path="metabolic-code" element={<MetabolicCodeReportsContainer />} />}
        <Route path="*" element={<NotFoundView />} />
      </Route>
    </Routes>
  )
}

export { RoutesContainer }
