import { Patient } from "fhir"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { displayNotificationSuccess } from "utils"
import { registerErrorTrace } from "logger"
import { commonsQueryKeys , CustomError } from "commons"

const usePatchPatient = (onSettled?: () => void) => {
  const queryClient = useQueryClient()
  const { patch } = useClient()

  const updatePatient = async ({
    patientId,
    patientData,
  }: {
    patientId: string
    patientData: Partial<Patient>
    successMsg?: string
  }) => patch("Patient", patientId, patientData)

  const { mutate: patchPatient, isPending: isPatching } = useMutation({
    mutationFn: updatePatient,
    onError: (error: CustomError, context) => {
      if (!!error.cause?.name && ["409", "412"].includes(error.cause.name)) {
        // Conflict error or precondition failed error are thrown when the resource has been modified by someone
        queryClient.invalidateQueries({ queryKey: commonsQueryKeys.loggedIn(context.patientId) })
      }
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (_, { successMsg, patientData }) => {
      await queryClient.invalidateQueries({ queryKey: ["signedUrl", patientData.photo?.[0]?.url] })
      await queryClient.invalidateQueries({ queryKey: commonsQueryKeys.loggedIn(patientData.id) })

      displayNotificationSuccess(successMsg ?? "Patient updated successfully!")
      queryClient.invalidateQueries({ queryKey: commonsQueryKeys.loggedIn(patientData.id) })
      queryClient.invalidateQueries({ queryKey: ["logged-user-patients"] })
    },
    onSettled,
  })

  return { patchPatient, isPatching }
}

export { usePatchPatient }
