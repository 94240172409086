import { useSuspenseQuery } from "@tanstack/react-query"

import { settingsKeys } from "../query-keys"
import { useSettingQueryFunction } from "./useSetting"

const useModulesVisibility = (organizationId: string) => {
  const getSetting = useSettingQueryFunction()
  const queryKey = settingsKeys.modulesVisibility(organizationId)

  const { data, isError, error } = useSuspenseQuery({
    queryKey,
    queryFn: async () => await getSetting(organizationId, "ehr-modules"),
    meta: { context: { queryKey, organizationId } },
  })

  if (isError) {
    throw error
  }

  return { modulesSettings: data.indexedItems ?? {} }
}

export { useModulesVisibility }
