import { PropsWithChildren } from "react"
import { faAngleLeft, faBars } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { classNames } from "primereact/utils"

import { AzureImage, useSidebarContext } from "commons"

import banner from "images/banner.webp"

const NavigationBanner = ({
  toggleMenu,
  previousPage,
  page,
  children,
  onIconClick,
  className,
}: PropsWithChildren<Props>) => {
  const { branding } = useSidebarContext()

  const defaultBanner = <img src={banner} alt="" />

  return (
    <div
      className={classNames(
        "navigation-banner relative h-16 lg:h-20 w-full flex justify-left items-center bg-white bg-no-repeat bg-cover bg-center",
        { "pl-5": toggleMenu },
        className,
      )}
    >
      <AzureImage
        url={branding.banner as string}
        className="absolute top-0 left-0 w-full h-full"
        loadingClassName="absolute top-0 left-0 w-full h-full"
        fallbackIconNode={defaultBanner}
      />
      <div className="w-full flex items-center relative bg-clip-text text-primary">
        {previousPage && (
          <span
            className="inline-flex absolute items-center cursor-pointer font-semibold text-lg hover:text-primary-hover pl-5"
            onClick={onIconClick}
          >
            <FontAwesomeIcon icon={faAngleLeft} size="lg" className="mr-1 pb-0.5" />
            {previousPage}
          </span>
        )}
        {toggleMenu && (
          <FontAwesomeIcon
            icon={faBars}
            size="lg"
            className="cursor-pointer pt-0.5 hover:text-primary-hover"
            onClick={onIconClick}
          />
        )}
        <div className="flex flex-1 justify-between items-center pr-5">
          <h3
            className={classNames(
              "text-2xl w-full font-semibold",
              previousPage === undefined ? "text-left pl-5" : "text-center",
            )}
          >
            {page === "Medications"
              ? "Nutraceuticals"
              : page == "Plans"
                ? "Treatment Plans"
                : page === "Metabolic-code"
                  ? "Metabolic Code"
                  : page}
          </h3>
        </div>
        {children}
      </div>
    </div>
  )
}

type Props = {
  previousPage?: string
  page?: string
  className?: string
  toggleMenu?: boolean
  onIconClick?(): void
}

export { NavigationBanner }
