import { useQuery } from "@tanstack/react-query"
import { Account, getResources } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"

import { creditCardsKeys } from "../query-keys"
import { useDecryptPatientAccount } from "./useDecryptPatientAccount"

const useCreditCards = (patientId: string) => {
  const { search } = useClient()
  const { decrypt } = useDecryptPatientAccount()
  const queryKey = creditCardsKeys.withPatientId(patientId)

  const { data, isLoading, isFetching } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const bundle = await search({ endpoint: `Patient/${patientId}/Account`, signal })
      const accounts = getResources(bundle) as Account[]

      let decryptedAccount

      if (accounts.length) {
        decryptedAccount = await decrypt({ accountId: accounts?.[0]?.id ?? "" })
      }

      return { account: decryptedAccount ?? accounts[0] }
    },
    meta: { context: { queryKey, patientId } },
  })

  const { creditCards, account, count } = useMemo(() => {
    const account = data?.account

    const creditCards = account?.creditCard?.length
      ? account.creditCard.sort((a, b) =>
          (b.expirationYear ?? "") + (b.expirationMonth ?? "").padStart(2, "0") <
          (a.expirationYear ?? "") + (a.expirationMonth ?? "").padStart(2, "0")
            ? -1
            : 1,
        )
      : []

    return {
      creditCards,
      account,
      count: creditCards.length,
    }
  }, [data?.account])

  return {
    creditCards,
    accountId: account?.id,
    account,
    defaultCreditCard: account?.defaultCreditCard,
    isLoading,
    isFetching,
    count,
    total: creditCards?.length ?? 0,
  }
}

export { useCreditCards }
