const laboratoryOrdersKeys = {
  all: ["patient/laboratoryOrders"] as const,
  withPatientId: (patientId: string) => [...laboratoryOrdersKeys.all, patientId] as const,
  withStatusFilter: (patientId: string, statusFilter: string[]) =>
    [...laboratoryOrdersKeys.withPatientId(patientId), statusFilter] as const,
  withStatusAndSearchFilter: (patientId: string, statusFilter: string[], searchFilter?: string) =>
    [...laboratoryOrdersKeys.withStatusFilter(patientId, statusFilter), searchFilter] as const,
}

const laboratoryOrderKeys = {
  all: ["patient/laboratoryOrder"] as const,
  withOrderId: (orderid: string) => [...laboratoryOrderKeys.all, orderid] as const,
}

export { laboratoryOrdersKeys, laboratoryOrderKeys }
