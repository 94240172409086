import { QuestionnaireResponse } from "fhir"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarDays } from "@fortawesome/pro-regular-svg-icons"
import { Chip } from "primereact/chip"
import { classNames } from "primereact/utils"

import { CardListItem } from "commons"
import { formatsByTypes } from "data"
import { formatDate } from "utils"

const PatientQRListItem = ({
  questionnaireTitle,
  questionnaireVersion,
  qResponse: { status, authored },
  onClick,
}: Props) => {
  const head = (
    <>
      <div className="font-bold mr-2" title="Title">
        {questionnaireTitle}
      </div>
      {questionnaireVersion && (
        <span className="font-semibold" title="Version">
          {questionnaireVersion}
        </span>
      )}
      {status && (
        <span title="Status">
          <Chip
            label={status}
            className={classNames("ml-2 custom-chip whitespace-nowrap", { "is-success": status === "in-progress" })}
          />
        </span>
      )}
    </>
  )
  const details = (
    <>
      {authored && (
        <span title="Authored">
          <FontAwesomeIcon icon={faCalendarDays} size="xs" className="mr-1" />
          {formatDate(new Date(authored), formatsByTypes.LONG_DATETIME)}
        </span>
      )}
    </>
  )

  return (
    <CardListItem
      contentHeader={head}
      contentSubheader={details}
      className="cursor-pointer"
      onContentCliked={onClick}
    />
  )
}

type Props = {
  questionnaireTitle: string
  questionnaireVersion?: string
  qResponse: QuestionnaireResponse
  onClick?(): void
}

export { PatientQRListItem }
