import { hsl } from "color-convert"
import { format as dateFnFormat, format, isToday, isYesterday, parseISO } from "date-fns"
import {
  AccountBETACreditCardArray,
  Address,
  ChargeItemDefinition,
  ChargeItemDefinitionPropertyGroupArrayPriceComponentArray,
  Coding,
  Coverage,
  Identifier,
  isCoverage,
  isMedicationKnowledge,
  isMedicationRequest,
  MedicationKnowledge,
  MedicationRequest,
  ParametersParameterArrayValue,
  ServiceRequest,
  Task,
} from "fhir"
import { createElement } from "react"
import { toast, TypeOptions } from "react-toastify"
import * as Yup from "yup"
import { AnyObject } from "yup/lib/types"
import { BigNumber } from "bignumber.js"

import { BadgeProps, NotificationSuccess, NotificationWarningUpdateAvailable, NotificationWithActions } from "commons"
import { BillingTypeCodes, formatsByTypes, mrCategoryCodes } from "data"
import { SYSTEM_VALUES } from "system-values"

const lineInvoiceTypes = {
  TAX: "tax",
  FEE: "surcharge",
  DISCOUNT: "discount",
  BASE: "base",
  INFORMATIONAL: "informational",
}

const formatDate = (date: Date, format = "yyyy-MM-dd") => dateFnFormat(date, format)

const strCapitalize = (str: string) => (str ? `${str[0].toUpperCase()}${str.substring(1)}` : "")

const isISOdate = (str: string) => {
  const ISOdateRegex = "^-?[0-9]{4}(-(0[1-9]|1[0-2])(-(0[0-9]|[1-2][0-9]|3[0-1]))?)?$"
  const matches = str.match(ISOdateRegex)
  return matches && matches.length
}

const getDefaultAvatar = (name: string, bg: string) => {
  return `https://api.dicebear.com/5.x/initials/svg?seed=${name}&backgroundColor=${bg}`
}

const getMoneyCurrencyAlt = (currency?: string) => {
  switch (currency) {
    case "USD":
      return "$"
    case "EUR":
      return "€"

    default:
      return "$"
  }
}

const displayNotificationSuccess = (message: string) => {
  if (window.innerWidth > 768) {
    toast.success(createElement(NotificationSuccess, { message }), { autoClose: 2000 })
  } else {
    toast.success(createElement(NotificationSuccess, { message }), {
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      style: { bottom: "4rem", minHeight: 0 },
    })
  }
}

const displayActionNotification = ({
  type = "success",
  message,
  actions,
}: {
  type?: TypeOptions
  header?: string
  message: string
  actions: { label: string; onClick(): void }[]
}) => {
  toast(
    createElement(NotificationWithActions, {
      message,
      actions,
    }),
    { autoClose: 4000, type },
  )
}

const getBasePrice = (priceComponent?: ChargeItemDefinitionPropertyGroupArrayPriceComponentArray[]) => {
  return priceComponent?.find((price) => price.type === "base")
}

const getFeePrice = (priceComponent?: ChargeItemDefinitionPropertyGroupArrayPriceComponentArray[]) => {
  return priceComponent?.find((price) => price.type === "surcharge")
}

const getDiscountPrice = (priceComponent?: ChargeItemDefinitionPropertyGroupArrayPriceComponentArray[]) => {
  return priceComponent?.find((price) => price.type === lineInvoiceTypes.DISCOUNT)
}

const getTaxPrice = (priceComponent?: ChargeItemDefinitionPropertyGroupArrayPriceComponentArray[]) => {
  return priceComponent?.find((price) => price.type === lineInvoiceTypes.TAX)
}

const getBillToPatientFeePrice = (priceComponent?: ChargeItemDefinitionPropertyGroupArrayPriceComponentArray[]) => {
  return priceComponent?.find(
    (price) =>
      price.type === lineInvoiceTypes.INFORMATIONAL && price.code?.coding?.some((c) => c.code === "bill-patient"),
  )
}

const cidSort = (arr: ChargeItemDefinition[]) =>
  arr.sort(
    (a, b) =>
      (getFeePrice(a.propertyGroup?.[0].priceComponent)?.amount?.value ?? 0) -
      (getFeePrice(b.propertyGroup?.[0].priceComponent)?.amount?.value ?? 0),
  )

const formatCreditCardNumber = (number?: string, type?: string) => {
  if (!number) return "Unspecified"
  number = number.trim()
  if (number.length < 4) number = number.padStart(4, "X")
  return type === "AE"
    ? `XXXX-XXXXXX-X${number.substring(number.length - 4)}`
    : `XXXX-XXXX-XXXX-${number.substring(number.length - 4)}`
}

const getAddressSchema = (parentFieldName?: string) => {
  const parentFullFieldName = parentFieldName ? parentFieldName + "." : ""

  return Yup.object().shape({
    country: Yup.string().trim().required("Country is required"),
    line: Yup.array()
      .of(
        Yup.string()
          .trim()
          .test("test-address-lines", "First address line is required", (value, context) => {
            return context?.path === `${parentFullFieldName}line[0]` ? value !== undefined && value !== "" : true
          }),
      )
      .min(1, ({ min }) => `At least ${min} address line is required`),
    city: Yup.string().trim().required("City is required"),
    state: Yup.string().trim().required("State is required"),
    postalCode: Yup.string().trim().required("ZIP is required"),
  })
}

const humanNameSchema = Yup.object().shape({
  given: Yup.array()
    .of(
      Yup.string().test("test-first-name", "First name is required", (value, context) => {
        return context?.path === "name[0].given[0]" ? value !== undefined && value !== "" : true
      }),
    )
    .min(1, "Name is required"),
  family: Yup.string().required("Last name is required"),
})

const telecomSchema = Yup.object().shape({
  system: Yup.string()
    .oneOf(["phone", "fax", "email", "pager", "url", "sms", "other"], "Invalid value")
    .required("Specify telecom system"),
  use: Yup.string()
    .oneOf(["home", "work", "temp", "old", "mobile"], "Invalid value")
    .required("Specify this telecom usage"),
  value: Yup.string().when("system", (system, yup) => system && yup.required(`${strCapitalize(system)} is required`)),
})

const MOBILE_SCREEN_MAX = 720
const PRICE_SUB_SYSTEM = "sku"
const PRICE_SUB_SYSTEM_PROP61 = "sku-ca"

const getCommonCode = (codes: Coding[] | undefined) =>
  (
    codes?.find(({ system }) => system?.includes(PRICE_SUB_SYSTEM)) ||
    codes?.find(({ system }) => system?.includes(PRICE_SUB_SYSTEM_PROP61))
  )?.code ?? "no-code"

const getBadgeColor = (text: string): BadgeProps => {
  switch (text.toLowerCase()) {
    case "completed":
    case "resolved":
    case "active":
    case "balanced":
    case "delivered":
    case "open":
    case "click":
    case "final results available":
      return { text, colorStyle: "green" }

    case "stoped":
    case "not-done":
    case "recurrence":
    case "cancelled":
    case "entered-in-error":
    case "revoked":
    case "dropped":
    case "spam":
    case "unsubscribe":
      return { text: text === "revoked" ? "cancelled" : text, colorStyle: "red" }
    case "draft":
    case "bounce":
    case "deferred":
    case "requisition pending":
      return { text, colorStyle: "yellow" }

    case "issued":
    case "in-progress":
    case "processed":
    case "preliminary results":
      return { text, colorStyle: "blue" }
    case "requisition available":
      return { text, colorStyle: "orange" }
    default:
      return { text, colorStyle: "gray" }
  }
}

const displayNotificationWarningUpdateAvailable = (message: string) => {
  const toastId = "new-available-update-toast"

  toast.warn(
    ({ closeToast }) =>
      createElement(NotificationWarningUpdateAvailable, {
        message,
        onClose: closeToast,
      }),
    {
      autoClose: false,
      draggable: false,
      position: toast.POSITION.BOTTOM_RIGHT,
      icon: false,
      toastId: toastId,
      closeOnClick: false,
    },
  )
}

const getPriceByCode = (
  chargeItemDefinitions: Record<string, ChargeItemDefinition>,
  medCoding?: Coding[],
  factor = 1,
  includePatientFee = false,
) => {
  const chargeItemDef = chargeItemDefinitions?.[getCidIdentifier(getCommonCode(medCoding), factor)]

  const cost = getBasePrice(chargeItemDef?.propertyGroup?.[0].priceComponent)?.amount
  const fee = getBillToPatientFeePrice(chargeItemDef?.propertyGroup?.[0].priceComponent)?.amount
  const sum = (cost?.value ?? 0) + (fee?.value ?? 0)
  const totalPrice = includePatientFee ? sum : cost?.value

  return totalPrice ? { value: totalPrice, currency: cost?.currency ?? "USD" } : undefined
}

const getMedCodes = ({
  meds,
  withQty = false,
}: {
  meds?: Array<MedicationRequest | MedicationKnowledge>
  withQty?: boolean
}) =>
  meds?.reduce<Coding[] | ParametersParameterArrayValue[]>(
    (prev, med) =>
      isMedicationRequest(med) && med.medication?.CodeableConcept?.coding
        ? withQty
          ? ([
              ...prev,
              ...med.medication.CodeableConcept.coding.map((c) => ({
                Coding: c,
                Quantity: med.dispenseRequest?.quantity,
              })),
            ] as ParametersParameterArrayValue[])
          : ([...prev, ...med.medication.CodeableConcept.coding] as Coding[])
        : isMedicationKnowledge(med) && med.code?.coding
          ? [...prev, ...med.code.coding]
          : prev,
    [],
  )

const convertIdentifiersToCodings = (resourceList: { identifier?: Identifier[] }[]) => {
  const codes =
    resourceList?.reduce<Coding[]>((acc, pd) => {
      const newCodes = pd.identifier?.reduce<Coding[]>(
        (prev, id) => [...prev, { system: id.system, code: id.value }],
        [],
      )
      return newCodes ? [...acc, ...newCodes] : acc
    }, []) ?? []
  return codes
}

const getCidIdentifier = (code: string, factor = 1) => `${code}-${factor}`

const hasMedAutoship = (medicationRequests?: MedicationRequest[]) =>
  medicationRequests?.some((mr) => (mr.dispenseRequest?.dispenseInterval?.value as number) > 0) ?? false

const getCcId = (cc: AccountBETACreditCardArray) => (cc ? `${cc.type}|${cc.last4Digits}` : "")

const getIndexedCID = (cids?: ChargeItemDefinition[]) =>
  cids?.reduce<Record<string, ChargeItemDefinition>>((prev, cid) => {
    return {
      ...prev,
      [getCidIdentifier(getCommonCode(cid.code?.coding), cid?.propertyGroup?.[0]?.priceComponent?.[0]?.factor)]: cid,
    }
  }, {}) ?? {}

const hslToHex = (hslColor: string) => {
  if (new RegExp(/^\d+deg \d+% \d+%$/g).test(hslColor)) {
    const values = hslColor
      .replace("%", "")
      .split(" ")
      .map((v) => parseInt(v))
    return hsl.hex([values[0], values[1], values[2]])
  }
  return ""
}

const getSubviewPath = (patientId: string, subviewPath?: string) => {
  const refinedSubviewPath = subviewPath?.replace(/\//g, "") ?? ""
  return `/patient/${patientId}/${refinedSubviewPath}`
}

const pathToRouteName = (path = "") => {
  if (path === "/") {
    return "Home"
  } else {
    const splittedPath = path.split("/")
    return strCapitalize(splittedPath[splittedPath.length - 1].replace(/\//g, ""))
  }
}

const bytesToMegaBytes = (bytes: number) => (bytes / (1024 * 1024)).toFixed(2)

const isMrProcedure = (mr: MedicationRequest) =>
  mr.category?.some(({ coding }) => coding?.[0].code === mrCategoryCodes["procedure"].code) ?? false

const isMrMedication = (mr: MedicationRequest) =>
  mr.category?.some(({ coding }) => coding?.[0].code === mrCategoryCodes["medication"].code) ?? false

const getTaskDate = (task: Task) => {
  const startDate = task.restriction?.period?.start
  if (!startDate) return "Not specified date"
  const endDate = task.restriction?.period?.end
  return `${taskDateToString(startDate)}${endDate ? ` - ${taskDateToString(endDate)}` : ""}`
}

const taskDateToString = (fieldDate: string) =>
  format(parseISO(fieldDate), fieldDate.includes("T") ? formatsByTypes.LONG_DATETIME : formatsByTypes.LONG_DATE)

const IsNetworkError = (message: string) => {
  const errorMessages = new Set([
    "Failed to fetch", // Chrome
    "NetworkError when attempting to fetch resource.", // Firefox
    "The Internet connection appears to be offline.", // Safari 16
    "Load failed", // Safari 17+
    "Network request failed", // `cross-fetch`
    "fetch failed", // Undici (Node.js)
  ])

  return errorMessages.has(message)
}

const isAbortError = (reason: unknown): reason is DOMException =>
  reason instanceof DOMException && reason.name === "AbortError"

const getServiceRequestBillingType = (sr?: ServiceRequest): BillingTypeCodes => {
  const billingType = sr?.insurance?.[0].localRef
    ? isCoverage(sr?.contained?.[0]) && sr?.contained?.[0]?.payor?.[0]?.resourceType === "Patient"
      ? BillingTypeCodes.BILL_PATIENT
      : BillingTypeCodes.BILL_PRACTICE
    : BillingTypeCodes.INSURANCE

  return billingType
}

const mergeSort = <T extends AnyObject>(
  list: T[],
  propToCompare: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  compareFunction?: (a: any, b: any) => number,
): T[] => {
  if (list.length <= 1) {
    return list
  }

  const mid = Math.floor(list.length / 2)
  const leftHalf = list.slice(0, mid)
  const rightHalf = list.slice(mid)

  const sortedLeftHalf = mergeSort(leftHalf, propToCompare, compareFunction)
  const sortedRightHalf = mergeSort(rightHalf, propToCompare, compareFunction)

  return merge(sortedLeftHalf, sortedRightHalf, propToCompare, compareFunction)
}

const merge = <T extends AnyObject>(
  left: T[],
  right: T[],
  propToCompare: string, // Do not use a chained prop(x.y) here. If you need that level of deep use it with compareFunction
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  compareFunction?: (a: any, b: any) => number,
): T[] => {
  const merged: T[] = []
  let leftIndex = 0
  let rightIndex = 0

  while (leftIndex < left.length && rightIndex < right.length) {
    if (
      compareFunction
        ? compareFunction(left[leftIndex]?.[propToCompare] ?? 0, right[rightIndex]?.[propToCompare] ?? 0) <= 0
        : (left[leftIndex]?.[propToCompare] ?? 0) < (right[rightIndex]?.[propToCompare] ?? 0)
    ) {
      merged.push(left[leftIndex])
      leftIndex++
    } else {
      merged.push(right[rightIndex])
      rightIndex++
    }
  }

  while (leftIndex < left.length) {
    merged.push(left[leftIndex])
    leftIndex++
  }

  while (rightIndex < right.length) {
    merged.push(right[rightIndex])
    rightIndex++
  }

  return merged
}

const getStringAddress = (address?: Address) => {
  if (!address) {
    return "Unspecified address"
  }

  const { line, city, state, country, postalCode } = address ?? {}

  return Array.from([line, city, state, country, postalCode])
    .flat()
    .filter((d) => d && d !== "")
    .join(", ")
}

const isRefrigeratedMedicationKnowledge = (medicationKnowledge: MedicationKnowledge) =>
  medicationKnowledge.drugCharacteristic?.some(({ type }) => type?.coding?.[0].code === "refrigerated")

const medicationKnowledgeRegulations = (medicationKnowledge: MedicationKnowledge) =>
  medicationKnowledge.regulatory?.reduce<Coding[]>((prev, { substitution }) => {
    if (substitution?.[0]?.type.coding?.[0]?.code) {
      const coding = substitution?.[0]?.type.coding[0]

      return [...prev, coding]
    }

    return prev
  }, [])

const sanitizeURL = (url: string) => {
  const splittedUrl = url.split("https://")
  const sanitizedUrl = splittedUrl[0].concat(splittedUrl[1]?.replaceAll("//", "/") ?? "")
  return sanitizedUrl.startsWith("/") ? sanitizedUrl.slice(1) : sanitizedUrl
}

const openLinkInNewTab = (url?: string, onError?: (error: unknown) => void) => {
  if (url)
    try {
      const tab = window.open()
      if (tab) {
        tab.location.href = url
        tab.focus()
        return
      }
      /* Fallback download file */
      const link = document.createElement("a")
      link.download = url
      link.href = url
      link.target = "_blank"
      link.classList.add("hidden")
      document.body.appendChild(link)
      link.click()

      setTimeout(() => {
        document.body.removeChild(link)
      }, 2000)
    } catch (error) {
      onError?.(error)
    }
}

const getDateLabel = (dateStr: string, formatting?: string) => {
  const date = new Date(dateStr)

  return isToday(date)
    ? "Today"
    : isYesterday(date)
      ? "Yesterday"
      : format(date, formatting ?? formatsByTypes.ISO_8601_DATE)
}

const isPoBoxAddress = (address?: Address) =>
  /\b(?:[Pp]\.?\s*[Oo]\.?|post\s+office)(\s+)?(?:[Bb]ox|[0-9]*)?\b/g.test(address?.line?.[0] ?? "")

const getBillingTypeCode = (mr?: MedicationRequest) =>
  mr?.insurance?.[0]?.localRef
    ? (mr?.contained?.find((resource) => (resource as Coverage)?.id === mr?.insurance?.[0]?.localRef) as Coverage)?.type
        ?.coding?.[0]?.code
    : undefined

const getOrderType = (serviceRequest?: ServiceRequest) =>
  serviceRequest?.orderDetail?.reduce((acc, { coding }) => {
    const typeCode = coding?.find(({ system }) => system === SYSTEM_VALUES.ORDER_DETAIL_TYPE)?.code
    return typeCode ?? acc
  }, "")

const getIdentifierBySystem = (identifiers: Identifier[] = [], system: string) =>
  identifiers.find(({ system: IdentifierSystem }) => IdentifierSystem === system)

const sumPrice = (num1: number | BigNumber, num2: number | BigNumber) => {
  const bNum1 = new BigNumber(num1)
  const bNum2 = new BigNumber(num2)
  const sum = bNum1.plus(bNum2)

  return { num1: bNum1, num2: bNum2, sum }
}

const substractPrice = (num1: number | BigNumber, num2: number | BigNumber) => {
  const bNum1 = new BigNumber(num1)
  const bNum2 = new BigNumber(num2)
  const sub = bNum1.minus(bNum2)

  return { num1: bNum1, num2: bNum2, sub }
}

const multiplyPrice = (price: number | BigNumber, num2: number | BigNumber) => {
  const bNum1 = new BigNumber(price)
  const bNum2 = new BigNumber(num2)

  return bNum1.multipliedBy(bNum2)
}

export {
  bytesToMegaBytes,
  cidSort,
  convertIdentifiersToCodings,
  displayActionNotification,
  displayNotificationSuccess,
  displayNotificationWarningUpdateAvailable,
  formatCreditCardNumber,
  formatDate,
  getAddressSchema,
  getBadgeColor,
  getBasePrice,
  getBillingTypeCode,
  getCcId,
  getCidIdentifier,
  getCommonCode,
  getDateLabel,
  getDefaultAvatar,
  getDiscountPrice,
  getFeePrice,
  getIndexedCID,
  getMedCodes,
  getMoneyCurrencyAlt,
  getOrderType,
  getIdentifierBySystem,
  getPriceByCode,
  getServiceRequestBillingType,
  getStringAddress,
  getSubviewPath,
  getTaskDate,
  getTaxPrice,
  hasMedAutoship,
  hslToHex,
  humanNameSchema,
  isAbortError,
  isISOdate,
  isMrMedication,
  isMrProcedure,
  IsNetworkError,
  isPoBoxAddress,
  isRefrigeratedMedicationKnowledge,
  medicationKnowledgeRegulations,
  mergeSort,
  MOBILE_SCREEN_MAX,
  openLinkInNewTab,
  pathToRouteName,
  sanitizeURL,
  strCapitalize,
  taskDateToString,
  telecomSchema,
  sumPrice,
  substractPrice,
  multiplyPrice,
}
