import { useMutation } from "@tanstack/react-query"
import { Bundle, getResources, MedicationRequest } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

const useStopMedicationRequest = (refreshMedications: (meds: MedicationRequest[]) => void, onSettled?: () => void) => {
  const { operationRequest } = useClient()

  const stopMedicationReq = async (medicationRequestId: string) =>
    operationRequest<Bundle>({
      endpoint: "MedicationRequest",
      method: "POST",
      operation: "stop",
      id: medicationRequestId,
    })

  const { mutate: stopMedicationRequest, isPending } = useMutation({
    mutationFn: stopMedicationReq,
    onError: (error: CustomError, medicationRequestId) => {
      displayNotificationError(registerErrorTrace(error, { medicationRequestId }))
    },
    onSuccess: (meds) => {
      displayNotificationSuccess("Medication stopped successfully!")
      const medications = getResources<MedicationRequest>(meds)
      refreshMedications(medications)
    },
    onSettled: onSettled,
  })

  return { stopMedicationRequest, isLoading: isPending }
}

export { useStopMedicationRequest }
