import { Account, AccountBETACreditCardArray } from "fhir"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"
import { useLoginContext } from "security"
import { CustomError } from "commons"

import { useBasisTheoryInstance } from "./useBasisTheoryInstance"
import { useAuthorizeBasisTheorySession } from "./useAuthorizeBasisTheorySession"
import { CreditCardFormData } from "../types"
import { sanitize } from "../components/validations"
import { accountKeys, creditCardsKeys } from "../query-keys"

const useCreateCreditCard = (onSuccess: () => void, onSettled?: () => void) => {
  const { loggedInPatientId } = useLoginContext()
  const { patch } = useClient()
  const queryClient = useQueryClient()
  const { bt } = useBasisTheoryInstance()
  const { authorize } = useAuthorizeBasisTheorySession()

  const updateAccount = async ({
    creditCard,
    creditCardList,
    account,
  }: {
    creditCard: CreditCardFormData
    account: Account
    creditCardList: AccountBETACreditCardArray[]
  }) => {
    if (!bt) return

    const { sessionKey, nonce } = await bt.sessions.create()
    const { container } = await authorize({ nonce })

    const token = await bt.tokens.create(
      {
        type: "card",
        data: {
          number: creditCard.number?.replace(/-/g, "") as string,
          cvv: creditCard.cvv as string,
          expiration_month: creditCard.expirationMonth as string,
          expiration_year: creditCard.expirationYear as string,
        },
        containers: [container],
      },
      { apiKey: sessionKey },
    )

    const sanitizedCreditCard = sanitize(creditCard)
    const creditCardWithPCIToken: AccountBETACreditCardArray = { ...sanitizedCreditCard, pciToken: token.id }

    return patch<Partial<Account>>("Account", account.id as string, {
      creditCard: [...creditCardList, creditCardWithPCIToken],
      defaultCreditCard: `${sanitizedCreditCard.type}|${sanitizedCreditCard.last4Digits}`,
    })
  }
  const { mutate: createCreditCard, isPending: isAdding } = useMutation({
    mutationFn: updateAccount,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: creditCardsKeys.withPatientId(loggedInPatientId) })
      await queryClient.invalidateQueries({ queryKey: accountKeys.withPatientId(loggedInPatientId) })

      onSuccess?.()
      displayNotificationSuccess("Credit card updated successfully!")
    },
    onSettled,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
  })

  return { createCreditCard, isAdding }
}

export { useCreateCreditCard }
