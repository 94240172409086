import { useQuery } from "@tanstack/react-query"
import { Communication, getResources } from "fhir"

import { useClient } from "api"
import { IMessage } from "communication/types"
import { useMemo } from "react"

const useUnreadedCommunications = (patientId: string) => {
  const { search } = useClient()
  const queryKey = ["patient/unreadcommunications", patientId]

  const { data, isLoading, refetch } = useQuery({
    queryKey,
    queryFn: async () => {
      const filters = new URLSearchParams({
        status: "in-progress",
        recipient: patientId,
        category: "message",
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/Communication`, filters })

      const communications = getResources(bundle) as Communication[]

      return { communications, count: communications.length }
    },
    meta: { context: { queryKey, patientId } },
  })

  const reloadUnreadCommunications = () => {
    refetch()
  }

  return {
    unreadedCommunications: data?.communications ?? [],
    count: data?.count ?? 0,
    isLoading,
    reloadUnreadCommunications,
  }
}

const useFilterUnreadedComms = (patientId: string, messages: IMessage[]) => {
  const { unreadedCount, unreadedCommunications } = useMemo(() => {
    const unreaded = messages.filter(
      (message) =>
        message.communication.status === "in-progress" && message.communication.recipient?.[0].id === patientId,
    )
    return { unreadedCount: unreaded.length, unreadedCommunications: unreaded }
  }, [patientId, messages])

  return { unreadedCount, unreadedCommunications }
}

export { useFilterUnreadedComms, useUnreadedCommunications }
