import { FormikValues } from "formik"
import { Context, PropsWithChildren, useState } from "react"

import { ReplaceFormContext, ReplacementProps, State } from "../hooks/useReplaceFormContext"

const ReplaceFormProvider = <T extends FormikValues>({ children }: PropsWithChildren<T>) => {
  const [replacementContent, setReplacementContent] = useState<ReplacementProps<T>>()

  const Provider = (ReplaceFormContext as Context<State<T>>).Provider
  const toogleShowReplacementContent = () =>
    setReplacementContent((content) => {
      return content && { ...content, showForm: !content.showForm }
    })

  const setReplacementInitialData = (data: T | undefined) =>
    setReplacementContent((content) => {
      return content && { ...content, initialValue: data }
    })

  const setShowReplacementContent = (show: boolean) =>
    setReplacementContent((content) => {
      return content && { ...content, showForm: show }
    })

  return (
    <Provider
      value={{
        replacementContent,
        setReplacementContent,
        toogleShowReplacementContent,
        setReplacementInitialData,
        setShowReplacementContent,
      }}
    >
      {children}
    </Provider>
  )
}

export { ReplaceFormContext, ReplaceFormProvider }
