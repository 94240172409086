const settingsKeys = {
  currentBranding: (organizationId?: string) =>
    ["current-branding", ...(organizationId ? [organizationId] : [])] as const,
  modulesVisibility: (organizationId?: string) =>
    ["modulesVisibility", ...(organizationId ? [organizationId] : [])] as const,
  purchaseSetting: (organizationId?: string) =>
    ["purchaseSetting", ...(organizationId ? [organizationId] : [])] as const,
}

export { settingsKeys }
