import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { InputText } from "primereact/inputtext"
import { classNames } from "primereact/utils"
import { ErrorMessage, Field, FieldProps } from "formik"
import { faSpinner } from "@fortawesome/pro-light-svg-icons"
import { FC, useState } from "react"

import { useCheckEmailExists } from "../hooks"
import { FormFieldBaseProps } from "./FormField"

const EmailField: FC<Props> = ({
  inputClassName,
  disabled,
  validateDuplicate,
  initialValue,
  field,
  horizontal,
  label,
  className,
}) => {
  const [exists, setExists] = useState(false)
  const { checkEmailExists, isCheckingEmail } = useCheckEmailExists(setExists)

  const validation = (value: string) => {
    const { isValid, msg } = emailValidation(value)

    if (!isValid) {
      return msg
    }

    if (validateDuplicate) {
      if (initialValue !== value && !isCheckingEmail && exists) {
        return "This email address is in use"
      }
    }

    return undefined
  }

  return (
    <Field name={field} validate={validation}>
      {({ field: { name, value, onChange }, meta: { touched, error }, form: { setFieldTouched } }: FieldProps) => (
        <div
          className={classNames(
            "field relative",
            horizontal && "inline-flex justify-between",
            !horizontal && "flex flex-col",
            className,
          )}
        >
          {label && (
            <label
              htmlFor={name}
              className={classNames("text-sm font-medium text-gray-700 mb-2", { "mr-3 mb-0 mt-2": horizontal })}
            >
              {label}
            </label>
          )}
          <InputText
            autoComplete="off"
            aria-autocomplete="none"
            type="text"
            id={name}
            name={name}
            onChange={(e) => {
              setExists(false)

              onChange(e)
            }}
            onBlur={() => {
              if (validateDuplicate) {
                const { isValid } = emailValidation(value)

                if (isValid && initialValue !== value) {
                  setFieldTouched(field, true)
                  checkEmailExists(value)
                }
              }
            }}
            value={value}
            disabled={disabled}
            className={classNames(
              "grow pr-7 h-10 p-inputtext-sm",
              { "p-invalid": touched && error, horizontal },
              inputClassName,
            )}
          />

          <div className="flex items-start h-2 mt-1">
            <small>
              {error ? (
                <ErrorMessage name={field}>{(msg) => <span className="p-error mr-2">{msg}</span>}</ErrorMessage>
              ) : isCheckingEmail ? (
                <span className="text-slate-500">
                  <FontAwesomeIcon icon={faSpinner} spin />
                  <span className="ml-1">checking availability...</span>
                </span>
              ) : exists ? (
                <span className="p-error">This email address is in use</span>
              ) : null}
            </small>
          </div>
        </div>
      )}
    </Field>
  )
}

const emailValidation = (value: string) => {
  const check = { isValid: true, msg: "" }

  if (!value) {
    check.isValid = false
    check.msg = "Email address is required"
  } else {
    if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(value)) {
      check.isValid = false
      check.msg = "Invalid email address"
    } else if (/@evexias.com/.test(value)) {
      check.isValid = false
      check.msg = "This email cannot be used"
    }
  }

  return check
}

type Props = Omit<FormFieldBaseProps<string>, "validation"> & {
  inputClassName?: string
  disabled?: boolean
  validateDuplicate?: boolean
  initialValue?: string
}

export { EmailField }
