import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/pro-light-svg-icons"

import { SkeletonLoader } from "commons"
import { useLoginContext } from "security"

import { NotificationListItem } from "./NotificationListItem"
import { useNotifications } from "../hooks/useNotifications"

const NotificationsView = () => {
  const { loggedInPatientId } = useLoginContext()

  const { isLoading, notifications } = useNotifications(loggedInPatientId)

  if (isLoading) return <SkeletonLoader repeats={4} loaderType="two-lines" />

  return (
    <div className="module-container h-full relative overflow-hidden p-3 lg:px-5 pb-0">
      {!notifications?.length ? (
        <div className="flex flex-col items-center justify-center h-full">
          <FontAwesomeIcon icon={faSearch} size="3x" className="text-slate-400" />
          <p className="text-md text-slate-400 pt-4 pb-2 place-self-center">No notifications found</p>
        </div>
      ) : (
        <div className="bg-white h-full overflow-auto pb-16">
          {notifications.map((notification, index) => (
            <NotificationListItem key={index} notification={notification} />
          ))}
        </div>
      )}
    </div>
  )
}

export { NotificationsView }
