import { Bundle, Parameters, Setting, SettingDefinition, SettingItemArray, getResources } from "fhir"

import { useClient } from "api"

const useSettingQueryFunction = () => {
  const { operationRequest } = useClient()

  return async (organizationId: string, settingId: string) => {
    const parameters = {
      resourceType: "Parameters",
      parameter: [
        { name: "owner", value: { Reference: { id: organizationId, resourceType: "Organization" } } },
        { name: "code", value: { code: settingId } },
      ],
    } as Parameters

    const bundle = await operationRequest<Bundle>({
      endpoint: "Setting",
      method: "POST",
      operation: "resolve",
      parameters,
    })
    const setting = getResources<Setting>(bundle, "Setting")?.[0]
    const settingDefinition = getResources<SettingDefinition>(bundle, "SettingDefinition")[0]

    const definitions =
      settingDefinition?.item?.reduce<SettingItemArray[]>(
        (prev, item) => [
          ...prev,
          {
            code: item.code,
            label: item.label,
            required: item.required,
            secure: item.secure,
            value: item.default,
          },
        ],
        [],
      ) ?? []

    const overrides =
      setting?.item?.reduce<Record<string, SettingItemArray>>((prev, item) => {
        return { ...prev, [item.code?.code as string]: item }
      }, {}) ?? {}

    const indexedItems =
      settingDefinition?.status !== "missing" && (!setting || setting.status !== "missing")
        ? definitions.reduce<Record<string, SettingItemArray>>(
            (prev, definition) => ({
              ...prev,
              [definition.code?.code as string]: overrides[definition.code?.code as string] ?? definition,
            }),
            {},
          )
        : {}

    const description: string = setting?.description ?? settingDefinition?.description ?? "Unspecified"

    const accessPolicy = setting?.accessPolicy ?? settingDefinition?.accessPolicy

    return {
      settingId: setting?.id,
      description,
      indexedItems,
      items: Object.values(indexedItems),
      readOnly: accessPolicy?.coding?.[0]?.code === "readonly",
    }
  }
}

export { useSettingQueryFunction }
