import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLink, faSignOut } from "@fortawesome/pro-light-svg-icons"
import { FallbackProps } from "react-error-boundary"
import { FC } from "react"
import { Button } from "primereact/button"
import { useMsal } from "@azure/msal-react"

import { CustomError } from "commons"

import { ERROR_CAUSE } from "../types"

const NotFoundErrorView: FC<Props> = ({ error, resetErrorBoundary }) => {
  const { instance } = useMsal()
  const message = error.cause?.message ?? ERROR_CAUSE.RESOURCE_NOT_FOUND
  const code = error.cause?.name ?? "404"

  const hasLogoutFlag = !!error.cause.logoutFlag

  return (
    <div className="flex items-center justify-center w-full h-full">
      <div className="text-center">
        <p className="p-error text-5xl">{code}</p>
        <h1 className="text-4xl py-2 font-bold">{ERROR_CAUSE.RESOURCE_NOT_FOUND}</h1>
        <p className="text-gray-400 pt-2 pb-4">{message}</p>
        <div className="flex flex-col justify-center w-fit m-auto">
          <Button onClick={resetErrorBoundary} className="p-button p-button-primary">
            <span>
              Go to Home <FontAwesomeIcon icon={faExternalLink} className="pl-1" />
            </span>
          </Button>
          {hasLogoutFlag && (
            <Button
              onClick={() => instance.logoutRedirect({ postLogoutRedirectUri: "/" })}
              className="p-button p-button-primary mt-2"
            >
              <span className="flex items-center flex-1 justify-between">
                Logout
                <FontAwesomeIcon icon={faSignOut} />
              </span>
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

type Props = Pick<FallbackProps, "resetErrorBoundary"> & { error: CustomError }

export { NotFoundErrorView }
