import { createContext, FC, PropsWithChildren, useState } from "react"
import { useNavigate } from "react-router-dom"

import { useLoginContext } from "security"

import { useCountCpoeOrders } from "../hooks"

const CPOEContext = createContext<State | undefined>(undefined)
CPOEContext.displayName = "CPOEContext"

const CPOEContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate()
  const { loggedInPatientId } = useLoginContext()
  const { ordersCount, refreshCpoeCount, isLoading: isLoadingOrdersCount } = useCountCpoeOrders(loggedInPatientId)

  const INITIAL_STATE = {
    loading: false,
    disabled: false,
    updatingAddress: false,
    addressChanged: false,
  }

  const [checkoutState, setCheckoutState] = useState(INITIAL_STATE)
  const [submitAddress, setSubmitAddress] = useState<() => void>()

  const resetCheckoutState = () => setCheckoutState(INITIAL_STATE)

  const gotoShop = () => navigate("shop")
  const gotoCheckout = () => navigate("checkout")

  return (
    <CPOEContext.Provider
      value={{
        ordersCount,
        isLoadingOrdersCount,
        refreshCpoeCount,
        checkoutState,
        setCheckoutState,
        resetCheckoutState,
        gotoShop,
        gotoCheckout,
        submitAddress,
        setSubmitAddress,
      }}
    >
      {children}
    </CPOEContext.Provider>
  )
}

type CheckoutState = {
  loading: boolean
  disabled: boolean
  updatingAddress: boolean
  addressChanged: boolean
}

type State = {
  ordersCount: number
  isLoadingOrdersCount: boolean
  refreshCpoeCount(): void
  checkoutState: CheckoutState
  setCheckoutState(state: CheckoutState): void
  resetCheckoutState(): void
  gotoShop(): void
  gotoCheckout(): void
  submitAddress?(): void
  setSubmitAddress(fn: () => void): void
}

export { CPOEContextProvider, CPOEContext }
