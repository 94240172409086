import { useSuspenseQuery } from "@tanstack/react-query"
import { useMemo } from "react"

import { ThemeDefinition } from "../types"
import { settingsKeys } from "../query-keys"
import { useSettingQueryFunction } from "./useSetting"

const useBranding = (organizationId: string) => {
  const getSetting = useSettingQueryFunction()
  const queryKey = settingsKeys.currentBranding(organizationId)

  const { data, isError, error } = useSuspenseQuery({
    queryKey,
    queryFn: async () => await getSetting(organizationId, "branding"),
    meta: { context: { queryKey, organizationId } },
  })

  const { branding } = useMemo(() => {
    const branding: ThemeDefinition & { ehrUrl: string | undefined } = {
      primaryColor: data?.indexedItems?.["primary-color"]?.value?.string,
      secondaryColor: data?.indexedItems?.["secondary-color"]?.value?.string,
      iconLogo: data?.indexedItems?.["icon-logo"]?.value?.uri,
      logo: data?.indexedItems?.["logo"]?.value?.uri,
      banner: data?.indexedItems?.["banner"]?.value?.uri,
      ehrUrl: data?.indexedItems?.["ehr-url"]?.value?.string,
    }

    return { branding }
  }, [data])

  if (isError) {
    throw error
  }

  return {
    branding,
  }
}

export { useBranding }
