const medicationRequestsKeys = {
  all: ["patient/medicationRequests"] as const,
  withPatientId: (patientId: string) => [...medicationRequestsKeys.all, patientId] as const,
  withStatusFilter: (patientId: string, statusFilter: string[]) =>
    [...medicationRequestsKeys.withPatientId(patientId), statusFilter] as const,
  withStatusAndSearchFilter: (patientId: string, statusFilter: string[], searchFilter?: string) =>
    [...medicationRequestsKeys.withStatusFilter(patientId, statusFilter), searchFilter] as const,
  withFiltersAndPagination: (
    patientId: string,
    statusFilter: string[],
    searchFilter?: string,
    perPage?: number,
    page?: number,
  ) =>
    [
      ...medicationRequestsKeys.withStatusAndSearchFilter(patientId, statusFilter, searchFilter),
      perPage,
      page,
    ] as const,
}

const draftMedicationRequestKeys = {
  all: ["patient/medicationRequests"] as const,
  withPatientId: (patientId: string) => [...draftMedicationRequestKeys.all, patientId] as const,
}

const medicationCatalogsKeys = {
  all: ["medicationCatalogs"] as const,
}

const medicationKnowledgeKeys = {
  all: ["medicationKnowledge"] as const,
  withFilters: (catalogId: string, filter: string, ignoredMedCodes: string) =>
    [...medicationKnowledgeKeys.all, catalogId, filter, ignoredMedCodes] as const,
}

const medicationOrderKeys = {
  all: ["patient-medication-orders"] as const,
  withPatientId: (patientId: string) => [...medicationOrderKeys.all, patientId] as const,
  withStatusAndSearchFilter: (patientId: string, statusFilter: string[], searchText?: string) =>
    [...medicationOrderKeys.withPatientId(patientId), statusFilter, searchText] as const,
  detail: (orderId: string) => ["medication-order-details", orderId] as const,
}

const monographKeys = { all: ["monograph"] as const, detail: (id?: string) => [...monographKeys.all, id] }

export {
  medicationRequestsKeys,
  draftMedicationRequestKeys,
  medicationCatalogsKeys,
  medicationKnowledgeKeys,
  medicationOrderKeys,
  monographKeys,
}
