const patientDocumentsCategoryOptions = [
  {
    label: "Medication Summary Document",
    value: { code: "56445-0", system: "http://loinc.org", display: "Medication Summary Document" },
  },
  {
    label: "Personal health monitoring report Document",
    value: { code: "53576-5", system: "http://loinc.org", display: "Personal health monitoring report Document" },
  },
  { label: "Plan of care note", value: { code: "56447-6", system: "http://loinc.org", display: "Plan of care note" } },
  {
    label: "Diagnostic imaging study",
    value: { code: "18748-4", system: "http://loinc.org", display: "Diagnostic imaging study" },
  },
  {
    label: "Surgical operation note",
    value: { code: "11504-8", system: "http://loinc.org", display: "Surgical operation note" },
  },
  { label: "Referral note", value: { code: "57133-1", system: "http://loinc.org", display: "Referral note" } },
]

export { patientDocumentsCategoryOptions }
