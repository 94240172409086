import visa from "images/visa.svg"
import masterCard from "images/mastercard.svg"
import americanExpress from "images/amex.svg"
import discover from "images/discover.svg"
import dinersClub from "images/dinersclub.svg"
import jcb from "images/jcb.svg"
import genericCard from "images/gcard.svg"
import { CreditCardType } from "../types"

const CreditCardImage = ({ creditCardType }: Props) => {
  const cardImg: Record<CreditCardType, string> = {
    AE: americanExpress,
    MC: masterCard,
    V: visa,
    D: discover,
    DC: dinersClub,
    JCB: jcb,
  }
  return (
    <img
      src={cardImg[creditCardType] ?? genericCard}
      alt="creditCard.type"
      className="h-12 rounded-lg"
      title="Card type"
    />
  )
}

type Props = {
  creditCardType: CreditCardType
}

export { CreditCardImage }
