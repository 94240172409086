import { faWarning } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"

type TAlertBeforeUnload = {
  visible?: boolean
  cancel?: () => void
  proceed?: () => void
  proceedLoading?: boolean
}

export const AlertBeforeUnload = ({ cancel, proceed, visible = false, proceedLoading }: TAlertBeforeUnload) => {
  const handleCancel = () => cancel?.()

  const handleProceed = () => proceed?.()

  const footerContent = (
    <>
      <Button
        disabled={proceedLoading}
        label="No"
        icon="pi pi-times"
        onClick={handleCancel}
        className="p-button-text"
      />
      <Button loading={proceedLoading} label="Yes" icon="pi pi-check" onClick={handleProceed} autoFocus />
    </>
  )

  return (
    <Dialog
      header={
        <>
          <FontAwesomeIcon size="lg" icon={faWarning} color="#cc8925" /> Warning
        </>
      }
      closeOnEscape
      modal
      focusOnShow={false}
      closable={false}
      visible={visible}
      style={{ width: "50vw" }}
      onHide={() => {}}
      footer={footerContent}
    >
      <p className="m-0">You are making a call, if you reload the page it will hang up immediately.</p>
    </Dialog>
  )
}
