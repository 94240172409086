import { useContext } from "react"

import { CustomError } from "commons"
import { registerErrorTrace } from "logger"

import { HomeContext } from "../context/HomeProvider"

const useHomeContext = () => {
  const context = useContext(HomeContext)

  if (context === undefined) {
    throw registerErrorTrace(new Error("useHomeContext must be used within a HomeProvider") as CustomError)
  }

  return context
}

export { useHomeContext }
