import { Patient } from "fhir"
import { humanNameAsString } from "fhir/utils"
import { format, parseISO } from "date-fns"
import { useState } from "react"

import { AvatarImage, BirthdateField, GenderField, InputField, useAzureContainer } from "commons"
import { ProfileSection, formatsByTypes } from "data"
import { useLoginContext } from "security"

import { usePatchPatient } from "../hooks"
import { patientValidationSchema } from "./validations"
import { InformationCard } from "./InformationCard"
import { ProfileImageField } from "./ProfileImageField"

const BasicInformation = () => {
  const { loggedInPatient, loggedInPatientId } = useLoginContext()
  const { patchPatient, isPatching } = usePatchPatient()

  const initialValues = {
    id: loggedInPatient.id,
    photo: loggedInPatient.photo,
    name: loggedInPatient.name,
    birthDate: loggedInPatient.birthDate,
    gender: loggedInPatient.gender,
  }

  const { id, photo, name, birthDate, gender } = initialValues
  const formattedBirthday = birthDate ? format(parseISO(birthDate), formatsByTypes.LONG_DATE) : "Unknown"

  // We create a temp file to store the image before uploading it to Azure
  const [profileImageTempFile, setProfileImageTempFile] = useState<{ file: Blob; fileUrl: string }>({
    file: new Blob(),
    fileUrl: photo?.[0]?.url || "",
  })

  // Use hook to upload the image to Azure
  const { uploadFile, isUploading } = useAzureContainer("patient-photos")

  // When the form is submitted, we upload the image to Azure if the image is not the default one (url = "")
  // and then patch the patient with the new image url
  const onSubmit = async (patientData: Partial<Patient>) => {
    // If the image is different from the current one, we upload it to Azure and then patch the patient with the new url
    profileImageTempFile.fileUrl !== photo?.[0]?.url
      ? await uploadFile(new File([profileImageTempFile.file], `${id}.png`)).then((url) => {
          patientData.photo = [{ url }]
        })
      : (patientData.photo = [{ url: profileImageTempFile.fileUrl }])
    patchPatient({ patientId: loggedInPatientId, patientData: { ...patientData, meta: loggedInPatient.meta } })
  }

  // We check if the form is submitting by checking if the patient is patching or uploading the image
  const isSubmitting = isPatching || isUploading

  return (
    <InformationCard
      id={ProfileSection.BASIC}
      title="Basic Information"
      className="profile-card-section"
      data={{
        Photo: (
          <div className="relative w-12 h-12 lg:w-16 lg:h-16 rounded-full overflow-hidden">
            <AvatarImage
              photoUrl={photo?.[0]?.url}
              name={humanNameAsString(name?.[0])}
              className="w-12 h-12 lg:w-16 lg:h-16"
            />
          </div>
        ),
        Name: humanNameAsString(name?.[0]),
        Birthdate: formattedBirthday,
        "Biological Sex": gender ?? "Unknown",
      }}
      initialValue={initialValues}
      validationSchema={patientValidationSchema}
      isUpdating={isSubmitting}
      onSubmit={onSubmit}
    >
      <div className="2xl:flex 2xl:flex-col 2xl:flex-nowrap gap-2 space-y-2">
        <ProfileImageField
          /* properties for setting and getting  the tempImage */
          profileImageTempFile={profileImageTempFile.fileUrl}
          setProfileImageTempFile={setProfileImageTempFile}
        />
        <div className="flex flex-col flex-1 gap-2">
          <InputField field="name[0].given[0]" inputClassName="h-10" label="First Name" />
          <InputField field="name[0].family" inputClassName="h-10" label="Last Name" />
          <BirthdateField field="birthDate" label="Birthdate" />
          <GenderField field="gender" label="Biological Sex" />
        </div>
      </div>
    </InformationCard>
  )
}

export { BasicInformation }
