import { AccountBETACreditCardArray, ResourceObject } from "fhir"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { displayNotificationSuccess } from "utils"
import { registerErrorTrace } from "logger"
import { useLoginContext } from "security"
import { CustomError } from "commons"

import { useBasisTheoryInstance } from "./useBasisTheoryInstance"
import { useAuthorizeBasisTheorySession } from "./useAuthorizeBasisTheorySession"
import { creditCardsKeys, accountKeys } from "../query-keys"

const useDeleteCreditCard = (hideForm: () => void) => {
  const { loggedInPatientId } = useLoginContext()
  const { patch } = useClient()
  const queryClient = useQueryClient()
  const { bt } = useBasisTheoryInstance()
  const { authorize } = useAuthorizeBasisTheorySession()

  const removeCreditCardToken = async (tokenId: string) => {
    if (!bt) return

    const { sessionKey, nonce } = await bt.sessions.create()
    await authorize({ nonce })

    return bt.tokens.delete(tokenId, { apiKey: sessionKey })
  }

  const updatedCreditCard = async ({
    index,
    accountId,
    creditCardList,
    defaultCreditCardId,
  }: {
    index: number
    accountId: string
    creditCardList: AccountBETACreditCardArray[]
    defaultCreditCardId: string
  }) => {
    const isDefaultCC = defaultCreditCardId === `${creditCardList[index].type}|${creditCardList[index].last4Digits}`
    const patchOps: {
      op: string
      path: string
      value?: AccountBETACreditCardArray[]
    }[] = [
      {
        op: "replace",
        path: "/creditCard",
        value: [...creditCardList.slice(0, index), ...creditCardList.slice(index + 1)],
      },
    ]

    if (isDefaultCC) {
      patchOps.push({ op: "remove", path: "/defaultCreditCard" })
    }

    await patch("Account", accountId, patchOps as ResourceObject)

    return removeCreditCardToken(creditCardList[index].pciToken as string)
  }

  const { mutate: removeCreditCard, isPending: isDeleting } = useMutation({
    mutationFn: updatedCreditCard,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: accountKeys.withPatientId(loggedInPatientId) })
      await queryClient.invalidateQueries({ queryKey: creditCardsKeys.withPatientId(loggedInPatientId) })

      displayNotificationSuccess("Credit card deleted successfully!")
    },
    onSettled: hideForm,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
  })

  return { removeCreditCard, isDeleting }
}

export { useDeleteCreditCard }
