import { useContext } from "react"

import { CustomError } from "commons"
import { registerErrorTrace } from "logger"

import { CPOEContext } from "../context/CPOEProvider"

const useCPOEContext = () => {
  const context = useContext(CPOEContext)

  if (context === undefined) {
    throw registerErrorTrace(new Error("Couldn't create CPOE context") as CustomError)
  }

  return context
}

export { useCPOEContext }
