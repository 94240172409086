import { classNames } from "primereact/utils"
import { CodeableConcept, Coding } from "fhir"
import { FieldProps } from "formik"
import { Dropdown } from "primereact/dropdown"
import { FC } from "react"

import { FormField, FormFieldBaseProps } from "./FormField"

const CodeableConceptField: FC<Props> = ({ codes, autoSelect, required, ...fieldProps }) => {
  const firstOption = autoSelect || codes?.length === 1 ? codes[0] : undefined

  return (
    <FormField {...fieldProps}>
      {({ field: { name, value }, form: { setFieldValue }, meta: { touched, error } }: FieldProps) => (
        <Dropdown
          id={name}
          name={name}
          optionLabel="display"
          options={codes}
          required={required}
          onChange={({ value }) => {
            setFieldValue(name, { coding: [value], text: value.display } as CodeableConcept)
          }}
          value={value?.coding?.[0] ?? firstOption}
          className={classNames({ "p-invalid": touched && error }, "h-10 p-inputtext-sm")}
        />
      )}
    </FormField>
  )
}

type Props = FormFieldBaseProps<Coding> & {
  codes: Coding[]
  autoSelect?: boolean
  required?: boolean
}

export { CodeableConceptField }
