import { ConfirmDialog } from "primereact/confirmdialog"
import { SelectButton } from "primereact/selectbutton"
import { Skeleton } from "primereact/skeleton"
import { classNames } from "primereact/utils"
import { startTransition, useReducer } from "react"
import { useWindowSize } from "react-use"

import { SearchWithStatus, SkeletonLoader, useChargeItemDefinitions, useSidebarContext } from "commons"
import { medicationRequestStatus } from "data"
import { useCPOEContext } from "orders"
import { useLoginContext } from "security"
import { displayActionNotification, MOBILE_SCREEN_MAX } from "utils"

import { useMedicationRequestDataBind, useMedicationRequests } from "commons/meds"
import { refreshMR, stopMR } from "../../data"
import { useCreateMedicationRequest, useStopMedicationRequest } from "../../hooks"
import { Contents, MedicationRequestInfo } from "../../types"
import { getRenewedMR } from "../validations"
import { MedicationRequestList } from "./MedicationRequestList"

const MedicationRequestDataContainer = ({ activeContent, contentOptions, onUpdateContent }: Props) => {
  const { width } = useWindowSize()
  const {
    statusFilter,
    searchFilter,
    page,
    perPage,
    itemActionClicked,
    onActionCliked,
    changePage,
    changePerPage,
    updateFilters,
    updateSearchText,
  } = useReducerState()

  const { loggedInPatientId } = useLoginContext()
  const { reloadMedications, organizationId, purchaseEnabled } = useSidebarContext()
  const { gotoShop, gotoCheckout, refreshCpoeCount } = useCPOEContext()

  const {
    medicationRequests,
    medicationDispenses,
    medicationKnowledges,
    medCodes,
    isLoading,
    total: totalMR,
    hasNextPage,
    fetchNextPage,
  } = useMedicationRequests(loggedInPatientId, "nutraceutical", statusFilter, perPage, page, searchFilter)
  const { chargeItemDefinitions } = useChargeItemDefinitions(organizationId, {
    billToPatientCIDs: medCodes,
  })
  const { medicationRequestData } = useMedicationRequestDataBind(
    medicationRequests,
    medicationKnowledges,
    chargeItemDefinitions.billToPatientCIDs,
    medicationDispenses,
  )

  const { stopMedicationRequest, isLoading: isStopping } = useStopMedicationRequest(
    (meds) => {
      refreshMR(meds[0], medicationRequestData ?? [])
      reloadMedications()
    },
    () => onActionCliked(""),
  )
  const { createMedicationReq, isAdding } = useCreateMedicationRequest(
    () => {
      refreshCpoeCount()
      reloadMedications()
    },
    () =>
      displayActionNotification({
        message: "Medication renewed successfully!",
        actions: [
          { label: "Go to shop", onClick: gotoShop },
          { label: "Begin checkout", onClick: gotoCheckout },
        ],
      }),
  )

  const stop = (medReqId: string) => {
    stopMR(medReqId, () => {
      onActionCliked(medReqId)
      stopMedicationRequest(medReqId)
    })
  }

  const renew = (medicationRequest: MedicationRequestInfo) => {
    onActionCliked(medicationRequest.id as string)
    const renewedMR = {
      ...medicationRequest,
      dispenseRequest: {
        ...medicationRequest.dispenseRequest,
        numberOfRepeatsAllowed: medicationRequest.dispenseRequest?.validityPeriod?.end === undefined ? undefined : 0,
      },
    }

    createMedicationReq(getRenewedMR(renewedMR))
  }

  const loader = () => (
    <div className="flex flex-col h-full">
      <SkeletonLoader
        repeats={3}
        containerClassName="p-5 overflow-auto h-full"
        loaderType="list"
        skeletonShape="rectangle"
        skeletonSize="6rem"
        extraLine
      />
      {width > MOBILE_SCREEN_MAX && <Skeleton width="50%" height="2.5rem" className="mb-3 self-center" />}
    </div>
  )

  return (
    <div className="w-full h-full bg-white flex flex-col p-3 lg:px-5 pb-0">
      <div className="flex flex-wrap justify-center md:inline-flex md:justify-between md:h-12 w-full mb-3">
        <SelectButton
          value={activeContent}
          options={contentOptions}
          optionLabel="name"
          optionValue="value"
          allowEmpty={false}
          className="mb-3 md:mb-0 w-full md:w-auto inline-flex md:block sm-buttons-w-full"
          onChange={(e) => onUpdateContent(e.value)}
        />
        <SearchWithStatus
          placeholder="Search medications"
          options={medicationRequestStatus}
          selectedItems={statusFilter}
          onStatusCheck={updateFilters}
          onSearch={(filter) => {
            startTransition(() => {
              updateSearchText(filter ?? "")
            })
          }}
          className="p-fluid w-full md:w-2/3 lg:1/3 xl:max-w-max justify-end"
        />
      </div>
      <>
        {isLoading && loader()}
        <MedicationRequestList
          data={medicationRequestData}
          page={page}
          perPage={perPage}
          totalMR={totalMR}
          className={classNames("data-container", {
            empty: !totalMR,
            hidden: isLoading,
          })}
          onPerPageChange={changePerPage}
          onPageChange={changePage}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          itemWaitingForAction={isStopping || isAdding ? itemActionClicked : undefined}
          medicationRequestActions={{ stop, renew: purchaseEnabled ? renew : undefined }}
        />
      </>
      <ConfirmDialog />
    </div>
  )
}

type State = {
  activeContent: Contents
  statusFilter: Array<string>
  searchFilter?: string
  perPage: number
  page: number
  itemActionClicked?: string
}

const initialState = {
  activeContent: "medications",
  statusFilter: ["active", "completed"],
  searchFilter: undefined,
  perPage: 20,
  page: 1,
  itemActionClicked: "",
} as State

const reducer = (
  state: State,
  {
    type,
    payload,
  }: {
    type:
      | "action-cliked"
      | "active-content"
      | "active-filters"
      | "change-per-page"
      | "change-page"
      | "update-search-filter"
    payload: Array<string> | Contents | number | string | undefined
  },
) => {
  switch (type) {
    case "action-cliked":
      return { ...state, itemActionClicked: payload as string }
    case "active-content":
      return {
        ...state,
        activeContent: payload as Contents,
      }
    case "active-filters":
      return {
        ...state,
        statusFilter: payload as string[],
      }
    case "change-per-page":
      return {
        ...state,
        perPage: payload as number,
      }
    case "change-page":
      return {
        ...state,
        page: payload as number,
      }
    case "update-search-filter":
      return {
        ...state,
        searchFilter: payload as string,
      }
    default:
      return state
  }
}

const useReducerState = () => {
  const [{ activeContent, statusFilter, searchFilter, perPage, page, itemActionClicked }, dispatch] = useReducer(
    reducer,
    initialState,
  )

  const onActionCliked = (medicationId: string) => {
    dispatch({ type: "action-cliked", payload: medicationId })
  }

  const updateContent = (content: Contents) => {
    dispatch({ type: "active-content", payload: content })
  }

  const updateFilters = (filters: string[]) => {
    dispatch({ type: "active-filters", payload: filters })
  }

  const changePerPage = (perPage: number) => {
    dispatch({ type: "change-per-page", payload: perPage })
  }

  const changePage = (page: number) => {
    dispatch({ type: "change-page", payload: page })
  }

  const updateSearchText = (filter: string) => {
    dispatch({ type: "update-search-filter", payload: filter })
  }

  return {
    activeContent,
    statusFilter,
    searchFilter,
    perPage,
    page,
    itemActionClicked,
    onActionCliked,
    updateContent,
    updateFilters,
    updateSearchText,
    changePerPage,
    changePage,
  }
}

type Props = {
  activeContent: Contents
  contentOptions: { name: string; value: Contents }[]
  onUpdateContent(activeContent: Contents): void
}

export { MedicationRequestDataContainer }
