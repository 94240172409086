import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { CustomError } from "commons"
import { displayNotificationSuccess } from "utils"
import { registerErrorTrace } from "logger"
import { cpoeOrdersKeys } from "orders"

import { draftMedicationRequestKeys } from "../query-keys"

const useDeleteMedicationRequest = (onSuccess?: (medicationRequestId: string) => void, onSettled?: () => void) => {
  const { remove } = useClient()
  const queryClient = useQueryClient()

  const delMR = async (medicationRequestId: string) => {
    await remove("MedicationRequest", medicationRequestId)
    return { medicationRequestId }
  }

  const { mutate: deleteMedicationRequest, isPending: isDeleting } = useMutation({
    mutationFn: delMR,
    onError: (error: CustomError, medicationRequestId) => {
      displayNotificationError(registerErrorTrace(error, { medicationRequestId }))
    },
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: draftMedicationRequestKeys.all })
      data && onSuccess?.(data.medicationRequestId)
      displayNotificationSuccess("Medication discarded successfully!")
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: cpoeOrdersKeys.all })
      onSettled?.()
    },
  })

  return { deleteMedicationRequest, isDeleting }
}

export { useDeleteMedicationRequest }
