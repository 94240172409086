import { Button } from "primereact/button"
import { FC } from "react"

const NotificationWarningUpdateAvailable: FC<Props> = ({ message, onClose }) => (
  <div className="flex flex-col space-y-2 divide-y divide-gray-300">
    <p className="font-bold text-gray-700">Update Available</p>
    <p className="py-2">{message}</p>
    <span className="w-full flex justify-end pt-3">
      <Button
        className="p-button-sm p-button-outlined"
        label="Update"
        onClick={() => {
          onClose?.()
          window.location.reload()
        }}
      />
    </span>
  </div>
)

type Props = { message: string; onClose?: () => void }

export { NotificationWarningUpdateAvailable }