import { faCreditCard } from "@fortawesome/pro-regular-svg-icons"
import { faCalendarCheck, faPlus, faStar, faUser } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Account, AccountBETACreditCardArray } from "fhir"

import {
  Button,
  ConfirmDialog,
  DataContainerSlideoverForm,
  InformationCardContainer,
  SkeletonLoader,
  StackedListContainer,
  StackedListItemProps,
  useCrudReducer,
} from "commons"
import { ProfileSection } from "data"
import { useLoginContext } from "security"
import { formatCreditCardNumber } from "utils"
import { defaultEditRemoveMenu } from "utils-renders"

import {
  useCreateCreditCard,
  useCreditCards,
  useDeleteCreditCard,
  useUpdateCreditCard,
  useUpdateDefaultCreditCard,
} from "../hooks"
import { CreditCardType } from "../types"
import { CreditCardForm } from "./CreditCardForm"
import { CreditCardImage } from "./CreditCardImage"
import { creditCardValidationSchema, getInitialValues } from "./validations"

const CreditCardContainer = () => {
  const { loggedInPatient, loggedInPatientId } = useLoginContext()
  const { initialValue, showSlide, isNew, editIndex, deleteIndex, reset, add, editWithIndex, setDeleteIndex } =
    useCrudReducer({ defaultEntity: getInitialValues(loggedInPatient) })

  const { accountId, creditCards, defaultCreditCard, isLoading, isFetching, account } = useCreditCards(
    loggedInPatientId as string,
  )

  const { createCreditCard } = useCreateCreditCard(reset)
  const { updateCreditCard } = useUpdateCreditCard(reset)
  const { removeCreditCard, isDeleting } = useDeleteCreditCard(() => setDeleteIndex())
  const { updateDefaultCreditCard, isUpdating } = useUpdateDefaultCreditCard(reset)

  const onSubmit = (creditCard: AccountBETACreditCardArray) => {
    isNew
      ? createCreditCard({
          creditCard: creditCard,
          creditCardList: creditCards,
          account: account as Account,
        })
      : updateCreditCard({
          creditCard: creditCard,
          account: account,
          creditCardList: creditCards,
          index: editIndex as number,
        })
  }

  const getCCid = (creditCard: AccountBETACreditCardArray) => `${creditCard.type}|${creditCard.last4Digits}`

  return (
    <InformationCardContainer
      id={ProfileSection.CC}
      title="Credit Cards"
      showEdit={creditCards.length > 0}
      customButton={<Button buttonStyle="default" label="Add new" icon={faPlus} onClick={add} />}
      className="profile-card-section"
    >
      {isLoading ? (
        <SkeletonLoader repeats={1} loaderType="two-lines" />
      ) : (
        <>
          <DataContainerSlideoverForm
            hasData={creditCards.length > 0}
            showSlide={showSlide}
            formTitle="Credit Card"
            iconDataNotFound={faCreditCard}
            formInitialValue={initialValue}
            validationSchema={creditCardValidationSchema}
            onSubmit={onSubmit}
            onCancel={reset}
            form={<CreditCardForm isEditing={!isNew} />}
            showAddButton={false}
            onButtonAddClick={add}
          >
            <StackedListContainer
              itemPadding
              data={creditCards}
              itemModelBuilder={(item, index) =>
                modelBuilder(
                  item,
                  defaultCreditCard === getCCid(item),
                  isUpdating || isFetching,
                  () => {
                    updateDefaultCreditCard({
                      creditCardId: getCCid(item),
                      account: account as Account,
                    })
                  },
                  () => editWithIndex(item, index),
                  () => setDeleteIndex(index),
                )
              }
            />
          </DataContainerSlideoverForm>
          <ConfirmDialog
            confirmText="Are you sure you want to remove this credit card?"
            actionName="Remove"
            visible={deleteIndex !== undefined || isDeleting}
            isLoading={isDeleting}
            onConfirm={() =>
              removeCreditCard({
                index: deleteIndex as number,
                accountId: accountId as string,
                creditCardList: creditCards,
                defaultCreditCardId: defaultCreditCard as string,
              })
            }
            hideDialog={() => setDeleteIndex()}
          />
        </>
      )}
    </InformationCardContainer>
  )
}

const isExpired = (creditCard: AccountBETACreditCardArray) =>
  new Date(parseInt(creditCard?.expirationYear as string), parseInt(creditCard?.expirationMonth as string), 1) <
  new Date()

const modelBuilder = (
  creditCard: AccountBETACreditCardArray,
  isPreferred: boolean,
  isUpdating: boolean,
  makePreferred: () => void,
  edit: () => void,
  remove: () => void,
): StackedListItemProps => ({
  leftData: [
    {
      lineItems: [{ name: "Number", value: formatCreditCardNumber(creditCard.last4Digits as string, creditCard.type) }],
    },
    {
      lineItems: [
        { name: "Holder name", value: creditCard.cardHolderName ?? "Unspecified", icon: faUser },
        {
          name: "Expiration date",
          value: `${creditCard.expirationMonth?.padStart(2, "0")}-${creditCard.expirationYear}`,
          icon: faCalendarCheck,
        },
        ...(isPreferred ? [{ name: "Preferred credit card", value: "Preferred", icon: faStar }] : []),
      ],
    },
  ],
  isLoading: isUpdating,
  menu: [
    ...(isPreferred
      ? []
      : [
          {
            label: "Preferred",
            icon: <FontAwesomeIcon icon={faStar} size="sm" className="mr-2" />,
            command: makePreferred,
          },
        ]),
    ...defaultEditRemoveMenu({ onEdit: edit, onDelete: remove }),
  ],
  image: <CreditCardImage creditCardType={creditCard.type as CreditCardType} />,
  badge: isExpired(creditCard) ? { text: "expired", colorStyle: "red" } : undefined,
})

export { CreditCardContainer }
