import { useQuery } from "@tanstack/react-query"

import { useClient } from "api"

import { medicationRequestsKeys } from "../query-keys"

const useCountMedicationRequests = (patientId: string, statusFilter: string[]) => {
  const { search } = useClient()
  const queryKey = medicationRequestsKeys.withStatusFilter(patientId, statusFilter)

  const { data, isLoading, refetch } = useQuery({
    queryKey,
    queryFn: async () => {
      const filters = new URLSearchParams({
        _query: "patient-prescriptions",
        _patient: patientId,
        ...(statusFilter.length > 0 ? { _status: statusFilter.join(",") } : {}),
        category: "nutraceutical",
        _count: "0",
        _total: "count",
        _summary: "count",
      })

      const bundle = await search({ endpoint: "MedicationRequest", filters })

      return { medicationRequestsCount: bundle.total }
    },
    meta: { context: { queryKey, patientId } },
  })

  const reloadMedications = () => {
    refetch()
  }

  return {
    medicationRequestsCount: data?.medicationRequestsCount ?? 0,
    isLoading,
    reloadMedications,
  }
}

export { useCountMedicationRequests }
