import { useMutation } from "@tanstack/react-query"
import { Bundle, ChargeItem, CodeableConcept, getResource, getResources, Invoice, Parameters } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { getCommonCode, getDiscountPrice, getFeePrice, getTaxPrice } from "utils"

import { CustomInvoiceData, InvoiceItem } from "../types"
import {
  LINE_ITEM_DISCOUNT_SYSTEM,
  LINE_ITEM_FEE_SYSTEM,
  LINE_ITEM_PRODUCT_FEE_SYSTEM,
  LINE_ITEM_SHIPPING_METHOD_SYSTEM,
  PRODUCT_CODE_SYSTEM,
} from "../utils"

const useIvoicePreview = (patientId: string, onSettled?: () => void, onError?: (error: CustomError) => void) => {
  const { operationRequest } = useClient()

  const generate = async () => {
    const parametersList = (await operationRequest({
      endpoint: `Patient/${patientId}/cpoe`,
      method: "GET",
      operation: "invoice-preview",
    })) as Parameters[]

    return parametersList?.reduce<Array<InvoiceData>>((acc, { parameter }) => {
      const invoiceBundle = parameter?.find((p) => p.name === "invoice-bundle")?.resource as Bundle

      if (!invoiceBundle) {
        return acc
      }

      const invoice = getResource<Invoice>(invoiceBundle, "Invoice")
      const chargeItems = getResources<ChargeItem>(invoiceBundle, "ChargeItem")
      const summary = parameter?.find((p) => p.name === "summary")?.resource as Parameters

      return [...acc, { invoice, chargeItems, summary }]
    }, [])
  }

  const {
    mutate: invoicePreview,
    isPending: isLoadingPreview,
    data,
    error,
  } = useMutation({
    mutationFn: generate,
    onSettled,
    onError: (error: CustomError, patientId) => {
      displayNotificationError(registerErrorTrace(error, { finish: "CPOE invoice-preview", patientId }))

      onError?.(error)
    },
  })

  const customData = useMemo(() => {
    return data?.map(({ invoice, summary }) => {
      const initialData: CustomInvoiceData = {
        items: {} as Record<string, InvoiceItem[]>,
        fees: [],
        feesSubTotal: 0,
        shippingMethods: {} as Record<string, InvoiceItem[]>,
        shippingMethodSubtotal: 0,
        taxes: 0,
        itemsSubtotal: 0,
        discount: 0,
        productFeesSubtotal: 0,
      }

      const itemData = invoice.lineItem?.reduce((result, item) => {
        const productType = getProductCode(item.productCode)

        if (item.chargeItem?.Reference) {
          const descriptionUnit = item.priceComponent?.[0].code?.coding?.[0].code
            ? ` (${item.priceComponent?.[0].code?.coding?.[0].code})`
            : ""

          if (productType)
            result.items = {
              ...result.items,
              ...{
                [productType]: [
                  ...(result.items[productType] ?? []),
                  {
                    productId: getCommonCode(item.productCode?.[1]?.coding),
                    productType,
                    description: `${item.chargeItem.Reference.display as string}${descriptionUnit}`,
                    qty: item.priceComponent?.[0].factor,
                    price: item.priceComponent?.[0].amount?.value as number,
                  },
                ],
              },
            }
          result.itemsSubtotal += (item.priceComponent?.[0].factor ?? 0) * (item.priceComponent?.[0].amount?.value ?? 0)
        }

        if (item.chargeItem?.CodeableConcept?.coding?.some((ci) => ci.system === LINE_ITEM_FEE_SYSTEM)) {
          const productType = getProductCode(item.productCode)
          const price = getFeePrice(item.priceComponent)?.amount?.value ?? 0

          if (item.chargeItem?.CodeableConcept?.coding?.some((ci) => ci.system === LINE_ITEM_SHIPPING_METHOD_SYSTEM)) {
            if (productType)
              result.shippingMethods = {
                ...result.shippingMethods,
                ...{
                  [productType]: [
                    ...(result.shippingMethods[productType] ?? []),
                    {
                      description: item?.chargeItem?.CodeableConcept?.coding?.[0].display as string,
                      price,
                      productType,
                    },
                  ],
                },
              }
            result.shippingMethodSubtotal += price
          } else if (
            item.chargeItem?.CodeableConcept?.coding?.some((ci) => ci.system === LINE_ITEM_PRODUCT_FEE_SYSTEM)
          ) {
            const procedureCode = getCommonCode(item.chargeItem.CodeableConcept.coding)
            result.productFees = {
              ...(result.productFees ?? {}),
              [procedureCode]: [
                ...(result.productFees?.[procedureCode] ?? []),
                {
                  description: `Additional Fee (${item?.chargeItem?.CodeableConcept?.coding?.[0].display as string})`,
                  price,
                  productType,
                },
              ],
            }
            result.productFeesSubtotal += price
          } else {
            result.fees = [
              ...result.fees,
              {
                description: item?.chargeItem?.CodeableConcept?.coding?.[0].display as string,
                price,
              },
            ]
            result.feesSubTotal += price
          }
        }

        if (item.chargeItem?.CodeableConcept?.coding?.[0].code === "estimated-taxes") {
          result.taxes += getTaxPrice(item.priceComponent)?.amount?.value ?? 0
        }

        if (item.chargeItem?.CodeableConcept?.coding?.[0].system === LINE_ITEM_DISCOUNT_SYSTEM) {
          const price = getDiscountPrice(item.priceComponent)?.amount?.value ?? 0
          result.discount += price

          const productType = getProductCode(item.productCode)
          if (productType)
            result.discounts = {
              ...result.discounts,
              ...{
                [productType]: [
                  ...(result.discounts?.[productType] ?? []),
                  {
                    description: item?.chargeItem?.CodeableConcept?.coding?.[0].display as string,
                    price,
                    productType,
                  },
                ],
              },
            }
        }

        return result
      }, initialData)

      return { ...itemData, invoice, summary }
    })
  }, [data])

  return { invoicePreview, isLoadingPreview, invoiceData: customData, error }
}

const getProductCode = (productCodes?: CodeableConcept[]) => {
  return productCodes?.find((cc) => cc.coding?.find((code) => code.system === PRODUCT_CODE_SYSTEM))?.coding?.[0].code
}

type InvoiceData = { invoice: Invoice; chargeItems: ChargeItem[]; summary: Parameters }

export { useIvoicePreview }
