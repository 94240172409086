import { ActivityDefinition, Reference, RequestGroup } from "fhir"
import { createContext, FC, PropsWithChildren, useEffect, useMemo, useState } from "react"

import { cpoeActions } from "data"

import { ACTION_GROUP_CODES, CPOE_ACTION_SYSTEM, CpoeRequest } from "../types"

const CPOERequestsContext = createContext<(Props & State) | undefined>(undefined)
CPOERequestsContext.displayName = "CPOEContext"

const CPOERequestsContextProvider: FC<PropsWithChildren<Props>> = ({
  cpoeRequests,
  isLoadingRequests,
  ordersCount,
  activityDefinition,
  requestGroup,
  children,
}) => {
  const [selectedRequests, updateSelectedRequests] = useState<CpoeRequest[]>([...(cpoeRequests ?? [])])
  const [manuallyActivateNutras, setManuallyActivateNutras] = useState(true)

  const { totalCost, hasAutoship, activatedCount, activeRequestReferences } = useMemo(
    () =>
      selectedRequests.reduce(
        (acc, req) => {
          const reqStatusAction = req.resource.code?.find(({ coding }) =>
            coding?.some(({ system }) => system === CPOE_ACTION_SYSTEM),
          )
          if (reqStatusAction?.coding?.some(({ code }) => code === cpoeActions.activateMR.code)) {
            let autoShip = acc.hasAutoship
            if (req.resource.resource?.resourceType === "MedicationRequest" && !autoShip) {
              autoShip = (req.medicationData?.dispenseRequest?.dispenseInterval?.value ?? 0) > 0
            }
            return {
              totalCost: acc.totalCost + (req.unitPrice.value ?? 0),
              hasAutoship: autoShip,
              activatedCount: acc.activatedCount + 1,
              activeRequestReferences: [
                ...acc.activeRequestReferences,
                {
                  id: req.resource.resource?.id as string,
                  resourceType: req.resource.resource?.resourceType as string,
                },
              ],
            }
          } else {
            let manuallyActivated = 0
            const manuallyActivatedRefs = Array<Reference>()
            /* Default activate all Nutra requests to checkout */
            if (
              manuallyActivateNutras &&
              req.resource.code?.some(({ coding }) => coding?.some((c) => c.code === ACTION_GROUP_CODES.NUTRA)) &&
              !!reqStatusAction
            ) {
              reqStatusAction.coding = [cpoeActions.activateMR, ...(reqStatusAction.coding?.slice(1) ?? [])]
              manuallyActivated++
              manuallyActivatedRefs.push({
                id: req.resource.resource?.id as string,
                resourceType: req.resource.resource?.resourceType as string,
              })
            }

            return {
              ...acc,
              activatedCount: manuallyActivated + acc.activatedCount,
              activeRequestReferences: [...acc.activeRequestReferences, ...manuallyActivatedRefs],
            }
          }
        },
        { totalCost: 0, hasAutoship: false, activatedCount: 0, activeRequestReferences: Array<Reference>() },
      ),
    [selectedRequests],
  )

  // Deactivate manually activate after 1st render
  useEffect(() => {
    setManuallyActivateNutras(false)
  }, [])

  useEffect(() => {
    updateSelectedRequests(cpoeRequests)
  }, [cpoeRequests])

  return (
    <CPOERequestsContext.Provider
      value={{
        ordersCount,
        isLoadingRequests,
        cpoeRequests,
        requestGroup,
        activityDefinition,
        selectedRequests,
        updateSelectedRequests,
        subtotal: totalCost,
        hasAutoship,
        activeRequestsCount: activatedCount,
        activeRequestReferences,
      }}
    >
      {children}
    </CPOERequestsContext.Provider>
  )
}

type Props = {
  ordersCount: number
  isLoadingRequests: boolean
  cpoeRequests: CpoeRequest[]
  requestGroup?: RequestGroup
  activityDefinition?: ActivityDefinition
}

type State = {
  hasAutoship?: boolean
  medIds?: string[]
  selectedRequests: CpoeRequest[]
  subtotal: number
  activeRequestsCount: number
  activeRequestReferences: Array<Reference>
  updateSelectedRequests(requests: CpoeRequest[]): void
}

export { CPOERequestsContext, CPOERequestsContextProvider }
