import { ErrorMessage, Field, FieldProps, useFormikContext } from "formik"
import { Dropdown } from "primereact/dropdown"
import { classNames } from "primereact/utils"

import { FileUploaderField } from "commons"
import { azureContainer } from "data"

import { patientDocumentsCategoryOptions } from "../data"

const PatientDocumentForm = () => {
  const { isSubmitting } = useFormikContext()

  return (
    <>
      <div className="relative flex flex-col gap-2 w-full">
        <label className="mr-2 h-fit text-sm">Category</label>
        <Field name="category[0].coding[0]">
          {({ field: { name, value, onChange }, meta: { touched, error } }: FieldProps) => (
            <Dropdown
              options={patientDocumentsCategoryOptions}
              optionLabel="label"
              optionValue="value"
              id={name}
              name={name}
              value={value}
              onChange={onChange}
              className={classNames("p-inputtext-sm h-10 min-w-[9rem]", {
                "p-invalid": touched && error,
              })}
              disabled={isSubmitting}
            />
          )}
        </Field>
        <ErrorMessage name="category[0]">
          {(msg) => <small className="p-error absolute -bottom-2">{msg}</small>}
        </ErrorMessage>
      </div>
      <FileUploaderField
        field="attachment"
        azureContainer={azureContainer.docs}
        label="Document"
        maxFileSize={1048000}
        className="button-primary"
        mode="advanced"
      />
    </>
  )
}

export { PatientDocumentForm }
