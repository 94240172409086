import { ConfirmDialog } from "commons"

const RemoveComfirmation = ({ visible, isLoading, onHide, onDelete }: Props) => (
  <ConfirmDialog
    confirmText="This medication was prescribed by your practitioner, are you sure you want to remove it?"
    actionName="Remove"
    visible={visible}
    onConfirm={onDelete}
    hideDialog={onHide}
    isLoading={isLoading}
  />
)

type Props = {
  visible?: boolean
  isLoading?: boolean
  onHide(): void
  onDelete(): void
}

export { RemoveComfirmation }
