import { faCalendarDays } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Chip } from "primereact/chip"
import { classNames } from "primereact/utils"
import { FC } from "react"

import { formatsByTypes } from "data"
import { formatDate } from "utils"

import { CardListItem } from "../../components/CardListItem"
import { TrackingData } from "../types"

const TrackingCodeItem: FC<Props> = ({ trackinCode, className }) => {
  const head = (
    <>
      <div className="font-bold" title="Trackin code number">
        {`#${trackinCode.identifier}`}
      </div>
      <span title="Status">
        <Chip
          label={trackinCode.status.replace(/-/g, " ")}
          className={classNames("ml-2 custom-chip capitalize", {
            "is-danger": ["stopped", "on-hold", "cancelled"].includes(trackinCode.status),
            "is-warning": trackinCode.status === "entered-in-error",
            "is-success": trackinCode.status === "completed",
            "is-info": trackinCode.status === "in-progress",
          })}
        />
      </span>
    </>
  )

  const details = (
    <>
      <div className="inline-flex items-center">
        {trackinCode.datePrepared && (
          <>
            <span title="Given out date">
              <FontAwesomeIcon icon={faCalendarDays} size="xs" className="mr-2" />
              {formatDate(new Date(trackinCode.datePrepared), formatsByTypes.LONG_DATE)}
            </span>
          </>
        )}
        {trackinCode.dateShipped && (
          <>
            <span className="mx-1">|</span>
            <span title="Shipped on">
              <FontAwesomeIcon icon={faCalendarDays} size="xs" className="mr-2" />
              {formatDate(new Date(trackinCode.dateShipped), formatsByTypes.LONG_DATE)}
            </span>
          </>
        )}
        <span title="Medications">{trackinCode.medications.join(", ")}</span>
      </div>
    </>
  )

  const googlingCode = () => {
    window.open(`https://www.google.com/search?q=${trackinCode.identifier}`, "_blank")
  }

  return (
    <CardListItem
      contentHeader={head}
      contentSubheader={details}
      className={classNames("cursor-pointer", className)}
      onContentCliked={googlingCode}
      lastElementClass="last:mb-0 last:border-b-0 border-b"
    />
  )
}

type Props = {
  trackinCode: TrackingData
  className?: string
}

export { TrackingCodeItem }
