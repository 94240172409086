import { FC } from "react"
import { Address } from "fhir"
import { addressStringify, getAddressByType, getHomeAddress } from "fhir/utils"
import { FormikHelpers, useFormikContext } from "formik"

import {
  AddressField,
  AddressVerificationFeedback,
  useSmartyAddressVerification,
  useSmartyAddressVerificationContext,
} from "commons"
import { ProfileSection, emptyAddress } from "data"
import { useLoginContext } from "security"
import { getAddressSchema } from "utils"

import { useUpdatePatientAddress } from "../hooks"
import { InformationCard } from "./InformationCard"

const AddressInformation = () => {
  const { loggedInPatient } = useLoginContext()
  const { updateAddress, isUpdating } = useUpdatePatientAddress()
  const { checkAddress, clearVerificationInfo } = useSmartyAddressVerification()

  const { address: patientAddress } = loggedInPatient

  const onSubmit = async (address: Address, formikHelpers?: FormikHelpers<Address>) => {
    await checkAddress(address, formikHelpers, () => {
      clearVerificationInfo()
      updateAddress({ address, patient: loggedInPatient })
    })
  }

  const homeAddress = getHomeAddress(patientAddress)
  const shippingAddress = getAddressByType(patientAddress, "postal")
  const physicalAddress = getAddressByType(patientAddress, "physical")

  return (
    <InformationCard
      id={ProfileSection.ADDRESS}
      title="Address Information"
      data={{
        Home: addressStringify(homeAddress),
        Shipping: addressStringify(shippingAddress),
        Physical: addressStringify(physicalAddress),
      }}
      lineEditData={[
        homeAddress ?? emptyAddress,
        shippingAddress ?? { ...emptyAddress, type: "postal" },
        physicalAddress ?? { ...emptyAddress, type: "physical" },
      ]}
      initialValue={emptyAddress}
      validationSchema={getAddressSchema()}
      isUpdating={isUpdating}
      onSubmit={onSubmit}
      className="profile-card-section"
    >
      <AddressFieldWithVerification />
    </InformationCard>
  )
}

const AddressFieldWithVerification: FC = () => {
  const { setFieldValue } = useFormikContext()
  const { addressVerificationInfo, autoCompleteRecommendedAddress } = useSmartyAddressVerificationContext()

  return (
    <div className="relative p-fluid grid gap-4">
      <fieldset className="relative p-fluid grid grid-cols-2 gap-4">
        <AddressField context="Address" />
      </fieldset>
      <AddressVerificationFeedback
        addressVerificationInfo={addressVerificationInfo}
        handleAutoCompleteRecommendedAddress={() => autoCompleteRecommendedAddress?.(setFieldValue)}
      />
    </div>
  )
}

export { AddressInformation }
