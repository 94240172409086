import { datadogRum } from "@datadog/browser-rum"

import packageJson from "../../package.json"

const isDevelopment = process.env.NODE_ENV === "development"

const dataDogInit = () => {
  datadogRum.init({
    applicationId: "f00731e9-ec34-4394-8d05-fbb739758afa",
    clientToken: "pub833a60956a1e155d124f41d16b8f8193",
    site: "us5.datadoghq.com",
    service: packageJson.name,
    env: window.VITE_APP_ENVIROMENT_NAME,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: packageJson.version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    beforeSend: () => {
      return !isDevelopment
    },
  })
}

export { dataDogInit, datadogRum }
