import { useInfiniteQuery } from "@tanstack/react-query"
import { getResources, Invoice, MedicationRequest, ServiceRequest, Task } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { hasMedAutoship } from "utils"

import { medicationOrderKeys } from "../query-keys"
import { OrderInfo } from "../types"

const useMrOrders = (patientId: string, statusFilter: string[], searchText?: string) => {
  const { search } = useClient()
  const queryKey = medicationOrderKeys.withStatusAndSearchFilter(patientId, statusFilter, searchText)

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery<
    MedicationRequestordersQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = 1 }) => {
      const filters = new URLSearchParams({
        _query: "patient-medication-orders",
        _subcategory: "nutraceutical-order",
        _patient: patientId,
        ...(searchText ? { _filter: searchText } : {}),
        ...(statusFilter.length > 0 ? { _status: statusFilter.join(",") } : {}),
        _count: "20",
        _page: `${pageParam}`,
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/ServiceRequest`, filters })

      const serviceRequests = getResources<ServiceRequest>(bundle, "ServiceRequest")
      const medicationRequest = getResources<MedicationRequest>(bundle, "MedicationRequest")
      const tasks = getResources<Task>(bundle, "Task")
      const invoices = getResources<Invoice>(bundle, "Invoice")

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return {
        serviceRequests,
        medicationRequest,
        tasks,
        invoices,
        next,
        total: bundle?.total ?? 0,
      }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, patientId, statusFilter, searchText } },
  })

  const { orders } = useMemo(() => {
    const srs = data?.pages.flatMap((page) => page.serviceRequests)
    const mrs = data?.pages.flatMap((page) => page.medicationRequest)
    const tasks = data?.pages.flatMap((page) => page.tasks)
    const invoices = data?.pages.flatMap((page) => page.invoices)

    const mrData = mrs?.reduce<Record<string, MedicationRequest>>(
      (prev, mr) => ({ ...prev, [mr.id as string]: mr }),
      {},
    )

    const orders =
      srs?.reduce<Array<OrderInfo>>((acc, sr) => {
        const taskSR = tasks?.find((task) => task.focus?.id === sr.id)
        const taskInvoice = tasks?.find((task) => task.id === taskSR?.dependsOn?.[0].id)
        const invoice = invoices?.find((inv) => inv.id === taskInvoice?.focus?.id) as Invoice
        const medicationRequests =
          sr.basedOn?.reduce<Array<MedicationRequest>>(
            (acc, ref) => (mrData?.[ref.id as string] ? [...acc, mrData[ref.id as string]] : [...acc]),
            [],
          ) ?? []
        const hasAutoship = hasMedAutoship(medicationRequests)

        return [...acc, { serviceRequest: sr, invoice, medicationRequests, hasAutoship }]
      }, []) ?? []

    return {
      orders,
      total: data?.pages?.[0]?.total ?? 0,
    }
  }, [data?.pages])

  return {
    orders,
    isLoading,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  }
}

type MedicationRequestordersQueryData = {
  serviceRequests: ServiceRequest[]
  medicationRequest: MedicationRequest[]
  tasks: Task[]
  invoices: Invoice[]
  next: number | undefined
  total: number
}

export { useMrOrders }
