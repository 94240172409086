import pluralize from "pluralize"

import { useInvoices } from "invoices"
import { getMoneyCurrencyAlt } from "utils"

import { Notification } from "../types"

const useNotifications = (patientId: string) => {
  const {
    invoices,
    dependsTasks,
    isLoading: isLoadingInvoices,
  } = useInvoices({ patientId, statusFilter: ["issued,draft"], includeTasks: true })

  const invoiceNotifications = invoices?.reduce((acc, invoice) => {
    const itemCount = invoice.lineItem?.filter((item) => item.priceComponent?.[0]?.type === "base").length
    const invoiceId = invoice.id as string

    acc.push({
      type: "invoice",
      date: invoice.date,
      url: `/invoices?invoiceId=${invoice.id}`,
      text: `You have a new invoice issued for ${itemCount} ${pluralize("item", itemCount)}${
        dependsTasks[invoiceId] ? " with pending data required" : ""
      }`,
      extraData: invoice.totalNet?.value
        ? getMoneyCurrencyAlt(invoice?.totalNet.currency) + invoice.totalNet.value.toFixed(2)
        : "$0.00",
    })
    return acc
  }, new Array<Notification>())

  return {
    isLoading: isLoadingInvoices,
    notifications: invoiceNotifications,
  }
}

export { useNotifications }
