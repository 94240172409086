import { useMutation } from "@tanstack/react-query"
import { Bundle, getResources, List, ListEntryArray, MedicationRequest, Parameters } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

const useRescheduleMedicationRequest = (
  refreshMedications?: (meds?: MedicationRequest[]) => void,
  onSettled?: () => void,
) => {
  const { operationRequest } = useClient()

  const rescheduleMedicationReq = async (params: Props) => {
    const list: List = {
      resourceType: "List",
      mode: "working",
      status: "current",
      entry: params.medicationRequests.reduce((acc, mrId) => {
        return [...acc, { item: { id: mrId, resourceType: "MedicationRequest" } }]
      }, [] as ListEntryArray[]),
    }

    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "date",
          value: {
            date: params.rescheduleDate,
          },
        },
        {
          name: "items",
          resource: list,
        },
      ],
    }

    return operationRequest<Parameters>({
      endpoint: "MedicationRequest",
      method: "POST",
      operation: "reschedule",
      parameters,
    })
  }

  const { mutate: rescheduleMedicationRequest, isPending } = useMutation({
    mutationFn: rescheduleMedicationReq,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: (meds) => {
      displayNotificationSuccess("Prescription rescheduled successfully!")
      const medications = getResources<MedicationRequest>(meds as Bundle)
      refreshMedications?.(medications)
    },
    onSettled: onSettled,
  })

  return { rescheduleMedicationRequest, isLoading: isPending }
}

type Props = {
  medicationRequests: string[]
  rescheduleDate: string
}

export { useRescheduleMedicationRequest }
