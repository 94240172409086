import { faCheckSquare, faTimes, faWarning } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC } from "react"

import { AddressVerificationInfo } from "../hooks"
import { Button } from "./Buttons"
import { MarkdownTextDisplay } from "./MarkdownTextDisplay"

const AddressVerificationFeedback: FC<AddressVerificationFeedbackProps> = ({
  addressVerificationInfo,
  handleAutoCompleteRecommendedAddress,
}) => (
  <>
    {(!!addressVerificationInfo?.isValidWithWarnings ||
      (addressVerificationInfo?.isValidAddress !== undefined && !addressVerificationInfo.isValidAddress)) && (
      <div className="relative w-full flex flex-col justify-between mt-3 p-3 gap-2">
        <h3 className="text-sm font-semibold">Address Verification:</h3>
        {!!addressVerificationInfo?.errors && addressVerificationInfo.errors.length > 0 && (
          <div className="text-red-400 text-sm mt-1">
            <p className="flex items-baseline">
              <FontAwesomeIcon icon={faTimes} className="mr-1" />
              Errors:
            </p>
            <span className="text-gray-600">
              <div className="flex-col gap-2">
                {addressVerificationInfo.errors.map(({ display, fix }, index) => (
                  <div key={index}>
                    <div className="text-sm inline-flex">
                      <MarkdownTextDisplay markdown={`${index + 1}. ${display}`} />
                    </div>
                    {fix?.length && (
                      <ul className="flex flex-col ml-3 italic">
                        {fix?.map((fixMessage, index) => <li key={index}>{fixMessage}</li>)}
                      </ul>
                    )}
                  </div>
                ))}
              </div>
            </span>
          </div>
        )}
        {!!addressVerificationInfo?.warnings && addressVerificationInfo.warnings.length > 0 && (
          <>
            <div className="text-yellow-600 text-sm mt-1">
              <p className="flex items-baseline">
                <FontAwesomeIcon icon={faWarning} className="mr-1" />
                Warnings:
              </p>
              <span className="text-gray-600">
                <div className="flex-col gap-2">
                  {addressVerificationInfo.warnings.map(({ display, fix }, index) => (
                    <div key={index}>
                      <div className="text-sm inline-flex">
                        <MarkdownTextDisplay markdown={`${index + 1}. ${display}`} />
                      </div>
                      {fix?.length && (
                        <ul className="flex flex-col ml-3 italic">
                          {fix?.map((fixMessage, index) => <li key={index}>{fixMessage}</li>)}
                        </ul>
                      )}
                    </div>
                  ))}
                </div>
              </span>
            </div>
            <p className="text-gray-600 text-sm mt-1">
              <p className="flex items-baseline text-green-600">
                <FontAwesomeIcon icon={faCheckSquare} className="mr-1" />
                Recommend address:
              </p>
              <div className="flex items-center justify-between">
                <span className="mr-3">- {addressVerificationInfo.recommendedAddress?.display}</span>
                <Button
                  label="Use"
                  className="grow"
                  size="sm"
                  buttonStyle="outlined"
                  onClick={handleAutoCompleteRecommendedAddress}
                />
              </div>
            </p>
          </>
        )}
      </div>
    )}
  </>
)

type AddressVerificationFeedbackProps = {
  addressVerificationInfo?: AddressVerificationInfo
  handleAutoCompleteRecommendedAddress?: () => void
}

export { AddressVerificationFeedback }
